import { forwardRef, useImperativeHandle, useState } from "react";

import { usePrivacyPolicy, useTermsAndConditions } from "queries";

import Modal from "../Modal";
import { RenderHTML } from "../RenderHTML";

export type LegalInfoModalsHandle = {
  openTermsAndConditions: () => void;
  openPrivacyPolicy: () => void;
};

const LegalInfoModals = forwardRef<LegalInfoModalsHandle>(
  function LegalInfoModals(_, ref) {
    const handleOpenTermsAndConditions = () => setOpenTermsAndConditions(true);
    const handleCloseTermsAndConditions = () =>
      setOpenTermsAndConditions(false);
    const handleOpenPrivacyPolicy = () => setOpenPrivacyPolicy(true);
    const handleClosePrivacyPolicy = () => setOpenPrivacyPolicy(false);

    const [openTermsAndConditions, setOpenTermsAndConditions] = useState(false);
    const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);
    const { data: termsAndConditions } = useTermsAndConditions();
    const { data: privacyPolicy } = usePrivacyPolicy();

    useImperativeHandle(ref, () => ({
      openTermsAndConditions: handleOpenTermsAndConditions,
      openPrivacyPolicy: handleOpenPrivacyPolicy,
    }));

    return (
      <>
        {termsAndConditions && openTermsAndConditions && (
          <Modal
            isOpen={openTermsAndConditions}
            onDidDismiss={handleCloseTermsAndConditions}
          >
            <RenderHTML html={termsAndConditions} />
          </Modal>
        )}

        {privacyPolicy && openPrivacyPolicy && (
          <Modal
            isOpen={openPrivacyPolicy}
            onDidDismiss={handleClosePrivacyPolicy}
          >
            <RenderHTML html={privacyPolicy} />
          </Modal>
        )}
      </>
    );
  },
);

export default LegalInfoModals;

import { FormattedMessage } from "react-intl";

import LoadingButton from "components/@common/LoadingButton";

import { LoadingButtonProps } from "./types";

const OwnerUpdateReservationButton = ({
  onClick,
  isLoading,
}: LoadingButtonProps) => (
  <LoadingButton
    className="owner-button"
    data-testid="update-reservation"
    form="hook-form-details"
    shape="round"
    color="primary"
    onClick={onClick}
    isLoading={isLoading}
    disabled={isLoading}
  >
    <FormattedMessage id="common.save" />
  </LoadingButton>
);

export default OwnerUpdateReservationButton;

import { IonContent } from "@ionic/react";

import PageWrap from "components/@common/PageWrap/PageWrap";
import PreferencesComponent from "components/@preferences/Preferences";

const Preferences = () => {
  return (
    <PageWrap safeArea={{ show: true }}>
      <IonContent>
        <PreferencesComponent />
      </IonContent>
    </PageWrap>
  );
};

export default Preferences;

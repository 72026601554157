import PageWrap from "components/@common/PageWrap/PageWrap";
import ConfirmPlanComponent from "components/@create-listing/ConfirmPlan";

const ConfirmPlan = () => {
  return (
    <PageWrap>
      <ConfirmPlanComponent />
    </PageWrap>
  );
};

export default ConfirmPlan;

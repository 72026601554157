import { IonButton, useIonRouter } from "@ionic/react";
import { useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath } from "react-router";

import { Routes } from "constants/routes.constants";
import { useUpdateListing } from "queries";
import { Flow } from "types/flow.types";
import { CompleteListingProviderValues } from "types/listing.types";
import { Context } from "types/upload.types";

import ErrorCard from "components/@common/ErrorCard";
import FileSelector from "components/@common/FileSelector";
import LinkUnstyled from "components/@common/LinkUnstyled";
import LoadingButton from "components/@common/LoadingButton";
import StepperContainer from "components/@common/StepperContainer";
import { FormIonInput } from "components/@form/FormIonInput";

const Photos = () => {
  const intl = useIntl();
  const router = useIonRouter();
  const { updateListing, error, isLoading } = useUpdateListing();
  const { setValue } = useFormContext<CompleteListingProviderValues>();

  const [isUploading, setIsUploading] = useState(false);

  const listingId = useWatch<CompleteListingProviderValues>({
    name: "listingId",
  }) as string;
  const license = useWatch<CompleteListingProviderValues>({
    name: "regulations.license",
  }) as string;
  const fileUrls = useWatch<CompleteListingProviderValues>({
    name: "regulations.documents",
  }) as Array<string>;

  const setFileUrls = (urls: Array<string>) => {
    setValue("regulations.documents", urls);
  };

  const handleSubmit = () => {
    updateListing(
      {
        id: listingId,
        attributes: { regulations: { license, documents: fileUrls } },
      },
      {
        onSuccess: () => {
          router.push(
            generatePath(Routes.CompleteListingOverview, { listingId }),
          );
        },
      },
    );
  };

  const handleBack = () => {
    router.push(
      generatePath(Routes.CompleteListingHouseRules, { listingId }),
      "back",
    );
  };

  const handleSkip = () => {
    router.push(generatePath(Routes.CompleteListingOverview, { listingId }));
  };

  return (
    <StepperContainer
      flow={Flow.CompleteListing}
      route={Routes.CompleteListingPhotos}
      continueButton={
        <LoadingButton
          data-testid="continue-button"
          className="continue-button-stepper"
          shape="round"
          expand="full"
          onClick={handleSubmit}
          disabled={isUploading}
          isLoading={isLoading}
        >
          <FormattedMessage id="common.continue" />
        </LoadingButton>
      }
      backButton={
        <IonButton
          className="contrast-button"
          shape="round"
          onClick={handleBack}
        >
          <FormattedMessage id="back.button" />
        </IonButton>
      }
      skipButton={
        <LinkUnstyled onClick={handleSkip}>
          <FormattedMessage id="button.skip.short" />
        </LinkUnstyled>
      }
    >
      <h2 className="margin-24">
        <FormattedMessage id="complete_listing_compliance.title" />
      </h2>

      <p className="create-listing-description">
        <FormattedMessage id="complete_listing_compliance.description" />
      </p>

      <p className="margin-16 bold">
        <FormattedMessage id="complete_listing_compliance.license.label" />
      </p>

      <FormIonInput
        name="regulations.license"
        data-testid="license-input"
        aria-label="regulations.license"
        type="text"
        fill="solid"
        enterKeyHint="enter"
        label={intl.formatMessage({
          id: "complete_listing_compliance.license.placeholder",
        })}
        labelPlacement="floating"
        style={{ marginBottom: "16px" }}
      />

      <FileSelector
        listingId={listingId}
        context={Context.Onboarding}
        type="documents"
        fileUrls={fileUrls}
        setIsUploading={setIsUploading}
        updateFileUrls={setFileUrls}
      />

      {error && (
        <ErrorCard title={<FormattedMessage id={"common.error_unknown"} />} />
      )}
    </StepperContainer>
  );
};

export default Photos;

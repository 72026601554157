import { FormattedMessage } from "react-intl";

import LoadingButton from "components/@common/LoadingButton";

import { LoadingButtonProps } from "./types";

const OwnerCancelReservationButton = ({
  onClick,
  isLoading,
}: LoadingButtonProps) => (
  <LoadingButton
    className="owner-button"
    data-testid="cancel-reservation"
    shape="round"
    color="danger"
    onClick={onClick}
    isLoading={isLoading}
    disabled={isLoading}
  >
    <FormattedMessage id="reservation_detail.cancel_reservation" />
  </LoadingButton>
);

export default OwnerCancelReservationButton;

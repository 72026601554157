import "./ResetPasswordOtp.scss";

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { IonCard, IonCardContent, IonText, useIonRouter } from "@ionic/react";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import OtpInput from "react-otp-input";

import { isNative } from "utils/capacitor.utils";
import { getQueryParam, QueryParam } from "utils/location.utils";

import { Routes } from "constants/routes.constants";
import { useForgotPassword } from "queries";
import { useAuthStore } from "store/auth-store";

import ErrorCard from "components/@common/ErrorCard";
import LinkUnstyled from "components/@common/LinkUnstyled";
import LoadingButton from "components/@common/LoadingButton";

import { getCognitoErrorMessageKey } from "../ForgotPassword/utils";

const OTP_LENGTH = 6;

const ResetPasswordOtp = () => {
  const { setValues, email } = useAuthStore((state) => ({
    setValues: state.actions.setValues,
    email: state.values?.email,
  }));

  const { forgotPassword: resend, error, isLoading } = useForgotPassword();

  const router = useIonRouter();
  const queryEmail = getQueryParam(QueryParam.Email);
  const queryCode = getQueryParam(QueryParam.Code);
  const [otp, setOtp] = useState(queryCode || "");
  const [showSuccess, setShowSuccess] = useState(false);

  const handleSubmit = () => {
    setValues({
      ...(queryEmail && { email: queryEmail }),
      otp,
    });
    router.push(Routes.ResetPasswordConfirm, "forward", "replace");
  };

  const handleResend = () => {
    resend(
      {
        username: queryEmail || email!,
      },
      {
        onSuccess: () => {
          setShowSuccess(true);
        },
        onError: () => {
          setShowSuccess(false);
        },
      },
    );
  };

  useEffect(() => {
    if (!isNative) return;
    const code = new URL(window.location.href).searchParams.get("code");
    !!code && setOtp(code);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- run when href changes
  }, [window.location.href]);

  return (
    <div className="container">
      <IonText>
        <h2>
          <FormattedMessage id="reset_password.title" />
        </h2>
      </IonText>

      <p className="description">
        <IonText>
          <FormattedMessage
            id="reset_password.description"
            values={{
              email: <b>{queryEmail || email}</b>,
            }}
          />
        </IonText>
      </p>

      {error && (
        <ErrorCard
          title={
            <FormattedMessage
              id={getCognitoErrorMessageKey(error.name).key}
              values={getCognitoErrorMessageKey(error.name).values}
            />
          }
        />
      )}

      {showSuccess && (
        <IonCard className="success-card">
          <IonCardContent>
            <p>
              <FormattedMessage
                id="forgot_password.success"
                values={{
                  b: (chunks) => <b>{chunks}</b>,
                }}
              />
            </p>
          </IonCardContent>
        </IonCard>
      )}

      <OtpInput
        value={otp}
        onChange={setOtp}
        numInputs={OTP_LENGTH}
        inputType="number"
        renderInput={(props) => (
          <input {...props} type="number" className="otp-input" />
        )}
        containerStyle={{
          gap: 8,
        }}
      />

      <p className="margin-16">
        <IonText>
          <FormattedMessage
            id="reset_password.otp.resend"
            values={{
              a: (chunks) => (
                <LinkUnstyled
                  data-testid="resend-forgot-email"
                  onClick={handleResend}
                >
                  {chunks}
                </LinkUnstyled>
              ),
            }}
          />
        </IonText>
      </p>

      <div className="button-margin">
        <LoadingButton
          className="continue-button"
          type="submit"
          shape="round"
          expand="full"
          data-testid="continue-button"
          onClick={handleSubmit}
          isLoading={isLoading}
          disabled={isLoading || otp.length < OTP_LENGTH}
        >
          <FormattedMessage id="common.continue" />
        </LoadingButton>
      </div>
    </div>
  );
};

export default ResetPasswordOtp;

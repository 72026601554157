import "./MobileMenu.scss";

import { useIonRouter } from "@ionic/react";
import { useRef } from "react";
import { FormattedMessage } from "react-intl";

import { isAndroid, isIos } from "utils/capacitor.utils";

import { accountLinksMap, supportLinksMap } from "constants/menu.constants";
import { Routes } from "constants/routes.constants";
import { useSignOut } from "queries";

import AppVersion from "components/@common/AppVersion";
import { LegalInfoModals } from "components/@common/LegalInfoModals";
import { LegalInfoModalsHandle } from "components/@common/LegalInfoModals/LegalInfoModals";
import MenuItem from "components/@common/MenuItem";

const MobileMenu = () => {
  const { signOut, isLoading } = useSignOut();
  const router = useIonRouter();

  const handleSignOut = () => {
    router.push(Routes.Login, "root", "replace");
    signOut();
  };

  const legalInfoModalsRef = useRef<LegalInfoModalsHandle>(null);

  return (
    <>
      <div
        className={`ion-tab-page mobile-menu ${isAndroid ? "android-top-padding" : !isIos && "web-top-padding"}`}
      >
        <div className="mobile-menu-content">
          <h3 className="menu-title">
            <FormattedMessage id="mobile_menu" />
          </h3>
          <p className="bold margin-bottom-8">
            <FormattedMessage id="mobile_menu_support.title" />
          </p>
          {supportLinksMap.map((link, index) => (
            <MenuItem
              key={index}
              onClick={() => window.open(link.href, "_blank")}
              {...link}
            />
          ))}
          <MenuItem
            title="terms_and_conditions.title"
            icon="assets/icons/icon-support.svg"
            onClick={() => legalInfoModalsRef.current?.openTermsAndConditions()}
          />
          <MenuItem
            title="privacy_policy.title"
            icon="assets/icons/icon-info-circle.svg"
            onClick={() => legalInfoModalsRef.current?.openPrivacyPolicy()}
          />
          <hr className="menu-divider" />
          <p className="bold margin-bottom-8">
            <FormattedMessage id="mobile_menu_account.title" />
          </p>
          {accountLinksMap.map((link, index) => (
            <MenuItem
              key={index}
              onClick={() => router.push(link.route, "forward", "push")}
              {...link}
            />
          ))}
          <hr className="menu-divider" />
          <MenuItem
            title="mobile_menu_logout"
            icon="assets/icons/icon-logout.svg"
            isExternal={false}
            onClick={handleSignOut}
            isLoading={isLoading}
          />
        </div>
        <AppVersion />
      </div>
      <LegalInfoModals ref={legalInfoModalsRef} />
    </>
  );
};

export default MobileMenu;

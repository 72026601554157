import "./Showcase.scss";

import { IonButton, useIonRouter } from "@ionic/react";
import { useWatch } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath } from "react-router";

import { Routes } from "constants/routes.constants";
import { useAmenities, useListing } from "queries";
import { Flow } from "types/flow.types";

import MarketingContent from "components/@common/MarketingContent.tsx/MarketingContent";
import StepperContainer from "components/@common/StepperContainer";

const Showcase = () => {
  const intl = useIntl();
  useAmenities();

  const router = useIonRouter();

  const listingId = useWatch({ name: "listingId" });

  const { data, isLoading, error } = useListing(listingId ?? "", {
    enabled: !!listingId,
  });

  const handleSubmit = () => {
    router.push(
      generatePath(Routes.ReviewListingAvailability, {
        listingId,
      }),
    );
  };

  const handleBack = () => {
    router.push(
      generatePath(Routes.ReviewListingLaunchpad, {
        listingId,
      }),
      "back",
    );
  };

  return (
    <StepperContainer
      flow={Flow.ReviewListing}
      route={Routes.ReviewListingShowcase}
      continueButtonOnBottom
      continueButton={
        <IonButton
          data-testid="continue-button"
          className="continue-button-stepper"
          type="submit"
          shape="round"
          expand="full"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          <FormattedMessage id="common.continue" />
        </IonButton>
      }
      skipButton={
        <>
          <IonButton
            className="contrast-button"
            shape="round"
            onClick={handleBack}
            style={{ width: "fit-content" }}
          >
            <FormattedMessage id="back.button" />
          </IonButton>
          <IonButton
            className="contrast-button"
            shape="round"
            href={intl.formatMessage({ id: "mailto.request_changes" })}
            style={{ width: "100%" }}
          >
            <FormattedMessage id="request.changes.button" />
          </IonButton>
        </>
      }
    >
      <div className="review-showcase">
        <p className="review-caption">
          <FormattedMessage id="review_listing.title_caption" />
        </p>

        <h2 className="margin-bottom-24">
          <FormattedMessage id="review_listing_showcase.title" />
        </h2>

        <MarketingContent data={data} error={error} />
      </div>
    </StepperContainer>
  );
};

export default Showcase;

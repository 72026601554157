import "./ReservationsTable.scss";

import {
  IonIcon,
  IonSearchbar,
  IonSkeletonText,
  useIonRouter,
} from "@ionic/react";
import { formatISO } from "date-fns";
import {
  forwardRef,
  Fragment,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath } from "react-router";

import { Routes } from "constants/routes.constants";
import { useInfiniteReservations } from "queries/reservations/useReservations";
import { Reservation } from "types/reservation.types";

import BookedByItem from "components/@common/BookedByItem";
import { RefreshHandle } from "components/@common/PullToRefresh";
import Rating from "components/@common/Rating";

import ReservationStatusChip from "../ReservationStatusChip/ReservationStatusChip";
import { formatCurrency, formatDateMMMddyyyyHHmm } from "../utils";
import { COLUMNS } from "./constants";
import EmptyTable from "./EmptyTable";
import ErrorTable from "./ErrorTable";
import { useTableSort } from "./hooks";
import Pagination from "./Pagination";
import TableSkeleton from "./TableSkeleton";
import {
  COLUMN_KEYS,
  ReservationFilters,
  SortDirection,
  TabOption,
} from "./types";
import { formatDateMMMdd } from "./utils";

const ReservationsTable = forwardRef<RefreshHandle>(
  function ReservationsTable(_, ref) {
    const intl = useIntl();
    const [filters, setFilters] = useState<ReservationFilters>({
      status: "confirmed",
      start_date: formatISO(new Date()),
    });
    const [activeTab, setActiveTab] = useState<TabOption>(TabOption.Upcoming);
    const [search, setSearch] = useState<string>("");
    const router = useIonRouter();

    const { sortBy, sortDirection, handleTableSort, resetTableSort } =
      useTableSort({
        initialSortBy: COLUMN_KEYS.CheckOutDate,
        initialSortDirection: SortDirection.Asc,
      });

    const {
      data: reservations,
      hasNextPage,
      isFetchingNextPage,
      fetchNextPage,
      isLoading,
      isError,
      refetch,
    } = useInfiniteReservations({
      search,
      filters,
      sortBy,
      sortDirection,
    });

    useImperativeHandle(ref, () => ({ refresh: refetch }));

    const filteredColumns = useMemo(
      () =>
        COLUMNS.filter((column) =>
          column.optional ? column.tabs.includes(activeTab) : true,
        ),
      [activeTab],
    );

    const handleLoadMore = () => {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    };

    const handleRowClick = (listingId: string, reservationId: string) => {
      router.push(
        generatePath(Routes.ReservationDetails, { listingId, reservationId }),
      );
    };

    const handleTabChange = (tab: TabOption) => {
      setActiveTab(tab);
      const today = formatISO(new Date());
      switch (tab) {
        case TabOption.Upcoming:
          setFilters({
            status: "confirmed",
            start_date: today,
          });
          resetTableSort();
          break;
        case TabOption.Completed:
          setFilters({
            status: "confirmed",
            end_date: today,
          });
          handleTableSort(COLUMN_KEYS.CheckOutDate, SortDirection.Desc);
          break;
        case TabOption.Canceled:
          setFilters({ status: "canceled" });
          handleTableSort(COLUMN_KEYS.CheckOutDate, SortDirection.Desc);
          break;
        default:
          setFilters({});
          handleTableSort(COLUMN_KEYS.CheckOutDate, SortDirection.Desc);
          break;
      }
    };

    const renderCellContent = (
      columnId: COLUMN_KEYS,
      reservation: Reservation,
    ) => {
      switch (columnId) {
        case COLUMN_KEYS.MainGuestName:
          return (
            <BookedByItem
              name={reservation.attributes.mainGuestName}
              verified={reservation.attributes.isVerified}
              isOwnerReservation={reservation.attributes.isOwnerReservation}
            />
          );

        case COLUMN_KEYS.Rating:
          return <Rating rating={reservation.attributes.overallScore} />;
        case COLUMN_KEYS.Status:
          return (
            <ReservationStatusChip
              checkInDate={reservation.attributes.checkInDate}
              checkOutDate={reservation.attributes.checkOutDate}
              status={reservation.attributes.status}
            />
          );
        case COLUMN_KEYS.Id:
          return (
            <div className="listing-id-cell">
              {reservation.relationships.listing.nickname}
            </div>
          );
        case COLUMN_KEYS.CheckInDate:
          return (
            <p className="margin-0 bold">
              {formatDateMMMddyyyyHHmm(reservation.attributes.checkInDate)}
            </p>
          );
        case COLUMN_KEYS.CheckOutDate:
          return (
            <p className="margin-0 bold">
              {formatDateMMMddyyyyHHmm(reservation.attributes.checkOutDate)}
            </p>
          );
        case COLUMN_KEYS.Nights:
          return (
            <FormattedMessage
              id="reservations_table.numberOfNights"
              values={{
                count: reservation.attributes.numberOfNights,
              }}
            />
          );
        case COLUMN_KEYS.Guests:
          return (
            <FormattedMessage
              id="reservations_table.numberOfGuests"
              values={{
                count: reservation.attributes.numberOfGuests,
              }}
            />
          );
        case COLUMN_KEYS.CreatedDate:
          return formatDateMMMdd(reservation.attributes.createdDate);
        case COLUMN_KEYS.OTAName:
          return reservation.relationships.ota.name;
        case COLUMN_KEYS.CanceledOn:
          return reservation.attributes.canceledDate
            ? formatDateMMMdd(reservation.attributes.canceledDate)
            : "-";
        case COLUMN_KEYS.ConfirmationCode:
          return reservation.attributes.confirmationCode;
        case COLUMN_KEYS.RentalRevenue:
          return (
            <p className="margin-0 bold">
              {formatCurrency(reservation.attributes.rentalRevenue)}
            </p>
          );
        case COLUMN_KEYS.OwnerRevenue:
          return (
            <p className="margin-0 bold">
              {formatCurrency(reservation.attributes.ownerRevenue)}
            </p>
          );
        default:
          return null;
      }
    };

    const handleInput = (ev: Event) => {
      const target = ev.target as HTMLIonSearchbarElement;
      const value = target.value;

      if (typeof value === "string") {
        setSearch(value);
      }
    };

    return (
      <>
        <div className="filters">
          <div className="search">
            <IonSearchbar
              debounce={300}
              onIonInput={(ev) => handleInput(ev)}
              inputmode="search"
              placeholder={intl.formatMessage({
                id: "reservations_table.search",
              })}
            />
          </div>
        </div>

        <div className="reservations-table">
          <div className="tabs">
            {Object.values(TabOption).map((tab, index) => (
              <button
                className={`tab ${activeTab === tab ? "active" : ""}`}
                key={`${tab}-${index}`}
                onClick={() => handleTabChange(tab)}
              >
                <h6>
                  <FormattedMessage id={`reservations_table.${tab}`} />
                </h6>
              </button>
            ))}
          </div>

          {reservations?.data.length === 0 && !isLoading && !isError && (
            <EmptyTable tab={activeTab} />
          )}

          {isError && !isLoading ? (
            <ErrorTable onRefetch={refetch} />
          ) : (
            <>
              <div className="mobile-grid">
                {(!!reservations?.data.length || isLoading) && (
                  <div className="grid">
                    {filteredColumns
                      .slice(0, 2)
                      .map(({ id, initialSort, label }) => (
                        <div
                          key={`mobile-header-${id}`}
                          className="grid-header"
                          onClick={() => handleTableSort(id, initialSort)}
                          style={{ cursor: "pointer" }}
                        >
                          <div className="grid-header-container">
                            <FormattedMessage id={label} />
                            <IonIcon
                              icon="assets/icons/icon-chevron-expand.svg"
                              style={{ marginLeft: 4 }}
                            />
                          </div>
                        </div>
                      ))}

                    {isLoading
                      ? [...Array(4)].map((_, index) => (
                          <Fragment key={index}>
                            <div className="grid-item loader">
                              <IonSkeletonText animated={true} />
                            </div>
                            <div className="grid-item">
                              <IonSkeletonText animated={true} />
                            </div>
                          </Fragment>
                        ))
                      : reservations?.data.map((reservation, index) => (
                          <Fragment
                            key={`mobile-grid-${reservation.id}-${index}`}
                          >
                            <div
                              className="grid-item booked-by"
                              onClick={() =>
                                handleRowClick(
                                  reservation.relationships.listing.id,
                                  reservation.id,
                                )
                              }
                            >
                              <IonIcon
                                className="ellipse-icon"
                                color={
                                  reservation.attributes.isOwnerReservation
                                    ? "secondary"
                                    : "success"
                                }
                                src="assets/icons/icon-ellipse.svg"
                              />
                              <div className="guest-info">
                                <p className="main-guest-name margin-0 bold">
                                  <span className="clamp-text">
                                    {reservation.attributes.mainGuestName}
                                  </span>
                                  {reservation.attributes.isVerified && (
                                    <IonIcon
                                      className="verified-icon"
                                      src="assets/icons/icon-verified.svg"
                                    />
                                  )}
                                </p>
                                <div className="nights-and-guests">
                                  <FormattedMessage
                                    id="reservations_table.numberOfNights"
                                    values={{
                                      count:
                                        reservation.attributes.numberOfNights,
                                    }}
                                  />
                                  {" • "}
                                  <FormattedMessage
                                    id="reservations_table.numberOfGuests"
                                    values={{
                                      count:
                                        reservation.attributes.numberOfGuests,
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div
                              className="grid-item"
                              onClick={() =>
                                handleRowClick(
                                  reservation.relationships.listing.id,
                                  reservation.id,
                                )
                              }
                            >
                              <span className="clamp-text">
                                {reservation.relationships.listing.nickname}
                              </span>
                            </div>
                          </Fragment>
                        ))}
                  </div>
                )}
              </div>

              <table className="desktop-table">
                {(!!reservations?.data.length || isLoading) && (
                  <thead>
                    <tr>
                      {filteredColumns.map(({ id, initialSort, label }) => (
                        <th
                          className={`column-${id}`}
                          key={`desktop-header-${id}`}
                          onClick={() => handleTableSort(id, initialSort)}
                          style={{ cursor: "pointer" }}
                        >
                          <div className="table-header-container">
                            <FormattedMessage id={label} />
                            <IonIcon
                              icon="assets/icons/icon-chevron-expand.svg"
                              style={{ marginLeft: 4 }}
                            />
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                )}

                <tbody>
                  {isLoading ? (
                    <TableSkeleton columns={filteredColumns} />
                  ) : (
                    reservations?.data.map((reservation, index) => (
                      <tr
                        key={`desktop-row-${reservation.id}-${index}`}
                        onClick={() =>
                          handleRowClick(
                            reservation.relationships.listing.id,
                            reservation.id,
                          )
                        }
                      >
                        {filteredColumns.map(({ id, width }, index) => (
                          <td
                            key={`desktop-cell-${id}-${index}`}
                            style={{ width }}
                            className={`column-${id}`}
                          >
                            {renderCellContent(id, reservation)}
                          </td>
                        ))}
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </>
          )}
        </div>

        <Pagination
          meta={reservations?.meta}
          onLoadMore={handleLoadMore}
          isLoading={isLoading || isFetchingNextPage}
          disabled={isLoading || !hasNextPage || isFetchingNextPage}
        />
      </>
    );
  },
);

export default ReservationsTable;

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import "./ResetPasswordSuccess.scss";

import { IonButton, IonIcon, IonText, useIonRouter } from "@ionic/react";
import { shieldCheckmarkOutline } from "ionicons/icons";
import { FormattedMessage } from "react-intl";

import { Routes } from "constants/routes.constants";

const ResetPasswordSuccess = () => {
  const router = useIonRouter();

  return (
    <div className="container">
      <IonIcon
        icon={shieldCheckmarkOutline}
        color="primary"
        className="shield-icon"
      />
      <IonText>
        <h2>
          <FormattedMessage id="reset_password_success.title" />
        </h2>
      </IonText>

      <p className="description">
        <IonText>
          <FormattedMessage id="reset_password_success.description" />
        </IonText>
      </p>

      <div className="button-margin">
        <IonButton
          className="continue-button"
          data-testid="continue-button"
          shape="round"
          expand="full"
          type="submit"
          onClick={() => router.push(Routes.SignInContinue, "back", "replace")}
        >
          <FormattedMessage id="back_to_login.button" />
        </IonButton>
      </div>
    </div>
  );
};

export default ResetPasswordSuccess;

import { forwardRef, useState } from "react";

import { isAndroid } from "utils/capacitor.utils";

import { useBlockReasons } from "queries/blocks";

import CalendarContent from "components/@calendar/CalendarContent/CalendarContent";
import CalendarSidebar from "components/@calendar/CalendarSidebar/CalendarSidebar";
import { RefreshHandle } from "components/@common/PullToRefresh";

const CalendarContainer = forwardRef<RefreshHandle>(
  function CalendarContainer(_, ref) {
    useBlockReasons(); // preload
    const [height, setHeight] = useState(642);

    const [selectedListingId, setSelectedListingId] = useState<string | null>(
      new URLSearchParams(window.location.search).get("listingId") || null,
    );

    return (
      <div
        className={`calendar-container ${isAndroid && "android-top-padding"}`}
        style={{ minHeight: height, display: "flex" }}
      >
        <CalendarSidebar
          activeListingId={selectedListingId}
          setActiveListingId={setSelectedListingId}
        />

        <CalendarContent
          ref={ref}
          selectedListingId={selectedListingId}
          setHeight={setHeight}
        />
      </div>
    );
  },
);

export default CalendarContainer;

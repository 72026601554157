import { useEffect, useState } from "react";

import { getIdToken } from "utils/cognito.utils";

import { useCurrentUser } from "services/auth/useCurrentUser";

export const useIsEmailVerified = () => {
  const [emailVerified, setEmailVerified] = useState<boolean>(true);
  const { isLoggedIn } = useCurrentUser();

  useEffect(() => {
    getIdToken()
      .then((idToken) => {
        setEmailVerified((idToken?.payload.email_verified as boolean) || false);
      })
      .catch(() => {
        setEmailVerified(false);
      });
  }, [isLoggedIn]);

  return emailVerified;
};

export default useIsEmailVerified;

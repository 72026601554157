import { useMutation, useQueryClient } from "@tanstack/react-query";
import { signIn, SignInInput } from "aws-amplify/auth";

import { accountUserQuery } from "./queries";

export const useCreateLogin = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, isPending, error } = useMutation({
    mutationFn: (input: SignInInput) => {
      return signIn(input)
        .then((response) => {
          if (response.isSignedIn) {
            queryClient.ensureQueryData({
              ...accountUserQuery,
              meta: { localStorage: true },
            });
          } else if (
            response.nextStep?.signInStep ===
            "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"
          ) {
            throw new Error(response.nextStep.signInStep);
          }
        })
        .catch((error) => {
          throw new Error(error);
        });
    },
  });
  return {
    createLogin: mutateAsync,
    isLoading: isPending,
    error,
  };
};

import PageWrap from "components/@common/PageWrap/PageWrap";
import AvailabilityComponent from "components/@review-listing/Availability";

const Availability = () => {
  return (
    <PageWrap>
      <AvailabilityComponent />
    </PageWrap>
  );
};

export default Availability;

import PageWrap from "components/@common/PageWrap/PageWrap";
import SegmentationComponent from "components/@create-listing/Segmentation";

const Segmentation = () => {
  return (
    <PageWrap>
      <SegmentationComponent />
    </PageWrap>
  );
};

export default Segmentation;

import "./Checkout.scss";

import { IonText, useIonRouter } from "@ionic/react";
import { useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { generatePath } from "react-router";

import { planKeys } from "constants/listing.constants";
import { Routes } from "constants/routes.constants";
import { useUpdateListing } from "queries";
import { Flow } from "types/flow.types";
import {
  CreateListingProviderValues,
  ListingStatus,
} from "types/listing.types";

import LinkUnstyled from "components/@common/LinkUnstyled";
import LoadingButton from "components/@common/LoadingButton";
import StepperContainer from "components/@common/StepperContainer";

import PropertySummary from "./PropertySummary";

const Checkout = () => {
  const { updateListing, isLoading } = useUpdateListing();
  const listingId = useWatch({ name: "listingId" });
  const { getValues } = useFormContext<CreateListingProviderValues>();
  const values = getValues();
  const router = useIonRouter();

  const formattedAddress = useMemo(() => {
    if (!values) return null;
    return `${values.street}, ${values.zipcode} ${values.city}`;
  }, [values]);

  const onClickAddress = () => {
    router.push(
      generatePath(Routes.CreateListingAddress, { listingId }),
      "back",
    );
  };

  const handleSubmit = () => {
    updateListing(
      {
        id: listingId,
        attributes: { status: ListingStatus.Onboarding },
      },
      {
        onSuccess: () =>
          router.push(
            generatePath(Routes.CreateListingSuccess, { listingId }),
            "forward",
            "replace",
          ),
      },
    );
  };

  return (
    <StepperContainer
      flow={Flow.CreateListing}
      route={Routes.CreateListingCheckout}
    >
      <h2 className="margin-24">
        <FormattedMessage id="create_listing_overview.title" />
      </h2>
      <p className="create-listing-description">
        <FormattedMessage id="create_listing_overview.description" />
      </p>

      <div className="checkout-container">
        <div className="checkout-card">
          <div className="checkout-content">
            <IonText color="primary">
              <p className="small margin-0 bold uppercase">
                <FormattedMessage id="create_listing_overview.property.title" />
              </p>
            </IonText>

            <h6>{formattedAddress}</h6>

            <PropertySummary
              propertyKind={values.propertyKind}
              propertyType={values.propertyType}
              maximumOccupancy={values.maximumOccupancy}
              bedrooms={values.bedrooms}
              bathrooms={values.bathrooms}
            />
          </div>
          <LinkUnstyled onClick={onClickAddress}>
            <FormattedMessage id="create_listing_overview.change" />
          </LinkUnstyled>
        </div>

        <div className="checkout-card">
          <div className="checkout-content">
            <IonText color="primary">
              <p className="small margin-0 bold uppercase">
                <FormattedMessage id="create_listing_overview.plan.title" />
              </p>
            </IonText>

            {values.pricingPlan && (
              <>
                <h6>
                  <FormattedMessage id={planKeys[values.pricingPlan]} />
                </h6>

                <p className="extra-small margin-0">
                  {!!values.pricingPlan ? (
                    <>
                      <FormattedMessage
                        id={`${values.pricingPlan}_plan.percentage.number`}
                      />
                      %{" "}
                    </>
                  ) : (
                    <FormattedMessage id="complete_listing_overview.not_set" />
                  )}
                  <FormattedMessage id="checkout.plan_percentage.description" />
                </p>
                <p className="extra-small margin-0">
                  €
                  <FormattedMessage
                    id={
                      values.pricingPlan
                        ? `${values.pricingPlan}_plan.per_month`
                        : "complete_listing_overview.not_set"
                    }
                  />{" "}
                  <FormattedMessage id="checkout.plan_cost.description" />
                </p>
              </>
            )}
          </div>
        </div>
      </div>

      <hr className="margin-40" />

      <p className="margin-0 extra-small">
        <FormattedMessage
          id="create_listing_overview.links"
          values={{
            a: (chunks) => (
              <a
                data-testid="contractuals-link"
                rel="noopener noreferrer"
                target="_blank"
                href="https://support.huswell.com/host"
              >
                {chunks}
              </a>
            ),
            a2: (chunks) => (
              <a
                data-testid="owners-agreement-link"
                rel="noopener noreferrer"
                target="_blank"
                href="https://support.huswell.com/host"
              >
                {chunks}
              </a>
            ),
          }}
        />
      </p>
      <div className="margin-24 agree-button">
        <LoadingButton
          shape="round"
          className="secondary-button width-100"
          isLoading={isLoading}
          onClick={handleSubmit}
        >
          <FormattedMessage id="create_listing_checkout.button.label" />
        </LoadingButton>
      </div>
    </StepperContainer>
  );
};
export default Checkout;

import "./ReservationDetail.scss";

import { IonButton, IonIcon } from "@ionic/react";
import { format, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { FormProvider, useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import { isAndroid } from "utils/capacitor.utils";

import { useRequestCancelReservation } from "queries/reservations";
import { ReservationType } from "types/reservation.types";
import { GuestyReservation } from "types/reservation.types";

import LoadingButton from "components/@common/LoadingButton";
import Modal from "components/@common/Modal";
import SuccessCard from "components/@common/SuccessCard";
import ReservationEditDetailsForm from "components/@create-reservation/Details/ReservationEditDetailsForm";
import { Form } from "components/@form/Form";
import FormIonTextArea from "components/@form/FormIonTextArea";
import ReservationDelayDisclaimer from "components/@reservations/ReservationDetail/ReservationDelayDisclaimer";

import ReservationStatusChip from "../../ReservationStatusChip/ReservationStatusChip";
import { ReservationStatus } from "../../types";
import HuswellShield from "../HuswellShield";
import ReservationDetailContent from "../ReservationDetailContent";
import ReservationDetailOwner from "../ReservationDetailOwner";
import { formatDateRange } from "../utils";
import {
  OwnerCancelEditButton,
  OwnerCancelReservationButton,
  OwnerEditButton,
  OwnerUpdateReservationButton,
} from "./components/ActionButtons";

interface Props {
  listingId: string;
  reservationId: string;
  reservation?: GuestyReservation["data"];
  hideBack?: boolean;
  useDesktopButtons?: boolean;
  handleBack?: () => void;
  updateError: Error | null;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  openRequestCancel: boolean;
  setOpenRequestCancel: (openRequestCancel: boolean) => void;
  canEdit?: boolean;
  isOwner?: boolean;
  reservationStatus: ReservationStatus;
  isLoadingUpdate: boolean;
  isLoadingDelete: boolean;
  handleDeleteConfirmation: () => void;
  handleEditReservation: () => void;
  handleUpdate: () => void;
  isModal?: boolean;
}

interface FormData {
  note: string;
}

const defaultValues = {
  note: "",
};

const Reservation = ({
  reservation,
  hideBack,
  listingId,
  reservationId,
  useDesktopButtons,
  handleBack,
  updateError,
  isEditing,
  setIsEditing,
  openRequestCancel,
  setOpenRequestCancel,
  canEdit,
  isOwner,
  reservationStatus,
  isLoadingUpdate,
  isLoadingDelete,
  handleDeleteConfirmation,
  handleEditReservation,
  handleUpdate,
  isModal,
}: Props) => {
  const [cancelSuccess, setCancelSuccess] = useState(false);
  const intl = useIntl();

  const methods = useFormContext();

  useEffect(() => {
    if (isEditing) {
      if (!!reservation) {
        methods.setValue(
          "checkInDate",
          format(parseISO(reservation.attributes.checkInDate), "yyyy-MM-dd"),
        );
        methods.setValue(
          "checkOutDate",
          format(parseISO(reservation.attributes.checkOutDate), "yyyy-MM-dd"),
        );
        methods.setValue("reservationType", ReservationType.Myself);
        methods.setValue(
          "numberOfNights",
          reservation.attributes.numberOfNights,
        );
        methods.setValue(
          "numberOfGuests",
          reservation.attributes.numberOfGuests,
        );
        methods.setValue(
          "cleaningRequired",
          reservation.attributes.cleaningRequired,
        );
      }
    }
  }, [isEditing, methods, reservation]);

  const { requestCancelReservation, isLoading: isLoadingCancel } =
    useRequestCancelReservation();

  const handleRequestCancellation = ({ note }: FormData) => {
    requestCancelReservation(
      {
        listingId: listingId ?? "",
        reservationId: reservationId ?? "",
        note,
      },
      {
        onSuccess: () => {
          setCancelSuccess(true);
        },
      },
    );
  };

  const buttons = (
    <div className="owner-buttons">
      {isEditing ? (
        <>
          <OwnerCancelEditButton onClick={() => setIsEditing(false)} />
          <OwnerUpdateReservationButton
            onClick={methods.handleSubmit(handleUpdate)}
            isLoading={isLoadingUpdate}
          />
        </>
      ) : (
        <>
          <OwnerCancelReservationButton
            onClick={handleDeleteConfirmation}
            isLoading={isLoadingDelete}
          />
          <OwnerEditButton onClick={handleEditReservation} />
        </>
      )}
    </div>
  );

  return (
    <>
      {reservation && (
        <div
          className={`reservation-detail-page ${isAndroid && "android-top-padding"} ${isModal && "reservation-modal"}`}
        >
          <div className="detail-container">
            {!hideBack && (
              <IonButton
                shape="round"
                className="back-button"
                onClick={handleBack}
              >
                <IonIcon
                  slot="icon-only"
                  icon="assets/icons/icon-chevron-back.svg"
                />
              </IonButton>
            )}
            <div className="detail-header">
              <div className="detail-title">
                <div>
                  <ReservationStatusChip
                    status={reservation.attributes.status}
                    checkInDate={reservation.attributes.checkInDate}
                    checkOutDate={reservation.attributes.checkOutDate}
                  />
                </div>
                <p className="margin-0 nickname">
                  {reservation.relationships.listing.nickname}
                </p>
                <h3>
                  {isOwner ? (
                    <FormattedMessage id="reservation_detail.owner_title" />
                  ) : (
                    reservation.attributes.guests.mainGuestName
                  )}
                </h3>

                <div className="reservation-type">
                  <IonIcon
                    className="ellipse-icon"
                    color={isOwner ? "secondary" : "success"}
                    src="assets/icons/icon-ellipse.svg"
                  />
                  <p className="margin-0 bold">
                    <FormattedMessage
                      id="reservation_detail.type"
                      values={{
                        isOwner,
                        dateRange: formatDateRange(
                          reservation.attributes.checkInDate,
                          reservation.attributes.checkOutDate,
                        ),
                        guests: reservation.attributes.numberOfGuests,
                      }}
                    />
                  </p>
                </div>
              </div>
              <div className={useDesktopButtons ? "" : "ion-hide-md-down"}>
                {isOwner && canEdit && buttons}
                {!isOwner && canEdit && (
                  <IonButton
                    className="continue-button-stepper"
                    data-testid="request-cancellation"
                    shape="round"
                    onClick={() => setOpenRequestCancel(true)}
                  >
                    <FormattedMessage id="reservation_detail.request_cancellation" />
                  </IonButton>
                )}
              </div>
            </div>
            <HuswellShield
              reservationConfirmed={
                reservation.attributes.status === "confirmed"
              }
              contractSigned={
                reservation.attributes.guests.requiredDocumentsUploaded
              }
              identityVerified={reservation.attributes.guests.identityVerified}
            />
            {isEditing ? (
              <FormProvider {...methods}>
                <ReservationDelayDisclaimer />
                <ReservationEditDetailsForm
                  isEditForm
                  onSubmit={handleUpdate}
                  error={updateError}
                />
              </FormProvider>
            ) : isOwner ? (
              <ReservationDetailOwner
                checkInDate={reservation.attributes.checkInDate}
                checkOutDate={reservation.attributes.checkOutDate}
                numberOfNights={reservation.attributes.numberOfNights}
                mainGuestName={reservation.attributes.guests.mainGuestName}
                numberOfGuests={reservation.attributes.numberOfGuests}
                cleaningRequired={reservation.attributes.cleaningRequired}
              />
            ) : (
              <ReservationDetailContent
                rentalRevenue={reservation.attributes.rentalRevenue}
                revenue={reservation.attributes.revenue}
                bookingReference={reservation.attributes.confirmationCode}
                checkInDate={reservation.attributes.checkInDate}
                checkOutDate={reservation.attributes.checkOutDate}
                numberOfNights={reservation.attributes.numberOfNights}
                otaName={reservation.relationships.ota.name}
                numberOfGuests={reservation.attributes.numberOfGuests}
                mainGuestName={reservation.attributes.guests.mainGuestName}
                email={reservation.attributes.guests.mainGuestEmail}
                phoneNumber={reservation.attributes.guests.mainGuestPhone}
                publicReview={
                  reservationStatus === ReservationStatus.Completed
                    ? reservation.attributes.publicReview
                    : undefined
                }
              />
            )}
          </div>
        </div>
      )}
      {openRequestCancel && (
        <Modal
          isOpen={openRequestCancel}
          small
          onDidDismiss={() => setOpenRequestCancel(false)}
          continueButton={
            cancelSuccess ? undefined : (
              <LoadingButton
                data-testid="continue-button"
                className="continue-button-stepper"
                shape="round"
                expand="full"
                type="submit"
                form="request-cancel-form"
                disabled={isLoadingCancel}
                isLoading={isLoadingCancel}
              >
                <FormattedMessage id="common.continue" />
              </LoadingButton>
            )
          }
        >
          <div className="request-cancel-modal">
            <h5>
              <FormattedMessage id="reservation_detail.modal.title" />
            </h5>
            <p className="description">
              <FormattedMessage id="reservation_detail.modal.description" />
            </p>

            <Form<FormData>
              formId="request-cancel-form"
              onSubmit={handleRequestCancellation}
              defaultValues={defaultValues}
              mode="onSubmit"
            >
              <FormIonTextArea
                name="note"
                labelPlacement="floating"
                fill="solid"
                maxlength={500}
                label={intl.formatMessage({ id: "note.label" })}
                rules={{
                  required: {
                    value: true,
                    message: intl.formatMessage({
                      id: "request_cancellation_note.required",
                    }),
                  },
                }}
              />
            </Form>
            {cancelSuccess && (
              <div className="margin-top-24">
                <SuccessCard title="request_cancellation.success" />
              </div>
            )}
          </div>
        </Modal>
      )}
      {/* backdrop is not ideal in calendar, should be full page if possible but is now only covering the first modal above */}
      <div
        style={{
          position: "fixed",
          inset: 0,
          background: "black",
          opacity: openRequestCancel ? 0.5 : 0,
          zIndex: 11,
          transition: "opacity 0.3s",
          pointerEvents: "none",
        }}
      />
    </>
  );
};

export default Reservation;

import PageWrap from "components/@common/PageWrap/PageWrap";
import RequirementsComponent from "components/@create-listing/Requirements";

const Requirements = () => {
  return (
    <PageWrap>
      <RequirementsComponent />
    </PageWrap>
  );
};

export default Requirements;

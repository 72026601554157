import { SignUpContinue as SignUpContinueComponent } from "components/@authentication/SignUpContinue";
import LogoContainer from "components/@common/LogoContainer";
import PageWrap from "components/@common/PageWrap/PageWrap";

const SignUpContinue = () => {
  return (
    <PageWrap androidStatusBar={{ bgColor: "#241261", iconsColor: "light" }}>
      <LogoContainer>
        <SignUpContinueComponent />
      </LogoContainer>
    </PageWrap>
  );
};

export default SignUpContinue;

import "./SignUpContinue.scss";

import { IonButton, IonText, useIonRouter } from "@ionic/react";
import { FormattedMessage, useIntl } from "react-intl";

import { getQueryParam, QueryParam } from "utils/location.utils";

import { Routes } from "constants/routes.constants";
import { emailRegex } from "constants/validation.constants";
import { useAuthStore } from "store/auth-store";

import Divider from "components/@common/Divider";
import LinkUnstyled from "components/@common/LinkUnstyled";
import SocialLoginOptions from "components/@common/SocialLoginOptions";
import { Form } from "components/@form/Form";
import { FormIonInput } from "components/@form/FormIonInput";

import { LoginFormFields } from "../types";

interface FormData {
  email: string;
}

const SignUpContinue = () => {
  const intl = useIntl();

  const { setValues, email } = useAuthStore((state) => ({
    setValues: state.actions.setValues,
    email: state.values?.email,
  }));

  const router = useIonRouter();

  const handleSubmit = async ({ email }: FormData) => {
    setValues({ email });
    router.push(Routes.CreateAccount);
  };

  const queryEmail = getQueryParam(QueryParam.Email);

  const defaultValues = {
    [LoginFormFields.Email]: (queryEmail ?? email) || "",
  };

  return (
    <div className="container">
      <IonText>
        <h2>
          <FormattedMessage id="sign_up_continue.title" />
        </h2>
      </IonText>
      <p className="description">
        <IonText>
          <FormattedMessage id="sign_up_continue.description" />
        </IonText>
      </p>

      <Form<FormData>
        onSubmit={handleSubmit}
        defaultValues={defaultValues}
        mode="onSubmit"
      >
        <FormIonInput
          data-testid="email-input"
          inputMode="email"
          name={LoginFormFields.Email}
          aria-label={LoginFormFields.Email}
          autocomplete="email"
          type="email"
          fill="solid"
          label={intl.formatMessage({ id: "email.label" })}
          labelPlacement="floating"
          rules={{
            required: {
              value: true,
              message: intl.formatMessage({
                id: "login.field.email.error.required",
              }),
            },
            pattern: {
              value: emailRegex,
              message: intl.formatMessage({
                id: "login.field.email.error.invalid",
              }),
            },
          }}
          placeholder={intl.formatMessage({
            id: "sign_up_continue.email.placeholder",
          })}
        />

        <IonButton
          data-testid="continue-button"
          className="continue-button"
          type="submit"
          shape="round"
          expand="full"
        >
          <FormattedMessage id="sign_up_continue.button.label" />
        </IonButton>
      </Form>

      <Divider />
      <SocialLoginOptions />

      <div className="link-container">
        <p className="margin-24">
          <IonText>
            <FormattedMessage
              id="sign_up_continue.link"
              values={{
                a: (chunks) => (
                  <LinkUnstyled
                    data-testid="sign-in-link"
                    onClick={() =>
                      router.push(Routes.SignInContinue, "back", "pop")
                    }
                  >
                    {chunks}
                  </LinkUnstyled>
                ),
              }}
            />
          </IonText>
        </p>
      </div>
    </div>
  );
};

export default SignUpContinue;

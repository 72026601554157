import "./PublicReviews.scss";

import { IonIcon } from "@ionic/react";
import { FormattedMessage } from "react-intl";

import { formatRating } from "utils/reservation.utils";

import {
  AirBnbReview,
  BookingComReview,
  PublicReview as PublicReviewType,
} from "types/reservation.types";

import Rating from "components/@common/Rating";

interface Props {
  publicReviews: Array<PublicReviewType>; // Accept single or array
}

const safeDivide = (total: number, count: number) =>
  count > 0 ? total / count : 0;

const PublicReviews = ({ publicReviews }: Props) => {
  const reviewCount = publicReviews.length;

  // Initialize total scores
  let totalOverallRating = 0;
  let totalLocationScore = 0;
  let totalCleanlinessScore = 0;
  let totalValueScore = 0;
  let totalAccuracyScore = 0;
  let totalCheckinScore = 0;
  let totalCommunicationScore = 0;
  let totalFacilitiesScore = 0;
  let totalComfortScore = 0;
  let totalStaffScore = 0;

  // Type guards for review types
  const isBookingCom = (
    review: AirBnbReview | BookingComReview,
  ): review is BookingComReview => {
    return (review as BookingComReview).facilitiesScore !== undefined;
  };

  const isAirBnb = (
    review: AirBnbReview | BookingComReview,
  ): review is AirBnbReview => {
    return (review as AirBnbReview).publicReview !== undefined;
  };

  // // Calculate total scores
  publicReviews.forEach((review) => {
    const rawReview = review.rawReview;
    totalOverallRating += safeDivide(rawReview.overallRating, 2);
    totalLocationScore += safeDivide(rawReview.locationScore, 2);
    totalCleanlinessScore += safeDivide(rawReview.cleanlinessScore, 2);
    totalValueScore += safeDivide(rawReview.valueScore, 2);

    // Check for Airbnb specific scores
    if (isAirBnb(rawReview)) {
      totalAccuracyScore += rawReview.accuracyScore;
      totalCheckinScore += rawReview.checkinScore;
      totalCommunicationScore += rawReview.communicationScore;
    }

    // Check for Booking.com specific scores
    if (isBookingCom(rawReview)) {
      totalFacilitiesScore += safeDivide(rawReview.facilitiesScore, 2);
      totalComfortScore += safeDivide(rawReview.comfortScore, 2);
      totalStaffScore += safeDivide(rawReview.staffScore, 2);
    }
  });

  // Calculate averages
  const averageOverallRating = Math.min(
    safeDivide(totalOverallRating, reviewCount),
    5,
  );
  const averageLocationScore = safeDivide(totalLocationScore, reviewCount);
  const averageCleanlinessScore = safeDivide(
    totalCleanlinessScore,
    reviewCount,
  );
  const averageValueScore = safeDivide(totalValueScore, reviewCount);
  const averageAccuracyScore = safeDivide(totalAccuracyScore, reviewCount);
  const averageCheckinScore = safeDivide(totalCheckinScore, reviewCount);
  const averageCommunicationScore = safeDivide(
    totalCommunicationScore,
    reviewCount,
  );
  const averageFacilitiesScore = safeDivide(totalFacilitiesScore, reviewCount);
  const averageComfortScore = safeDivide(totalComfortScore, reviewCount);
  const averageStaffScore = safeDivide(totalStaffScore, reviewCount);

  return (
    <div className="info-block">
      <div className="public-reviews">
        <div className="item large-array">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <p className="bold opacity margin-0">
              <FormattedMessage id="public_review.overall_rating" />
            </p>

            <div className="numeric">
              <h2>
                {averageOverallRating === null
                  ? "-"
                  : formatRating(averageOverallRating)}
              </h2>
              <h4 className="margin-0 bold divisor">/5</h4>
            </div>

            <Rating rating={averageOverallRating} stars small />
          </div>

          <div className="divider-vertical"></div>

          <div className="total-reviews">
            <p className="bold opacity margin-0">
              <FormattedMessage id="public_review.total_reviews" />
            </p>
            <h4>{reviewCount}</h4>
          </div>
        </div>
        <div className="item">
          <div className="review-item">
            <div className="review-item-title">
              <p className="bold opacity margin-0">
                <FormattedMessage id="reservation_detail_review.location" />
              </p>

              <Rating rating={averageLocationScore} />
            </div>
            <IonIcon src="assets/icons/icon-location.svg" />
          </div>
        </div>
        <div className="item">
          <div className="review-item">
            <div className="review-item-title">
              <p className="bold opacity margin-0">
                <FormattedMessage id="reservation_detail_review.cleanliness" />
              </p>

              <Rating rating={averageCleanlinessScore} />
            </div>
            <IonIcon src="assets/icons/icon-spray.svg" />
          </div>
        </div>
        <div className="item">
          <div className="review-item">
            <div className="review-item-title">
              <p className="bold opacity margin-0">
                <FormattedMessage id="reservation_detail_review.value" />
              </p>

              <Rating rating={averageValueScore} />
            </div>
            <IonIcon src="assets/icons/icon-tag.svg" />
          </div>
        </div>
        {totalAccuracyScore > 0 ? (
          <>
            <div className="item">
              <div className="review-item">
                <div className="review-item-title">
                  <p className="bold opacity margin-0">
                    <FormattedMessage id="reservation_detail_review.accuracy" />
                  </p>
                  <Rating rating={averageAccuracyScore} />
                </div>
                <IonIcon src="assets/icons/icon-accuracy.svg" />
              </div>
            </div>

            <div className="item">
              <div className="review-item">
                <div className="review-item-title">
                  <p className="bold opacity margin-0">
                    <FormattedMessage id="reservation_detail_review.checkin" />
                  </p>
                  <Rating rating={averageCheckinScore} />
                </div>
                <IonIcon src="assets/icons/icon-checkin.svg" />
              </div>
            </div>

            <div className="item">
              <div className="review-item">
                <div className="review-item-title">
                  <p className="bold opacity margin-0">
                    <FormattedMessage id="reservation_detail_review.communication" />
                  </p>
                  <Rating rating={averageCommunicationScore} />
                </div>
                <IonIcon src="assets/icons/icon-communication.svg" />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="item">
              <div className="review-item">
                <div className="review-item-title">
                  <p className="bold opacity margin-0">
                    <FormattedMessage id="reservation_detail_review.facilities" />
                  </p>
                  <Rating rating={averageFacilitiesScore} />
                </div>
                <IonIcon src="assets/icons/icon-appartment.svg" />
              </div>
            </div>
            <div className="item">
              <div className="review-item">
                <div className="review-item-title">
                  <p className="bold opacity margin-0">
                    <FormattedMessage id="reservation_detail_review.comfort" />
                  </p>
                  <Rating rating={averageComfortScore} />
                </div>
                <IonIcon src="assets/icons/icon-bed.svg" />
              </div>
            </div>
            <div className="item">
              <div className="review-item">
                <div className="review-item-title">
                  <p className="bold opacity margin-0">
                    <FormattedMessage id="reservation_detail_review.staff" />
                  </p>
                  <Rating rating={averageStaffScore} />
                </div>
                <IonIcon src="assets/icons/icon-guests.svg" />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PublicReviews;

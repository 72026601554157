import { OverviewStep } from "types/listing.types";

import LaunchpadComponent from "components/@common/Launchpad";
import PageWrap from "components/@common/PageWrap/PageWrap";

const Launchpad = () => {
  return (
    <PageWrap>
      <LaunchpadComponent highlightedStep={OverviewStep.Review} />
    </PageWrap>
  );
};

export default Launchpad;

import "./Support.scss";

import { IonAvatar, IonButton, IonIcon, IonText } from "@ionic/react";
import { mailOutline } from "ionicons/icons";
import { FormattedMessage } from "react-intl";

import { supportLinksMap } from "constants/menu.constants";
import { useListingSuccessManager } from "queries";

import { getListingId } from "components/@listing-detail/utils";

const Support = () => {
  const listingId = getListingId();
  const { data, isLoading } = useListingSuccessManager(listingId || "", {
    enabled: !!listingId,
  });

  const name = `${data?.firstName || ""} ${data?.lastName || ""}`.trim();

  return (
    <div className="support-block">
      <h5>
        <FormattedMessage id="support_block.title" />
      </h5>

      <div className="support-grid">
        <div className="contact">
          <h5>
            <FormattedMessage id="support_block.contact.title" />
          </h5>

          <p className="margin-0">
            <FormattedMessage id="support_block.contact.description" />
          </p>

          <div className="contact-buttons">
            <IonButton
              className="contact-button"
              shape="round"
              href={supportLinksMap[0].href}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="support_block.contact.problem" />
            </IonButton>

            <IonButton
              className="contact-button"
              shape="round"
              href={supportLinksMap[1].href}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="support_block.contact.chat" />
            </IonButton>
          </div>
        </div>

        {data && !isLoading && (
          <div className="success-manager">
            <h5>
              <FormattedMessage id="support_block.manager.title" />
            </h5>

            <p className="margin-0">
              <FormattedMessage id="support_block.manager.description" />
            </p>

            <div className="success-manager-info">
              <div className="profile">
                <IonAvatar>
                  <img
                    alt="Profile photo"
                    //TODO: Replace with actual image from api
                    src="https://thetreeparty.nl/wp-content/uploads/2018/05/avatar-placeholder.png"
                  />
                </IonAvatar>

                <div className="profile-info">
                  <IonText color="primary">
                    <h6 style={{ fontWeight: 400 }}>{name}</h6>
                  </IonText>
                  <p className="margin-0 small bold">
                    <FormattedMessage id="support_block.manager.role" />
                  </p>
                </div>
              </div>
              <div className="social-buttons">
                <IonButton
                  shape="round"
                  className="social-button"
                  href={`mailto:${data?.email}`}
                >
                  <IonIcon icon={mailOutline} />
                </IonButton>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Support;

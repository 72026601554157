import { IonButton } from "@ionic/react";
import { FormattedMessage } from "react-intl";

import { ButtonProps } from "./types";

const OwnerCancelEditButton = ({ onClick }: ButtonProps) => (
  <IonButton
    className="owner-button"
    data-testid="cancel-edit-reservation"
    shape="round"
    color="light"
    onClick={onClick}
  >
    <FormattedMessage id="button.cancel" />
  </IonButton>
);

export default OwnerCancelEditButton;

import { IonButton } from "@ionic/react";
import { FormattedMessage } from "react-intl";

import { ButtonProps } from "./types";

const RequestCancellationButton = ({ onClick }: ButtonProps) => (
  <IonButton
    className="width-100 owner-button"
    data-testid="request-cancellation"
    shape="round"
    onClick={onClick}
  >
    <FormattedMessage id="reservation_detail.request_cancellation" />
  </IonButton>
);

export default RequestCancellationButton;

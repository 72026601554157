import "./Legend.scss";

import { IonButton, useIonRouter } from "@ionic/react";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { generatePath } from "react-router";

import { Routes } from "constants/routes.constants";
import { useMediaQuery } from "hooks/useMediaQuery";

import LinkUnstyled from "components/@common/LinkUnstyled";
import Modal from "components/@common/Modal";

const legendItems: Array<{ title: I18nKey; color: string }> = [
  {
    title: "calendar.guest_reservation",
    color: "var(--ion-color-success)",
  },
  {
    title: "calendar.owner_reservation",
    color: "var(--ion-color-secondary-shade)",
  },
  { title: "calendar.owner_block", color: "#DAD9D8" },
];

interface Props {
  selectedListingId: string | null;
}

const CalendarLegend = ({ selectedListingId }: Props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const showMobileVersion = useMediaQuery("(max-width: 1000px)");
  const router = useIonRouter();

  const legend = (
    <>
      {legendItems.map(({ title, color }) => (
        <div key={title} className="legend-item">
          <div
            style={{
              backgroundColor: color,
              borderRadius: "100%",
              width: 14,
              height: 14,
            }}
          />
          <span style={{ fontSize: 14, fontWeight: 500 }}>
            <FormattedMessage id={title} />
          </span>
        </div>
      ))}
    </>
  );

  return (
    <div className="legend-wrap">
      <div className="legend-link">
        {showMobileVersion ? (
          <>
            <LinkUnstyled onClick={() => setIsDialogOpen(true)}>
              <FormattedMessage id="calendar.show_legend" />
            </LinkUnstyled>
            <Modal
              small
              onDidDismiss={() => setIsDialogOpen(false)}
              isOpen={isDialogOpen}
            >
              <h5 style={{ marginBottom: 24 }}>
                <FormattedMessage id="calendar.show_legend" />
              </h5>
              <div className="legend-modal-content">{legend}</div>
            </Modal>
          </>
        ) : (
          legend
        )}
      </div>

      {selectedListingId && (
        <IonButton
          shape="round"
          style={{ height: 50, margin: 0 }}
          onClick={() => {
            router.push(
              generatePath(
                Routes.CreateReservationType +
                  `?backroute=${router.routeInfo.pathname}` +
                  `?listingId=${selectedListingId}`,
                {
                  listingId: selectedListingId,
                  reservationId: undefined,
                },
              ),
              "forward",
            );
          }}
        >
          <FormattedMessage id="calendar.create_reservation" />
        </IonButton>
      )}
    </div>
  );
};

export default CalendarLegend;

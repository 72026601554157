import "./SplashScreen.scss";

import { StatusBar } from "@capacitor/status-bar";
import { useEffect, useLayoutEffect, useState } from "react";

import { isAndroid, isNative } from "utils/capacitor.utils";

import { APP_BG_COLOR, SPLASH_BG_COLOR } from "constants/colors.constants";
import { useIsAppLoaded } from "hooks";
import { useUIStore } from "store/ui-store";

const TRANSITION_DURATION = 300;

const Splash = () => {
  const { isLoaded: appIsLoaded } = useIsAppLoaded();

  const [splashIsLoading, setSplashIsLoading] = useState(true);
  const [splashIsPlaying, setSplashIsPlaying] = useState(false);
  const [splashWillHide, setSplashWillHide] = useState(false);
  const { splashHasEnded, setSplashHasEnded, isLoading } = useUIStore(
    (state) => ({
      splashHasEnded: state.splashHasEnded,
      setSplashHasEnded: state.setSplashHasEnded,
      isLoading: state.isLoading,
    }),
  );

  useLayoutEffect(() => {
    if (!isNative) return;
    document.documentElement.style.background = SPLASH_BG_COLOR;
    document.body.style.background = SPLASH_BG_COLOR;
    (document.getElementById("root") as HTMLElement).style.background =
      SPLASH_BG_COLOR;

    if (isAndroid) {
      StatusBar.setOverlaysWebView({ overlay: true });
    }

    setSplashIsLoading(true);
    setSplashHasEnded(false);
  }, [setSplashHasEnded]);

  const handleSplashIsLoaded = () => {
    setSplashIsLoading(false);
    setSplashIsPlaying(true);
  };

  useEffect(() => {
    if (!isNative) return;
    let splashTimeout: NodeJS.Timeout;
    if (!splashIsLoading && !splashIsPlaying && appIsLoaded) {
      setSplashWillHide(true);
      document.body.style.background = APP_BG_COLOR;
      document.documentElement.style.background = APP_BG_COLOR;
      (document.getElementById("root") as HTMLElement).style.background =
        APP_BG_COLOR;
      splashTimeout = setTimeout(() => {
        setSplashHasEnded(true);
        setSplashWillHide(false);
        if (isAndroid) {
          StatusBar.setOverlaysWebView({ overlay: false });
        }
      }, TRANSITION_DURATION);
    }
    return () => clearTimeout(splashTimeout);
  }, [appIsLoaded, setSplashHasEnded, splashIsLoading, splashIsPlaying]);

  if (!isNative || splashHasEnded || isLoading) return;
  return (
    <div
      className="splash-screen"
      style={{
        transform: splashWillHide ? "scale(1.2)" : "scale(1)",
        opacity: splashWillHide ? 0 : 1,
        transition: `all ${TRANSITION_DURATION}ms ease-out`,
      }}
    >
      <video
        className="splash-video"
        autoPlay
        poster="/assets/splash/splash-poster.png"
        muted
        playsInline
        controls={false}
        onLoadedData={handleSplashIsLoaded}
        onError={() => setSplashIsPlaying(false)}
        onEnded={() => setSplashIsPlaying(false)}
        style={{
          opacity: splashWillHide ? 0 : 1,
          transition: `all ${TRANSITION_DURATION}ms ease-out`,
        }}
      >
        <source src="/assets/splash/logo.mp4" type="video/mp4" />
      </video>
    </div>
  );
};

export default Splash;

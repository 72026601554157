import { isAndroid, isIos } from "./capacitor.utils";
import { isDev } from "./env.utils";
import { getTopLevelDomainFromCurrentLocation } from "./location.utils";

export const nativeFrontendUrls = {
  ios: "huswell://localhost",
  android: "https://localhost",
};

export const getNativeFrontendUrl = () => {
  if (isIos) return nativeFrontendUrls.ios;
  if (isAndroid) return nativeFrontendUrls.android;

  return "";
};

export const getWebFrontendUrl = () => {
  const tld = getTopLevelDomainFromCurrentLocation();
  let frontendUrl = isDev()
    ? (process.env.REACT_APP_API_REDIRECT_AMPLIFY_URL ?? "")
    : `${process.env.REACT_APP_API_REDIRECT_AMPLIFY_URL}.${tld}`;

  if (frontendUrl.endsWith("/")) {
    frontendUrl = frontendUrl.slice(0, -1);
  }

  return frontendUrl;
};

export const getFrontendUrl = () => {
  return isIos || isAndroid ? getNativeFrontendUrl() : getWebFrontendUrl();
};

import "./Navigation.scss";

import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonSpinner,
  IonTabBar,
  IonTabButton,
  IonTabs,
  iosTransitionAnimation,
  useIonRouter,
} from "@ionic/react";
import { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { matchPath, Redirect, useLocation } from "react-router";
import { Route } from "react-router-dom";

import { isAndroid, isNative } from "utils/capacitor.utils";
import { formatTabRoute } from "utils/router.utils";

import { Routes } from "constants/routes.constants";
import { useIsEmailVerified } from "hooks/useIsEmailVerified";
import { useMediaQuery } from "hooks/useMediaQuery";
import { useCurrentUser } from "services/auth/useCurrentUser";
import { useUIStore } from "store/ui-store";

import {
  FormSwitcher,
  FormSwitchProvider,
} from "components/@form/FormSwitcher";
import {
  authenticatedRoutes,
  mobileUntabbedRoutes,
  unauthenticatedRoutes,
  untabbedRoutes,
} from "components/@routes/AppRoutes/constants";
import TopNav from "components/@routes/AppRoutes/TopNav";

import { authRoutes, tabRoutes } from "./tab-routes.consts";
import { useTabs } from "./tabs";

const AppRoutes = () => {
  const location = useLocation();
  const router = useIonRouter();
  const { isLoggedIn, isLoading, user } = useCurrentUser();
  const profileCompleted = !!user?.data.attributes.profileCompleted;
  const [showNavigation, setShowNavigation] = useState(false);
  const tabs = useTabs("mobile");

  const emailVerified = useIsEmailVerified();

  const uiStoreLoading = useUIStore((state) => state.isLoading);
  const uiIsLoading = useMemo(
    () => isLoading || uiStoreLoading,
    [isLoading, uiStoreLoading],
  );
  const isDesktop = useMediaQuery("(min-width: 769px)");
  useEffect(() => {
    const matches = (isDesktop ? untabbedRoutes : mobileUntabbedRoutes).some(
      (untabbedRoute) => matchPath(location.pathname, { path: untabbedRoute }),
    );
    setShowNavigation(!matches);
  }, [isDesktop, location.pathname]);

  // Handle redirection based on authentication status. This is necessary because
  // IonRouterOutlet does not unmount components which can lead to unwanted side effects when using ProtectedRoutes
  // This effect will redirect to the correct route based on the authentication status and the profile completion status
  useEffect(() => {
    const isPathAuthenticated =
      !unauthenticatedRoutes.some((unauthenticatedRoute) =>
        matchPath(location.pathname, { path: unauthenticatedRoute }),
      ) ||
      authenticatedRoutes.some((authenticatedRoute) =>
        matchPath(location.pathname, { path: authenticatedRoute }),
      );

    if (!isLoading) {
      if (isPathAuthenticated && !isLoggedIn) {
        // Redirect unauthenticated user to login page
        return router.push(Routes.SignInContinue, "root", "replace");
      } else if (
        !profileCompleted &&
        isLoggedIn &&
        location.pathname !== Routes.SignUpDetails &&
        location.pathname !== Routes.SignUpWelcome
      ) {
        return router.push(Routes.SignUpDetails);
      } else if (!isPathAuthenticated && isLoggedIn) {
        return router.push(Routes.Listings);
      }
    }
  }, [isLoading, isLoggedIn, location.pathname, profileCompleted, router]);

  if (uiIsLoading) {
    return (
      <div
        style={{
          height: "100svh",
          width: "100svw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <IonSpinner style={{ width: 60, height: 60 }} />
      </div>
    );
  }

  return (
    <>
      {showNavigation && <TopNav emailVerified={emailVerified} />}

      {/* 
        Form providers which cover multiple routes should be placed outside of the IonRouterOutlet
        -> grouping the routes in the form provider will make the route itself not a direct child of IonRouterOutlet,
           which will break the navigation, animations and back gestures
        -> the providers switch depending on the current route, so the form state is maintained across the grouped routes
        -> add more form providers as needed in the FormSwitcher.tsx component
      */}
      <FormSwitchProvider>
        <FormSwitcher>
          <IonTabs>
            <IonRouterOutlet
              animated={isNative}
              animation={iosTransitionAnimation}
            >
              {Object.entries(tabRoutes).map(
                ([name, { path, Component, exact }]) => (
                  <Route
                    path={formatTabRoute(path)}
                    component={Component}
                    exact={exact}
                    key={name}
                  />
                ),
              )}
              {Object.entries(authRoutes).map(([name, { path, Component }]) => (
                <Route
                  path={formatTabRoute(path)}
                  component={Component}
                  key={name}
                />
              ))}
              <Redirect
                exact
                path={Routes.CreateListing}
                to={Routes.CreateListingLaunchpad}
              />
              <Redirect
                exact
                path={Routes.CompleteListing}
                to={Routes.CompleteListingLaunchpad}
              />
              <Redirect
                exact
                path={Routes.ReviewListing}
                to={Routes.ReviewListingLaunchpad}
              />
              <Redirect
                exact
                path={Routes.CreateReservation}
                to={Routes.CreateReservationType}
              />
              <Redirect exact path="/" to={Routes.Listings} />
            </IonRouterOutlet>
            <IonTabBar
              slot="bottom"
              className={isAndroid ? "tab-bar-android" : ""}
              style={{
                transform: showNavigation
                  ? "translateY(0)"
                  : "translateY(100%)",
                transition: "transform 0.2s ease",
                position: "fixed",
                zIndex: "0",
                bottom: "0",
                left: "0",
                right: "0",
              }}
            >
              {tabs.map(({ icon, label, route, disabled }) => (
                <IonTabButton
                  key={route}
                  tab={route}
                  href={route}
                  disabled={disabled}
                >
                  <IonIcon src={icon} />
                  <IonLabel>
                    <FormattedMessage id={label} />
                  </IonLabel>
                </IonTabButton>
              ))}
            </IonTabBar>
          </IonTabs>
        </FormSwitcher>
      </FormSwitchProvider>
    </>
  );
};

export default AppRoutes;

import PageWrap from "components/@common/PageWrap/PageWrap";
import TypeComponent from "components/@create-reservation/Type";

const Type = () => {
  return (
    <PageWrap>
      <TypeComponent />
    </PageWrap>
  );
};

export default Type;

import { SignUp } from "components/@authentication/SignUp";
import LogoContainer from "components/@common/LogoContainer";
import PageWrap from "components/@common/PageWrap/PageWrap";

const CreateAccount = () => {
  return (
    <PageWrap androidStatusBar={{ bgColor: "#241261", iconsColor: "light" }}>
      <LogoContainer>
        <SignUp />
      </LogoContainer>
    </PageWrap>
  );
};

export default CreateAccount;

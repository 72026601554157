import { IonContent, useIonRouter } from "@ionic/react";

import { Routes } from "constants/routes.constants";
import { useMediaQuery } from "hooks/useMediaQuery";

import PageWrap from "components/@common/PageWrap/PageWrap";
import MobileMenuComponent from "components/@mobile-menu/MobileMenu/MobileMenu";

const MobileMenu = () => {
  const router = useIonRouter();
  const isDesktop = useMediaQuery("(min-width: 769px)");
  if (isDesktop) {
    router.push(Routes.Preferences, "forward", "replace");
  }
  return (
    <PageWrap safeArea={{ show: true }}>
      <IonContent>
        <MobileMenuComponent />
      </IonContent>
    </PageWrap>
  );
};

export default MobileMenu;

import "./IOSSafeArea.scss";

import { IonHeader, IonToolbar } from "@ionic/react";
import { ReactNode } from "react";

import { isAndroid, isIos } from "utils/capacitor.utils";

import { APP_BG_COLOR } from "constants/colors.constants";
import { useMediaQuery } from "hooks/useMediaQuery";

type Props = {
  children?: ReactNode;
  showAndroid?: boolean;
  bgColor?: string;
};

const IOSSafeArea = ({
  children,
  showAndroid,
  bgColor = APP_BG_COLOR,
}: Props) => {
  const isDesktop = useMediaQuery("(min-width: 769px)");

  if (isDesktop || (isAndroid && !showAndroid)) return null;
  return (
    <IonHeader class={`ios-safe-area ${isAndroid && "android-top-padding"}`}>
      {isIos && (
        <IonToolbar style={{ "--background": bgColor || "transparent" }} />
      )}
      {children}
    </IonHeader>
  );
};

export default IOSSafeArea;

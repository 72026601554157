import "./Details.scss";

import { IonSpinner } from "@ionic/react";
import { useFormContext, useWatch } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import { cleaningRequiredKeys } from "constants/reservation.constants";
import { useBlockReasons } from "queries/blocks";
import {
  CleaningRequiredAnswer,
  ReservationType,
} from "types/reservation.types";

import ErrorCard from "components/@common/ErrorCard";
import FormCounter from "components/@form/FormCounter";
import { FormIonInput } from "components/@form/FormIonInput";
import FormIonTextarea from "components/@form/FormIonTextArea";
import FormRadioOptionGroup from "components/@form/FormRadioGroupOption";

interface Props {
  error?: Error | null;
  onSubmit: () => void;
  isEditForm?: boolean;
}

const ReservationEditDetailsForm = ({ error, onSubmit, isEditForm }: Props) => {
  const intl = useIntl();
  const { getValues, handleSubmit, setValue } = useFormContext<{
    checkInDate: string;
    checkOutDate: string;
    reasonId: string;
    reservationType: ReservationType | null;
    note?: string;
    cleaningRequired: boolean;
    numberOfGuests: number;
  }>();

  const reservationType = useWatch({ name: "reservationType" });

  const isBlock = reservationType === ReservationType.Block;
  const { data: blockReasons, isLoading: isLoadingBlockReasons } =
    useBlockReasons({
      enabled: isBlock,
    });

  return (
    <form
      id="hook-form-details"
      className="create-reservation-details"
      onSubmit={handleSubmit(onSubmit)}
    >
      {error && (
        <div style={{ marginTop: 12 }}>
          <ErrorCard title={<FormattedMessage id="common.error_unknown" />} />
        </div>
      )}

      <h6 className="subtitle">
        <FormattedMessage id="create_reservation_details.dates_subtitle" />
      </h6>

      <div className="date-container">
        <div className="date-items">
          <div className="date-item">
            <FormIonInput
              name="checkInDate"
              data-testid="create-reservation-start-date-input"
              aria-label="Start Date"
              type="date"
              fill="solid"
              onIonChange={(e) => {
                const checkOutDate = getValues("checkOutDate");
                const value = e.detail.value;
                if (value && (!checkOutDate || value > checkOutDate)) {
                  setValue("checkOutDate", value);
                }
              }}
              label={intl.formatMessage({ id: "check_in.label" })}
              labelPlacement="floating"
              rules={{
                required: {
                  value: true,
                  message: intl.formatMessage({
                    id: "requirements.error.required",
                  }),
                },
                validate: (value) =>
                  new Date(value) > new Date() ||
                  intl.formatMessage({ id: "date_error.future" }),
              }}
            />
          </div>

          <div className="date-item">
            <FormIonInput
              name="checkOutDate"
              data-testid="create-reservation-end-date-input"
              aria-label="End Date"
              type="date"
              fill="solid"
              label={intl.formatMessage({ id: "check_out.label" })}
              labelPlacement="floating"
              rules={{
                required: {
                  value: true,
                  message: intl.formatMessage({
                    id: "requirements.error.required",
                  }),
                },
                validate: (value) => {
                  const checkInDate = getValues("checkInDate");
                  return (
                    new Date(value) > new Date(checkInDate) ||
                    intl.formatMessage({
                      id: "checkout.date.error",
                    })
                  );
                },
              }}
            />
          </div>
        </div>
      </div>

      <h6 className="subtitle">
        <FormattedMessage
          id={
            isBlock
              ? "create_reservation_details.blocking_reason_subtitle"
              : "create_reservation_details.guests_subtitle"
          }
        />
      </h6>

      {isBlock ? (
        <>
          {isLoadingBlockReasons && <IonSpinner name="crescent" />}
          {blockReasons && !isLoadingBlockReasons && (
            <>
              <FormRadioOptionGroup
                name="reasonId"
                options={blockReasons.map((reason) => ({
                  label: (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 4,
                      }}
                    >
                      <p className="margin-0">
                        {reason.attributes.displayName}
                      </p>
                      <p className="margin-0 extra-small">
                        {reason.attributes.description}
                      </p>
                    </div>
                  ),
                  value: reason.id,
                }))}
                rules={{
                  required: intl.formatMessage({
                    id: "requirements.error.required",
                  }),
                }}
              />
              {isEditForm && (
                <>
                  <h6 className="form-label">
                    <FormattedMessage id="create_reservation_overview.reason.note.title" />
                  </h6>
                  <FormIonTextarea
                    name="note"
                    labelPlacement="floating"
                    fill="solid"
                    label={intl.formatMessage({
                      id: "create_reservation.block.reason.label",
                    })}
                  />
                </>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <FormCounter
            name="numberOfGuests"
            label={<FormattedMessage id="create_reservation_details.guests" />}
          />
          {isEditForm && (
            <>
              <h6 className="form-label">
                <FormattedMessage id="services.cleaning.label" />
              </h6>
              <FormRadioOptionGroup
                name="cleaningRequired"
                options={[
                  {
                    label: cleaningRequiredKeys[CleaningRequiredAnswer.Yes],
                    value: CleaningRequiredAnswer.Yes,
                  },
                  {
                    label: cleaningRequiredKeys[CleaningRequiredAnswer.No],
                    value: CleaningRequiredAnswer.No,
                  },
                ]}
                rules={{
                  required: {
                    value: true,
                    message: intl.formatMessage({
                      id: "requirements.error.required",
                    }),
                  },
                }}
              />
            </>
          )}
        </>
      )}
    </form>
  );
};

export default ReservationEditDetailsForm;

import "./Overview.scss";

import { useIonRouter } from "@ionic/react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import { captureMessage } from "utils/sentry.utils";

import { Routes } from "constants/routes.constants";
import { useUpdateListing } from "queries";
import { Flow } from "types/flow.types";
import {
  CompleteListingProviderValues,
  ListingStatus,
} from "types/listing.types";

import FilePreview from "components/@common/FileSelector/FilePreview/FilePreview";
import LoadingButton from "components/@common/LoadingButton";
import StepperContainer from "components/@common/StepperContainer";

import AboutCard from "./AboutCard";
import AmenitiesCard from "./AmenitiesCard";
import HouseRulesCard from "./HouseRulesCard";
import OverviewCard from "./OverviewCard";
import PhotosCard from "./PhotosCard";
import SleepingArrangementsCard from "./SleepingArrangementsCard";

const Overview = () => {
  const intl = useIntl();
  const { updateListing, isLoading } = useUpdateListing();
  const { getValues } = useFormContext<CompleteListingProviderValues>();
  const values = getValues();

  const router = useIonRouter();

  const handleSubmit = () => {
    updateListing(
      {
        id: values.listingId,
        attributes: { status: ListingStatus.Preparing },
      },
      {
        onSuccess: () => router.push(Routes.Listings, "root", "replace"),
        onError: (error) => {
          captureMessage("Error updating status in listing completion", {
            level: "error",
            extra: { error },
          });
        },
      },
    );
  };

  return (
    <StepperContainer
      flow={Flow.CompleteListing}
      route={Routes.CompleteListingOverview}
      continueButton={
        <LoadingButton
          data-testid="continue-button"
          className="secondary-button width-100"
          type="submit"
          shape="round"
          expand="full"
          isLoading={isLoading}
          disabled={isLoading}
          onClick={handleSubmit}
        >
          <FormattedMessage id="complete_listing_overview.submit" />
        </LoadingButton>
      }
    >
      <h2 className="margin-24">
        <FormattedMessage id="complete_listing_overview.title" />
      </h2>
      <p className="create-listing-description overview-description">
        <FormattedMessage id="complete_listing_overview.description" />
      </p>

      <div className="overview-container">
        <AboutCard
          title={values.title}
          neighborhood={values.neighborhood}
          region={values.region}
          unit={values.unit}
          appartment={values.appartment}
          floorNumber={values.floorNumber}
          floorAmount={values.floorAmount}
        />
        <AmenitiesCard amenities={values.amenities} />
        <SleepingArrangementsCard
          sleepingArrangements={values.sleepingArrangements}
        />
        <PhotosCard pictures={values.pictures} />

        <HouseRulesCard houseRules={values.houseRules} />

        <OverviewCard
          title="complete_listing_overview.regulations.title"
          route={Routes.CompleteListingCompliance}
        >
          <p className="margin-0 extra-small">
            <FormattedMessage
              id="complete_listing_overview.license_number"
              values={{
                license: values.regulations.license ? (
                  <b>{values.regulations.license}</b>
                ) : (
                  <em>
                    {intl.formatMessage({
                      id: "complete_listing_overview.not_set",
                    })}
                  </em>
                ),
              }}
            />
          </p>
          <FilePreview fileUrls={values.regulations.documents} />
        </OverviewCard>
      </div>
    </StepperContainer>
  );
};
export default Overview;

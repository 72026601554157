import PageWrap from "components/@common/PageWrap/PageWrap";
import OverviewComponent from "components/@create-reservation/Overview";

const Overview = () => {
  return (
    <PageWrap>
      <OverviewComponent />
    </PageWrap>
  );
};

export default Overview;

import { ResetPasswordOtp as ResetPasswordOtpComponent } from "components/@authentication/ResetPasswordOtp";
import LogoContainer from "components/@common/LogoContainer";
import PageWrap from "components/@common/PageWrap/PageWrap";

const ResetPasswordOtp = () => {
  return (
    <PageWrap androidStatusBar={{ bgColor: "#241261", iconsColor: "light" }}>
      <LogoContainer>
        <ResetPasswordOtpComponent />
      </LogoContainer>
    </PageWrap>
  );
};

export default ResetPasswordOtp;

import "./ListingTableRow.scss";

import { IonIcon, IonText } from "@ionic/react";
import { cameraOutline, eyeOutline } from "ionicons/icons";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { FormattedMessage } from "react-intl";

import { useListing } from "queries";
import { OwnerType } from "types/listing.types";

import { RefreshHandle } from "components/@common/PullToRefresh";
import {
  getListingAddress,
  useOwnerType,
} from "components/@listing-detail/utils";
import ListingButton from "components/@listing-table/ListingTableRow/ListingButton";
import ListingStatusChip from "components/@listing-table/ListingTableRow/ListingStatusChip";

interface Props {
  listingId: string;
  nickname: string;
}

const ListingTableRow = forwardRef<RefreshHandle, Props>(
  function ListingTableRow({ listingId, nickname }, ref) {
    const buttonRef = useRef<HTMLIonButtonElement>(null);
    const { data, isLoading, isError, refetch } = useListing(listingId ?? "", {
      enabled: !!listingId,
    });
    useImperativeHandle(ref, () => ({ refresh: refetch }));

    const hasViewAccess = useOwnerType(data) === OwnerType.SUB;

    if (isLoading) return <LoadingState />;
    if (isError || !data) {
      return (
        <LoadingState
          isError
          listingId={listingId}
          listingNickname={nickname}
        />
      );
    }

    const listing = data?.attributes;
    const picture = listing.pictures?.[0];

    const address = getListingAddress(listing);

    const handleClickMobile = () => {
      const vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0,
      );
      if (vw <= 940) buttonRef.current?.click();
    };

    return (
      <div className="listing-table-row" onClick={handleClickMobile}>
        <div className="overview" style={{ flex: 3 }}>
          {picture ? (
            <img className="img img-desktop" src={picture} />
          ) : (
            <div className="img img-desktop">
              <IonIcon icon={cameraOutline} />
              <FormattedMessage id="listing_overview.no_photos" />
            </div>
          )}

          <div className="details">
            <div className="nickname-and-address">
              <div>
                <p
                  className="margin-0"
                  style={{
                    fontSize: 13,
                    fontWeight: 600,
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 4,
                    marginBottom: 4,
                  }}
                >
                  <IonText color="primary">{listing.nickname}</IonText>
                  {!!listing.nickname && hasViewAccess && "·"}
                  {hasViewAccess && (
                    <span
                      style={{
                        textTransform: "uppercase",
                        color: "var(--ion-text-color-step-800)",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <IonIcon icon={eyeOutline} style={{ fontSize: 14 }} />
                      <FormattedMessage id="listing.settings.owners.view_access" />
                    </span>
                  )}
                </p>
              </div>

              <h5>{listing.title}</h5>
              <span>{address}</span>
            </div>
            <div className="rooms">
              <div>
                <IonIcon src="assets/icons/icon-bedroom.svg" />
                {listing.bedrooms ?? 0}
              </div>
              <div>
                <IonIcon src="assets/icons/icon-bed.svg" />
                {listing.beds ?? 0}
              </div>
              <div>
                <IonIcon src="assets/icons/icon-bath.svg" />
                {listing.bathrooms ?? 0}
              </div>
              <div>
                <IonIcon src="assets/icons/icon-guests.svg" />
                {listing.maximumOccupancy ?? 0}
              </div>
            </div>

            {/* <div style={{ flex: 1 }}>Rating mobile @TODO</div> */}

            <div className="status-chip-mobile">
              <ListingStatusChip status={listing.status} />
            </div>
          </div>
        </div>
        {/* <div style={{ flex: 1 }}>Rating desktop @TODO</div> */}

        {picture ? (
          <img className="img img-mobile" src={picture} />
        ) : (
          <div className="img img-mobile">
            <IonIcon icon={cameraOutline} />
          </div>
        )}

        <div className="actions-and-status">
          <div className="status-chip-desktop" style={{ flex: 1 }}>
            <ListingStatusChip status={listing.status} />
          </div>

          <div className="actions" style={{ flex: 2 }}>
            <ListingButton
              listing={listing}
              listingId={listingId}
              ref={buttonRef}
            />
          </div>
        </div>
      </div>
    );
  },
);

type LoadingStateProps = {
  isError?: boolean;
  listingId?: string;
  listingNickname?: string;
};

const LoadingState = ({
  isError,
  listingId,
  listingNickname,
}: LoadingStateProps) => {
  return (
    <div className="listing-table-row">
      <div className="overview">
        <div className="img img-desktop"></div>
        <div className="details" style={{ gap: 16 }}>
          {isError ? (
            <>
              <IonText color="danger">
                <FormattedMessage id="listing_overview.error" />
              </IonText>
              {listingNickname && <span>{listingNickname}</span>}
              <span>
                id: <strong>{listingId}</strong>
              </span>
            </>
          ) : (
            <>
              <div
                style={{
                  background: "var(--ion-background-color)",
                  height: 32,
                  width: "100%",
                  maxWidth: 256,
                  borderRadius: 8,
                }}
              />
              <div
                style={{
                  background: "var(--ion-background-color)",
                  height: 20,
                  maxWidth: 200,
                  width: "75%",
                  borderRadius: 8,
                }}
              />
            </>
          )}
        </div>
      </div>
      <div className="img img-mobile"></div>
    </div>
  );
};

export default ListingTableRow;

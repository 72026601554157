import { IonButton, useIonRouter } from "@ionic/react";
import { useCallback } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath } from "react-router";

import { cleaningRequiredKeys } from "constants/reservation.constants";
import { Routes } from "constants/routes.constants";
import { Flow } from "types/flow.types";
import { CleaningRequiredAnswer } from "types/reservation.types";

import StepperContainer from "components/@common/StepperContainer";
import FormRadioOptionGroup from "components/@form/FormRadioGroupOption";

const CleaningRequired = () => {
  const router = useIonRouter();
  const intl = useIntl();

  const listingId = useWatch({ name: "listingId" });
  const reservationId = useWatch({ name: "reservationId" });

  const { handleSubmit } = useFormContext();

  const backRoute =
    new URLSearchParams(window.location.search).get("backroute") ||
    Routes.Reservations;

  const handleBack = useCallback(() => {
    router.push(
      generatePath(
        Routes.CreateReservationDetails + `?backroute=${backRoute}`,
        {
          listingId,
          reservationId: reservationId ? reservationId : undefined,
        },
      ),
      "none",
    );
  }, [listingId, reservationId, router, backRoute]);

  const onSubmit = () => {
    router.push(
      generatePath(
        Routes.CreateReservationOverview + `?backroute=${backRoute}`,
        {
          listingId,
          reservationId: reservationId ? reservationId : undefined,
        },
      ),
    );
  };

  return (
    <StepperContainer
      flow={Flow.CreateReservation}
      route={Routes.CreateReservationDetails}
      backRoute={backRoute}
      continueButton={
        <IonButton
          data-testid="continue-button"
          className="continue-button-stepper"
          type="submit"
          shape="round"
          expand="full"
          form="hook-form-cleaning"
        >
          <FormattedMessage id="common.continue" />
        </IonButton>
      }
      backButton={
        <IonButton
          className="contrast-button"
          shape="round"
          onClick={handleBack}
        >
          <FormattedMessage id="back.button" />
        </IonButton>
      }
    >
      <h2 className="margin-24">
        <FormattedMessage id="services.cleaning.label" />
      </h2>

      <form
        id="hook-form-cleaning"
        className="create-reservation-cleaning"
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormRadioOptionGroup
          name="cleaningRequired"
          options={[
            {
              label: cleaningRequiredKeys[CleaningRequiredAnswer.Yes],
              value: CleaningRequiredAnswer.Yes,
            },
            {
              label: cleaningRequiredKeys[CleaningRequiredAnswer.No],
              value: CleaningRequiredAnswer.No,
            },
          ]}
          rules={{
            required: {
              value: true,
              message: intl.formatMessage({
                id: "requirements.error.required",
              }),
            },
          }}
        />
      </form>
    </StepperContainer>
  );
};

export default CleaningRequired;

import { ForgotPassword as ForgotPasswordComponent } from "components/@authentication/ForgotPassword";
import LogoContainer from "components/@common/LogoContainer";
import PageWrap from "components/@common/PageWrap/PageWrap";

const ForgotPassword = () => {
  return (
    <PageWrap androidStatusBar={{ bgColor: "#241261", iconsColor: "light" }}>
      <LogoContainer>
        <ForgotPasswordComponent />
      </LogoContainer>
    </PageWrap>
  );
};

export default ForgotPassword;

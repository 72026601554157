import "./ReservationDetail.scss";

import { IonButton, IonIcon, IonSpinner } from "@ionic/react";
import { captureMessage } from "@sentry/react";
import { differenceInDays, format, parseISO } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import { isAndroid } from "utils/capacitor.utils";

import { useListing } from "queries";
import { useBlockReasons } from "queries/blocks";
import { useUpdateBlock } from "queries/reservations/useUpdateBlock";
import { CalendarMarkingType } from "types/calendar.types";
import {
  CreateReservationProviderValues,
  GuestyBlock,
  ReservationType,
} from "types/reservation.types";

import { getMarkingColors } from "components/@calendar/utils";
import ItemList from "components/@common/ItemList/ItemList";
import ItemListRow from "components/@common/ItemList/ItemListRow";
import LoadingButton from "components/@common/LoadingButton";
import ReservationEditDetailsForm from "components/@create-reservation/Details/ReservationEditDetailsForm";
import { formatDateRange } from "components/@reservations/ReservationDetail/utils";
import ReservationStatusChip from "components/@reservations/ReservationStatusChip/ReservationStatusChip";
import { ReservationStatus } from "components/@reservations/types";
import {
  formatDateMMMddyyyyHHmm,
  getReservationStatus,
} from "components/@reservations/utils";

interface Props {
  block: GuestyBlock["data"];
  listingId: string;
  reservationId: string;
}

const Block = ({ block, listingId, reservationId }: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const { data: blockReasons, isLoading: isLoadingBlockReasons } =
    useBlockReasons();

  const { data: activeListing } = useListing(listingId || "", {
    enabled: !!listingId,
  });

  const { updateBlock, isPending, error } = useUpdateBlock();

  const methods = useForm<CreateReservationProviderValues>({
    defaultValues: {
      checkInDate: "",
      checkOutDate: "",
      note: "",
      reasonId: "",
      reservationType: ReservationType.Block,
    },
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const hasInitialised = useRef(false);
  useEffect(() => {
    if (isLoadingBlockReasons || hasInitialised.current) return;
    hasInitialised.current = true;

    methods.reset({
      checkInDate: format(parseISO(block.attributes.startDate), "yyyy-MM-dd"),
      checkOutDate: format(parseISO(block.attributes.endDate), "yyyy-MM-dd"),
      note: block.attributes.note || "",
      reasonId:
        blockReasons?.find(
          (reason) => reason.attributes.description === block.attributes.note,
        )?.id || "",
      reservationType: ReservationType.Block,
    });
  }, [isEditing, block, methods, isLoadingBlockReasons, blockReasons]);

  if (isLoadingBlockReasons) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <IonSpinner style={{ width: 50, height: 50 }} />
      </div>
    );
  }

  const reservationStatus = getReservationStatus(
    block?.attributes.startDate ?? "",
    block?.attributes.endDate ?? "",
    "confirmed",
  );
  const canEdit = reservationStatus === ReservationStatus.Upcoming;

  const startDate = new Date(block.attributes.startDate);
  const endDate = new Date(block.attributes.endDate);
  const numberOfNights =
    startDate && endDate ? differenceInDays(endDate, startDate) : 0;

  const isOwner = !!block.attributes.isOwnerBlock;

  const handleUpdate = () => {
    const { checkInDate, checkOutDate, note, reasonId } = methods.getValues();

    updateBlock(
      {
        listingId,
        blockageGuestyId: reservationId,
        attributes: {
          startDate: checkInDate,
          endDate: checkOutDate,
          note: note || "",
          reasonId: reasonId || "",
        },
      },
      {
        onSuccess: () => setIsEditing(false),
        onError: (error) => {
          captureMessage("Error updating block in ReservationDetail", {
            level: "error",
            extra: { error },
          });
        },
      },
    );
  };

  return (
    <>
      <div
        className={`reservation-detail-page ${isAndroid && "margin-safe-top"}`}
      >
        <div className="detail-container">
          <div className="detail-header">
            <div className="detail-title">
              <div>
                <ReservationStatusChip
                  status="confirmed"
                  checkInDate={block.attributes.startDate}
                  checkOutDate={block.attributes.endDate}
                />
              </div>
              <p className="margin-0 nickname">
                {activeListing?.attributes.nickname}
              </p>

              <h3>
                <FormattedMessage
                  id="block_detail.owner_title"
                  values={{
                    isOwner,
                  }}
                />
              </h3>

              <div className="reservation-type">
                <IonIcon
                  className="ellipse-icon"
                  src="assets/icons/icon-ellipse.svg"
                  style={{
                    color: getMarkingColors(CalendarMarkingType.BLOCK, false)
                      .shadow,
                  }}
                />

                <p className="margin-0 bold">
                  <FormattedMessage
                    id="block_detail.type"
                    values={{
                      isOwner,
                      dateRange: formatDateRange(
                        block.attributes.startDate,
                        block.attributes.endDate,
                      ),
                    }}
                  />
                </p>
              </div>
            </div>
            <div>
              {!isEditing && canEdit && isOwner && (
                <IonButton
                  className="continue-button-stepper"
                  data-testid="edit-save-block"
                  shape="round"
                  onClick={() => setIsEditing(true)}
                >
                  <FormattedMessage id="reservation_detail.edit_reservation" />
                </IonButton>
              )}
            </div>
          </div>

          {isEditing ? (
            <FormProvider {...methods}>
              <ReservationEditDetailsForm
                isEditForm
                error={error}
                onSubmit={handleUpdate}
              />
            </FormProvider>
          ) : (
            <>
              <h6 className="margin-0">
                <FormattedMessage id="block_detail.block_title" />
              </h6>

              <div className="info-block" style={{ marginTop: 8 }}>
                <ItemList>
                  {block.attributes.startDate && (
                    <ItemListRow title="block_detail.start_date">
                      <p className="margin-0">
                        {formatDateMMMddyyyyHHmm(block.attributes.startDate)}
                      </p>
                    </ItemListRow>
                  )}

                  {block.attributes.endDate && (
                    <ItemListRow title="block_detail.end_date">
                      <p className="margin-0">
                        {formatDateMMMddyyyyHHmm(block.attributes.endDate)}
                      </p>
                    </ItemListRow>
                  )}

                  {numberOfNights && (
                    <ItemListRow title="reservation_detail.nights">
                      <p className="margin-0">{numberOfNights}</p>
                    </ItemListRow>
                  )}

                  {block.attributes.note && (
                    <ItemListRow title="block_detail.reason">
                      <p className="margin-0">{block.attributes.note}</p>
                    </ItemListRow>
                  )}
                </ItemList>
              </div>
            </>
          )}

          {isEditing && (
            <div
              style={{
                marginTop: 24,
                display: "flex",
                justifyContent: "flex-end",
                gap: 12,
                paddingBottom: 8,
              }}
            >
              <IonButton
                className="owner-button"
                data-testid="edit-block"
                shape="round"
                color="light"
                onClick={() => setIsEditing(false)}
              >
                <FormattedMessage id="button.cancel" />
              </IonButton>
              <LoadingButton
                className="owner-button"
                data-testid="update-reservation"
                form="hook-form-details"
                shape="round"
                color="primary"
                onClick={methods.handleSubmit(handleUpdate)}
                isLoading={isPending}
                disabled={isPending}
              >
                <FormattedMessage id="common.save" />
              </LoadingButton>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Block;

import PageWrap from "components/@common/PageWrap/PageWrap";
import RequirementsFailComponent from "components/@create-listing/RequirementsFail";

const RequirementsFail = () => {
  return (
    <PageWrap>
      <RequirementsFailComponent />
    </PageWrap>
  );
};

export default RequirementsFail;

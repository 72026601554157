import "./Modal.scss";

import {
  IonButton,
  IonContent,
  IonIcon,
  IonModal,
  isPlatform,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { FooterBar } from "../FooterBar";

interface Props {
  isOpen: boolean;
  children: ReactNode;
  onDidDismiss?: () => void;
  continueButton?: ReactNode;
  breakpoints?: Array<number>;
  initialBreakpoint?: number;
  small?: boolean;
}

const Modal = ({
  isOpen,
  children,
  onDidDismiss,
  continueButton,
  small,
}: Props) => {
  // NOTE: when passing breakpoints to IonModal, it becomes a sheet modal
  const modalProps = isPlatform("mobile")
    ? { initialBreakpoint: 1, breakpoints: [0, 1] }
    : {};

  return (
    <IonModal
      onDidDismiss={onDidDismiss}
      isOpen={isOpen}
      className={`modal ${small && "modal-small"}`}
      {...modalProps}
    >
      <IonContent>
        <div className="modal-container">
          <IonButton
            color="primary"
            shape="round"
            className="icon-button ion-hide-md-up"
            fill="clear"
            onClick={onDidDismiss}
          >
            <IonIcon size="large" slot="icon-only" icon={closeOutline} />
          </IonButton>
          <IonButton
            color="primary"
            shape="round"
            className="icon-button ion-hide-md-down absolute-button"
            fill="clear"
            onClick={onDidDismiss}
          >
            <IonIcon size="large" slot="icon-only" icon={closeOutline} />
          </IonButton>

          {children}
        </div>
      </IonContent>

      <FooterBar
        continueOnBottom
        hideMainToolbar
        continueButton={
          <>
            <IonButton
              className="contrast-button"
              expand="full"
              shape="round"
              onClick={onDidDismiss}
              style={{ width: continueButton ? "fit-content" : "100%" }}
            >
              <FormattedMessage id="password_hints.modal.close" />
            </IonButton>
            {continueButton}
          </>
        }
      />
    </IonModal>
  );
};

export default Modal;

import { useRedirectIfLoggedIn } from "hooks";

import { SignIn } from "components/@authentication/SignIn";
import LogoContainer from "components/@common/LogoContainer";
import PageWrap from "components/@common/PageWrap/PageWrap";

const Login = () => {
  const { isLoggedIn } = useRedirectIfLoggedIn();
  if (isLoggedIn) return null;
  return (
    <PageWrap androidStatusBar={{ bgColor: "#241261", iconsColor: "light" }}>
      <LogoContainer>
        <SignIn />
      </LogoContainer>
    </PageWrap>
  );
};

export default Login;

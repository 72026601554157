/* eslint-disable @typescript-eslint/no-non-null-assertion */
import "./ResetPasswordConfirm.scss";

import {
  InputChangeEventDetail,
  InputCustomEvent,
  IonInputPasswordToggle,
  IonText,
  useIonRouter,
} from "@ionic/react";
import { confirmSignIn } from "aws-amplify/auth";
import { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Routes } from "constants/routes.constants";
import { useResetPassword } from "queries/authentication/useResetPassword";
import { useAuthStore } from "store/auth-store";

import ButtonUnstyled from "components/@common/ButtonUnstyled";
import ErrorCard from "components/@common/ErrorCard";
import LoadingButton from "components/@common/LoadingButton";
import { Form } from "components/@form/Form";
import { FormIonInput } from "components/@form/FormIonInput";

import { getCognitoErrorMessageKey } from "../ForgotPassword/utils";
import PasswordCriteria, {
  PasswordCriteriaSuccess,
  validatePassword,
} from "../PasswordCriteria";
import PasswordHints from "../PasswordHints";
import { ResetPasswordFormFields } from "../types";

interface FormData {
  password: string;
  confirmPassword: string;
}

const ResetPasswordConfirm = () => {
  const intl = useIntl();
  const { resetSubmitMutation, isLoading, error } = useResetPassword();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { email, otp } = useAuthStore((state) => ({
    email: state.values?.email,
    otp: state.values?.otp,
  }));
  const router = useIonRouter();

  const confirmSignInParam =
    new URLSearchParams(window.location.search).get("confirm-sign-in") ===
    "true";

  const defaultValues = {
    [ResetPasswordFormFields.Password]: "",
    [ResetPasswordFormFields.ConfirmPassword]: "",
  };

  const handleInputChange = (
    event: InputCustomEvent<InputChangeEventDetail>,
    setState: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    if (event.detail.value != null) setState(event.detail.value);
  };

  const isPasswordValid = useMemo(() => {
    const results = validatePassword(password);
    return Object.values(results).every((value) => value);
  }, [password]);

  const isPasswordMatch = useMemo(() => {
    return password === confirmPassword && isPasswordValid;
  }, [password, confirmPassword, isPasswordValid]);

  const handleSubmit = async ({ password }: FormData) => {
    if (confirmSignInParam) {
      await confirmSignIn({ challengeResponse: password });
    } else {
      resetSubmitMutation(
        {
          username: email!,
          confirmationCode: otp!,
          newPassword: password,
        },
        {
          onSuccess: () => {
            router.push(Routes.ResetPasswordSuccess, "forward", "replace");
          },
        },
      );
    }
  };

  useEffect(() => {
    if (!email) {
      router.push(Routes.ForgotPassword, "back");
    } else if (!otp && !confirmSignInParam) {
      router.push(Routes.ResetPassword, "back");
    }
  }, [confirmSignInParam, email, otp, router]);

  return (
    <div className="container">
      <IonText>
        <h2>
          <FormattedMessage id="reset_password.title" />
        </h2>
      </IonText>

      <Form<FormData>
        onSubmit={handleSubmit}
        defaultValues={defaultValues}
        mode="onSubmit"
      >
        <FormIonInput
          className="input-margin"
          data-testid="password-input"
          name={ResetPasswordFormFields.Password}
          aria-label={ResetPasswordFormFields.Password}
          autocomplete="new-password"
          type="password"
          tabIndex={1}
          clearOnEdit={false}
          rules={{
            required: {
              value: true,
              message: intl.formatMessage({
                id: "login.field.password.error.required",
              }),
            },
          }}
          fill="solid"
          placeholder={intl.formatMessage({
            id: "forgot_password.password.placeholder",
          })}
          onIonInput={(e) => handleInputChange(e, setPassword)}
        >
          <IonInputPasswordToggle color="medium" slot="end" />
        </FormIonInput>

        {isPasswordValid ? (
          <PasswordCriteriaSuccess>
            <FormattedMessage id="password_criteria.success" />
          </PasswordCriteriaSuccess>
        ) : (
          <>
            <PasswordCriteria password={password} />
            <PasswordHints />
          </>
        )}

        <FormIonInput
          className="input-margin"
          data-testid="confirm-password-input"
          name={ResetPasswordFormFields.ConfirmPassword}
          aria-label={ResetPasswordFormFields.ConfirmPassword}
          autocomplete="new-password"
          type="password"
          tabIndex={2}
          enterKeyHint="enter"
          clearOnEdit={false}
          rules={{
            required: {
              value: true,
              message: intl.formatMessage({
                id: "login.field.password.error.required",
              }),
            },
            validate: {
              match: (value) =>
                value === password ||
                intl.formatMessage({ id: "reset_password.mismatch" }),
            },
          }}
          fill="solid"
          placeholder={intl.formatMessage({
            id: "forgot_password.confirm.placeholder",
          })}
          onIonInput={(e) => handleInputChange(e, setConfirmPassword)}
        >
          <IonInputPasswordToggle color="medium" slot="end" />
        </FormIonInput>

        {isPasswordMatch && (
          <PasswordCriteriaSuccess>
            <FormattedMessage id="password_criteria.match" />
          </PasswordCriteriaSuccess>
        )}

        {error && (
          <ErrorCard
            title={
              <FormattedMessage
                id={getCognitoErrorMessageKey(error.name).key}
                values={{
                  email: <b>{email}</b>,
                  ...getCognitoErrorMessageKey(error.name).values,
                }}
              />
            }
          />
        )}

        <div className="button-margin">
          <LoadingButton
            data-testid="continue-button"
            className="continue-button"
            shape="round"
            expand="full"
            type="submit"
            disabled={!isPasswordValid}
            isLoading={isLoading}
          >
            <FormattedMessage id="reset_password.button.label" />
          </LoadingButton>
        </div>
      </Form>
      {!confirmSignInParam && (
        <div className="back-container">
          <ButtonUnstyled
            data-testid="back"
            onClick={() => router.push(Routes.ResetPassword, "back")}
            id="back"
          >
            <FormattedMessage id="back.button" />
          </ButtonUnstyled>
        </div>
      )}
    </div>
  );
};

export default ResetPasswordConfirm;

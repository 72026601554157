import PageWrap from "components/@common/PageWrap/PageWrap";
import AddressComponent from "components/@create-listing/Address";

const Address = () => {
  return (
    <PageWrap>
      <AddressComponent />
    </PageWrap>
  );
};

export default Address;

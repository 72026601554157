import PageWrap from "components/@common/PageWrap/PageWrap";
import DetailsComponent from "components/@create-reservation/Details";

const Details = () => {
  return (
    <PageWrap>
      <DetailsComponent />
    </PageWrap>
  );
};

export default Details;

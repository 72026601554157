import { Routes } from "constants/routes.constants";
import { Route } from "types/route.types";

import MainCalendar from "pages/calendar/Calendar";
import CompleteListingAbout from "pages/complete-listing/About";
import CompleteListingAmenities from "pages/complete-listing/Amenities";
import CompleteListingCompliance from "pages/complete-listing/Compliance";
import CompleteListingHouseRules from "pages/complete-listing/HouseRules";
import CompleteListingLaunchpad from "pages/complete-listing/Launchpad";
import CompleteListingOverview from "pages/complete-listing/Overview";
import CompleteListingPhotos from "pages/complete-listing/Photos";
import CompleteListingSleepingArrangements from "pages/complete-listing/SleepingArrangements";
import CreateListingAddress from "pages/create-listing/Address";
import CreateListingCheckout from "pages/create-listing/Checkout";
import CreateListingConfirmPlan from "pages/create-listing/ConfirmPlan";
import CreateListingLaunchpad from "pages/create-listing/Launchpad";
import CreateListingPropertyDetails from "pages/create-listing/PropertyDetails";
import CreateListingRequirements from "pages/create-listing/Requirements";
import CreateListingRequirementsFail from "pages/create-listing/RequirementsFail";
import CreateListingSegmentation from "pages/create-listing/Segmentation";
import CreateListingSuccess from "pages/create-listing/Success";
import CreateListingUrl from "pages/create-listing/Url";
import CreateReservationBlockReason from "pages/create-reservation/BlockReason";
import CreateReservationCleaningRequired from "pages/create-reservation/CleaningRequired";
import CreateReservationDetails from "pages/create-reservation/Details";
import CreateReservationOverview from "pages/create-reservation/Overview";
import CreateReservationType from "pages/create-reservation/Type";
import MainListingTable from "pages/listings/ListingTable";
import MainListingView from "pages/listings/ListingView";
import MainMobileMenu from "pages/mobile-menu/MobileMenu";
import MainPreferences from "pages/preferences/Preferences";
import MainReservationDetail from "pages/reservations/ReservationDetail";
import MainReservations from "pages/reservations/Reservations";
import ReviewListingAvailability from "pages/review-listing/Availability";
import ReviewListingLaunchpad from "pages/review-listing/Launchpad";
import ReviewListingShowcase from "pages/review-listing/Showcase";
import ReviewListingSuccess from "pages/review-listing/Success";
import AuthCallback from "pages/sign-in/Callback";
import AuthSignInContinue from "pages/sign-in/Continue";
import AuthForgotPassword from "pages/sign-in/ForgotPassword";
import AuthLogin from "pages/sign-in/Login";
import AuthResetPasswordConfirm from "pages/sign-in/ResetPasswordConfirm";
import AuthResetPasswordOtp from "pages/sign-in/ResetPasswordOtp";
import AuthResetPasswordSuccess from "pages/sign-in/ResetPasswordSuccess";
import AuthVerifyEmail from "pages/sign-in/verifyEmail";
import AuthSignUpContinue from "pages/sign-up/Continue";
import AuthCreateAccount from "pages/sign-up/CreateAccount";
import AuthSignUpDetails from "pages/sign-up/Details";
import AuthSignUpWelcome from "pages/sign-up/Welcome";

type RouteObject = {
  path: Route;
  Component: () => JSX.Element | null;
  exact?: boolean;
};

type RouteMapping = {
  [key in Route]?: RouteObject;
};

const mainRoutes: RouteMapping = {
  [Routes.Listings]: {
    path: Routes.Listings,
    Component: MainListingTable,
    exact: true,
  },
  [Routes.ListingView]: {
    path: Routes.ListingView,
    Component: MainListingView,
  },
  [Routes.Calendar]: {
    exact: true,
    path: Routes.Calendar,
    Component: MainCalendar,
  },
  [Routes.Reservations]: {
    exact: true,
    path: Routes.Reservations,
    Component: MainReservations,
  },
  [Routes.ReservationDetails]: {
    path: Routes.ReservationDetails,
    Component: MainReservationDetail,
  },
  [Routes.MobileMenu]: {
    exact: true,
    path: Routes.MobileMenu,
    Component: MainMobileMenu,
  },
  [Routes.Preferences]: {
    exact: true,
    path: Routes.Preferences,
    Component: MainPreferences,
  },
};
const createListingRoutes: RouteMapping = {
  [Routes.CreateListingLaunchpad]: {
    path: Routes.CreateListingLaunchpad,
    Component: CreateListingLaunchpad,
  },
  [Routes.CreateListingAddress]: {
    path: Routes.CreateListingAddress,
    Component: CreateListingAddress,
  },
  [Routes.CreateListingRequirements]: {
    path: Routes.CreateListingRequirements,
    Component: CreateListingRequirements,
  },
  [Routes.CreateListingSegmentation]: {
    path: Routes.CreateListingSegmentation,
    Component: CreateListingSegmentation,
  },
  [Routes.CreateListingRequirementsFail]: {
    path: Routes.CreateListingRequirementsFail,
    Component: CreateListingRequirementsFail,
  },
  [Routes.CreateListingPropertyDetails]: {
    path: Routes.CreateListingPropertyDetails,
    Component: CreateListingPropertyDetails,
  },
  [Routes.CreateListingUrl]: {
    path: Routes.CreateListingUrl,
    Component: CreateListingUrl,
  },
  [Routes.CreateListingConfirmPlan]: {
    path: Routes.CreateListingConfirmPlan,
    Component: CreateListingConfirmPlan,
  },
  [Routes.CreateListingCheckout]: {
    path: Routes.CreateListingCheckout,
    Component: CreateListingCheckout,
  },
  [Routes.CreateListingSuccess]: {
    path: Routes.CreateListingSuccess,
    Component: CreateListingSuccess,
  },
};

const completeListingRoutes: RouteMapping = {
  [Routes.CompleteListingLaunchpad]: {
    path: Routes.CompleteListingLaunchpad,
    Component: CompleteListingLaunchpad,
  },
  [Routes.CompleteListingAbout]: {
    path: Routes.CompleteListingAbout,
    Component: CompleteListingAbout,
  },
  [Routes.CompleteListingAmenities]: {
    path: Routes.CompleteListingAmenities,
    Component: CompleteListingAmenities,
  },
  [Routes.CompleteListingSleepingArrangements]: {
    path: Routes.CompleteListingSleepingArrangements,
    Component: CompleteListingSleepingArrangements,
  },
  [Routes.CompleteListingPhotos]: {
    path: Routes.CompleteListingPhotos,
    Component: CompleteListingPhotos,
  },
  [Routes.CompleteListingHouseRules]: {
    path: Routes.CompleteListingHouseRules,
    Component: CompleteListingHouseRules,
  },
  [Routes.CompleteListingCompliance]: {
    path: Routes.CompleteListingCompliance,
    Component: CompleteListingCompliance,
  },
  [Routes.CompleteListingOverview]: {
    path: Routes.CompleteListingOverview,
    Component: CompleteListingOverview,
  },
};
const reviewListingRoutes: RouteMapping = {
  [Routes.ReviewListingLaunchpad]: {
    path: Routes.ReviewListingLaunchpad,
    Component: ReviewListingLaunchpad,
  },
  [Routes.ReviewListingShowcase]: {
    path: Routes.ReviewListingShowcase,
    Component: ReviewListingShowcase,
  },
  [Routes.ReviewListingAvailability]: {
    path: Routes.ReviewListingAvailability,
    Component: ReviewListingAvailability,
  },
  [Routes.ReviewListingSuccess]: {
    path: Routes.ReviewListingSuccess,
    Component: ReviewListingSuccess,
  },
};
const createReservationRoutes: RouteMapping = {
  [Routes.CreateReservationType]: {
    path: Routes.CreateReservationType,
    Component: CreateReservationType,
  },
  [Routes.CreateReservationDetails]: {
    path: Routes.CreateReservationDetails,
    Component: CreateReservationDetails,
  },
  [Routes.CreateReservationCleaningRequired]: {
    path: Routes.CreateReservationCleaningRequired,
    Component: CreateReservationCleaningRequired,
  },
  [Routes.CreateReservationBlockReason]: {
    path: Routes.CreateReservationBlockReason,
    Component: CreateReservationBlockReason,
  },
  [Routes.CreateReservationOverview]: {
    path: Routes.CreateReservationOverview,
    Component: CreateReservationOverview,
  },
};

export const tabRoutes = {
  ...mainRoutes,
  ...createListingRoutes,
  ...completeListingRoutes,
  ...reviewListingRoutes,
  ...createReservationRoutes,
};

export const authRoutes: RouteMapping = {
  [Routes.Callback]: {
    path: Routes.Callback,
    Component: AuthCallback,
  },
  [Routes.SignInContinue]: {
    path: Routes.SignInContinue,
    Component: AuthSignInContinue,
  },
  [Routes.Login]: {
    path: Routes.Login,
    Component: AuthLogin,
  },
  [Routes.ForgotPassword]: {
    path: Routes.ForgotPassword,
    Component: AuthForgotPassword,
  },
  [Routes.ResetPassword]: {
    path: Routes.ResetPassword,
    Component: AuthResetPasswordOtp,
  },
  [Routes.ResetPasswordConfirm]: {
    path: Routes.ResetPasswordConfirm,
    Component: AuthResetPasswordConfirm,
  },
  [Routes.ResetPasswordSuccess]: {
    path: Routes.ResetPasswordSuccess,
    Component: AuthResetPasswordSuccess,
  },
  [Routes.VerifyEmail]: {
    path: Routes.VerifyEmail,
    Component: AuthVerifyEmail,
  },
  [Routes.SignUpContinue]: {
    path: Routes.SignUpContinue,
    Component: AuthSignUpContinue,
  },
  [Routes.CreateAccount]: {
    path: Routes.CreateAccount,
    Component: AuthCreateAccount,
  },
  [Routes.SignUpDetails]: {
    path: Routes.SignUpDetails,
    Component: AuthSignUpDetails,
  },
  [Routes.SignUpWelcome]: {
    path: Routes.SignUpWelcome,
    Component: AuthSignUpWelcome,
  },
};

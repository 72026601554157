import PageWrap from "components/@common/PageWrap/PageWrap";
import ShowcaseComponent from "components/@review-listing/Showcase";

const Showcase = () => {
  return (
    <PageWrap>
      <ShowcaseComponent />
    </PageWrap>
  );
};

export default Showcase;

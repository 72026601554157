import { ResetPasswordConfirm as ResetPasswordConfirmComponent } from "components/@authentication/ResetPasswordConfirm";
import LogoContainer from "components/@common/LogoContainer";
import PageWrap from "components/@common/PageWrap/PageWrap";

const ResetPasswordConfirm = () => {
  return (
    <PageWrap androidStatusBar={{ bgColor: "#241261", iconsColor: "light" }}>
      <LogoContainer>
        <ResetPasswordConfirmComponent />
      </LogoContainer>
    </PageWrap>
  );
};

export default ResetPasswordConfirm;

import "./ReservationDetailOwner.scss";

import { FormattedMessage } from "react-intl";

import { cleaningRequiredKeys } from "constants/reservation.constants";
import { CleaningRequiredAnswer } from "types/reservation.types";

import ItemList from "components/@common/ItemList/ItemList";
import ItemListRow from "components/@common/ItemList/ItemListRow";

import { formatDateMMMddyyyyHHmm } from "../utils";

interface Props {
  checkInDate?: string;
  checkOutDate?: string;
  numberOfNights?: number;
  mainGuestName?: string;
  numberOfGuests?: number;
  cleaningRequired?: boolean;
}

const ReservationDetailOwner = ({
  checkInDate,
  checkOutDate,
  numberOfNights,
  mainGuestName,
  numberOfGuests,
  cleaningRequired,
}: Props) => {
  return (
    <div className="reservation-detail-owner-form">
      {((mainGuestName && numberOfGuests) ||
        checkInDate ||
        checkOutDate ||
        numberOfNights) && (
        <>
          <h6 className="margin-0">
            <FormattedMessage id="reservation_detail.reservation_title" />
          </h6>

          <div className="info-block">
            <ItemList>
              {checkInDate && (
                <ItemListRow title="reservation_detail.check_in">
                  <p className="margin-0">
                    {formatDateMMMddyyyyHHmm(checkInDate)}
                  </p>
                </ItemListRow>
              )}
              {checkOutDate && (
                <ItemListRow title="reservation_detail.check_out">
                  <p className="margin-0">
                    {formatDateMMMddyyyyHHmm(checkOutDate)}
                  </p>
                </ItemListRow>
              )}
              {numberOfNights && (
                <ItemListRow title="reservation_detail.nights">
                  <p className="margin-0">{numberOfNights}</p>
                </ItemListRow>
              )}
              {mainGuestName && numberOfGuests && (
                <ItemListRow>
                  <div className="guests-item">
                    <p className="margin-0 bold">
                      <FormattedMessage
                        id="reservation_detail.guests"
                        values={{ count: numberOfGuests }}
                      />
                    </p>
                    <p className="margin-0">
                      <FormattedMessage
                        id="reservation_detail.guests_names"
                        values={{ count: numberOfGuests - 1, mainGuestName }}
                      />
                    </p>
                  </div>
                </ItemListRow>
              )}

              <ItemListRow title="services.cleaning.label">
                <p className="margin-0">
                  <FormattedMessage
                    id={
                      cleaningRequiredKeys[
                        cleaningRequired
                          ? CleaningRequiredAnswer.Yes
                          : CleaningRequiredAnswer.No
                      ]
                    }
                  />
                </p>
              </ItemListRow>
            </ItemList>
          </div>
        </>
      )}
    </div>
  );
};

export default ReservationDetailOwner;

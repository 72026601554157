import "./Success.scss";

import {
  IonButton,
  IonContent,
  IonIcon,
  IonText,
  useIonRouter,
} from "@ionic/react";
import Spline from "@splinetool/react-spline";
import { m, Variants } from "framer-motion";
import { cubicBezier } from "framer-motion";
import {
  cameraOutline,
  documentLockOutline,
  receiptOutline,
  wifiOutline,
} from "ionicons/icons";
import { useWatch } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { generatePath } from "react-router";

import { Routes } from "constants/routes.constants";

const variants: Variants = {
  initial: {
    opacity: 0,
    y: "100%",
  },
  animate: {
    opacity: 1,
    y: "0%",
    transition: {
      delay: 2.5,
      duration: 1.2,
      ease: cubicBezier(0.33, 1, 0.68, 1),
    },
  },
  exit: {
    opacity: 0,
    y: "-100%",
    transition: {
      duration: 2,
      ease: cubicBezier(0.37, 0, 0.63, 1),
    },
  },
};

const Success = () => {
  const router = useIonRouter();
  const listingId = useWatch({ name: "listingId" });

  /* @TODO
    - Make sure spline is downloaded before getting to this step (maybe preload on previous step or in CreateListingProvider)
    - Use correct spline scene (checked with Tom Wuyts, needs to be delivered by Dima)
    - Check how large Spline dependency & scene are, consider lazy loading
    - Maybe use video instead of Spline to optimize performance
  */

  return (
    <IonContent style={{ "--background": "var(--ion-color-primary-tint)" }}>
      <div className="success-page">
        <Spline
          scene="https://prod.spline.design/d1vimelhwmbkdVJq/scene.splinecode"
          style={{ position: "fixed", inset: 0, pointerEvents: "none" }}
        />
        <m.div
          className="success-container"
          variants={variants}
          initial="initial"
          animate="animate"
        >
          <IonText>
            <h3 className="success-header">
              <FormattedMessage id="create_listing_success.title" />
            </h3>
          </IonText>

          <p className="bold">
            <FormattedMessage id="create_listing_success.appreciation" />
          </p>

          <p>
            <FormattedMessage id="create_listing_success.description" />
          </p>

          <div className="create-listing-success-card">
            <p className="bold margin-0">
              <FormattedMessage id="create_listing_success.card.title" />
            </p>
            <div className="success-card-list-item">
              <IonIcon icon={cameraOutline} />
              <p className="margin-0">
                <FormattedMessage id="create_listing_success.card.list_item_1" />
              </p>
            </div>

            <div className="success-card-list-item">
              <IonIcon icon={wifiOutline} />
              <p className="margin-0">
                <FormattedMessage id="create_listing_success.card.list_item_2" />
              </p>
            </div>

            <div className="success-card-list-item">
              <IonIcon icon={receiptOutline} />
              <p className="margin-0">
                <FormattedMessage id="create_listing_success.card.list_item_3" />
              </p>
            </div>

            <div className="success-card-list-item">
              <IonIcon icon={documentLockOutline} />
              <p className="margin-0">
                <FormattedMessage id="create_listing_success.card.list_item_4" />
              </p>
            </div>
          </div>
          <div className="margin-16">
            <IonButton
              data-testid="success-complete-button"
              onClick={() =>
                router.push(
                  generatePath(Routes.CompleteListingLaunchpad, { listingId }),
                  "back",
                  "replace",
                )
              }
              shape="round"
              className="secondary-button width-100"
            >
              <FormattedMessage id="create_listing_success.button.label" />
            </IonButton>
          </div>

          <div className="link-container">
            <button
              className="dashboard-link"
              data-testid="success-dashboard-link"
              onClick={() => router.push(Routes.Listings, "back", "replace")}
            >
              <FormattedMessage id="create_listing_success.dashboard.link" />
            </button>
          </div>
        </m.div>
      </div>
    </IonContent>
  );
};

export default Success;

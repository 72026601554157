import "./LanguageSelect.scss";

import { IonSelect, IonSelectOption } from "@ionic/react";
import { chevronDown, chevronUp } from "ionicons/icons";

import { languageMap } from "services/i18n/config";
import { locales } from "services/i18n/index";
import { useIntlStore } from "store";

const LanguageSelect = () => {
  const { locale: currentLocale, setLocale } = useIntlStore((state) => state);

  const handleLocaleChange = (event: CustomEvent) => {
    const newLocale = event.detail.value;
    setLocale(newLocale);
  };

  return (
    <IonSelect
      data-testid="language-select"
      id="language-select"
      aria-label="Language Select"
      interface="popover"
      className="ion-select-language"
      placeholder={languageMap[currentLocale]}
      toggleIcon={chevronDown}
      expandedIcon={chevronUp}
      onIonChange={handleLocaleChange}
      style={{ "--background": "white" }}
    >
      {locales
        .filter((locale) => locale !== currentLocale)
        .map((locale) => (
          <IonSelectOption
            data-testid={`language-option-${locale}`}
            className="language-select-option"
            key={locale}
            value={locale}
          >
            {languageMap[locale]}
          </IonSelectOption>
        ))}
    </IonSelect>
  );
};

export default LanguageSelect;

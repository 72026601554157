import { IonContent } from "@ionic/react";
import { useRef } from "react";

import { isAndroid, isIos } from "utils/capacitor.utils";

import PageWrap from "components/@common/PageWrap/PageWrap";
import { PullToRefresh, RefreshHandle } from "components/@common/PullToRefresh";
import ReservationsComponent from "components/@reservations/Reservations/Reservations";

const Reservations = () => {
  const reservationsRef = useRef<RefreshHandle>(null);
  return (
    <PageWrap safeArea={{ show: true }}>
      <IonContent className="reservations-page-ion-content">
        <PullToRefresh dataRef={reservationsRef} />
        <div
          className={`ion-tab-page reservations-tab-page ${isAndroid ? "android-top-padding" : !isIos && "web-top-padding"}`}
        >
          <ReservationsComponent ref={reservationsRef} />
        </div>
      </IonContent>
    </PageWrap>
  );
};

export default Reservations;

import PageWrap from "components/@common/PageWrap/PageWrap";
import ReservationDetailComponent from "components/@reservations/ReservationDetail/ReservationDetail/ReservationDetail";

const ReservationDetail = () => {
  return (
    <PageWrap safeArea={{ show: true, showAndroid: true }}>
      <ReservationDetailComponent />
    </PageWrap>
  );
};

export default ReservationDetail;

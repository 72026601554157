import "./FooterBar.scss";

import { IonFooter, IonToolbar } from "@ionic/react";
import { CSSProperties, memo, ReactNode } from "react";

type Props = {
  topButton?: ReactNode;
  backButton?: ReactNode;
  skipButton?: ReactNode;
  continueButton?: ReactNode;
  continueOnBottom?: boolean;
  hideMainToolbar?: boolean;
  className?: string;
  style?: CSSProperties;
  isHidden?: boolean;
};

const FooterBar = ({
  topButton,
  backButton,
  continueButton,
  skipButton,
  continueOnBottom,
  hideMainToolbar,
  className,
  style,
  isHidden,
}: Props) => {
  if (!topButton && !backButton && !skipButton && !continueButton) return null;
  return (
    <IonFooter
      className={`ion-no-border footer-bar ${className}`}
      style={{
        display:
          !topButton && !backButton && !skipButton && !continueButton
            ? "none"
            : "flex",
      }}
    >
      <IonToolbar
        className="ion-no-padding"
        style={{
          opacity: isHidden ? 0 : 1,
          transition: "opacity 0.3s",
          ...style,
        }}
      >
        {topButton && (
          <div className="ion-no-padding toolbar-top">{topButton}</div>
        )}
        {!hideMainToolbar && (
          <div className="toolbar-main">
            <div
              className="button-container start"
              style={{
                display: backButton ? "" : "none",
              }}
            >
              {backButton}
            </div>
            <div
              className={`button-container ${backButton && "end"}`}
              style={{
                display: skipButton || continueButton ? "" : "none",
              }}
            >
              {skipButton}
              {!continueOnBottom && continueButton}
            </div>
          </div>
        )}
        {continueOnBottom && (
          <div
            className={`button-container-bottom ${!hideMainToolbar && "top-margin"}`}
          >
            {continueButton}
          </div>
        )}
      </IonToolbar>
    </IonFooter>
  );
};

export default memo(FooterBar);

import PageWrap from "components/@common/PageWrap/PageWrap";
import BlockReasonComponent from "components/@create-reservation/BlockReason";

const BlockReason = () => {
  return (
    <PageWrap>
      <BlockReasonComponent />
    </PageWrap>
  );
};

export default BlockReason;

import { CognitoErrorCode } from "services/auth/types";

export const getCognitoErrorMessageKey = (errorName?: string): I18nKey => {
  if (errorName && Object.keys(CognitoErrorCode).includes(errorName)) {
    return `cognito.error.${errorName}` as I18nKey;
  }

  return "common.error_unknown";
};

import { fetchAuthSession, signIn, SignInInput } from "aws-amplify/auth";

import { captureMessage } from "utils/sentry.utils";
/**
 * Checks if a user exists in the AWS Cognito user pool by attempting to sign in with a given email.
 * This function uses a hardcoded password ("123") to attempt the sign-in and interprets specific
 * AWS Cognito errors to determine the existence of the user.
 *
 * @param email - The email address of the user to check.
 * @returns A promise that resolves to `true` if the user exists (based on the
 * NotAuthorizedException error), and `false` if the user does not exist or in cases of other errors.
 */
export const userExists = async (email: string) => {
  const signInInput: SignInInput = {
    username: email,
    password: "123",
  };
  return signIn(signInInput)
    .then(() => {
      return false;
    })
    .catch((error) => {
      const name = error.name;
      switch (name) {
        case CognitoErrorCode.UserNotFoundException:
          return false;
        case CognitoErrorCode.NotAuthorizedException:
          return true;
        case "PasswordResetRequiredException":
          return false;
        case CognitoErrorCode.UserNotConfirmedException:
          return false;
        default:
          return false;
      }
    });
};

export const getIdToken = async () => {
  try {
    const currentSession = await fetchAuthSession({ forceRefresh: true });
    const token = currentSession.tokens?.idToken;
    return token;
  } catch (error) {
    captureMessage("Error fetching idToken", {
      level: "error",
      extra: { error },
    });
    return null;
  }
};

import { matchPath } from "react-router";

import { Routes } from "constants/routes.constants";
import { useUser } from "queries";
import { ListingResponse } from "types/listing.types";

export const getListingId = () => {
  try {
    const match = matchPath<{ listingId: string }>(location.pathname, {
      path: `${Routes.ListingView}/*`,
      exact: false,
      strict: false,
    });

    return match?.params.listingId || "";
  } catch (error) {
    return "";
  }
};

export const getListingAddress = (
  listing: ListingResponse["data"]["attributes"],
) => {
  return `${listing.address.street ? listing.address.street + ", " : ""}${listing.address.zipcode} ${listing.address.city}, ${listing.address.country}`;
};

export const useOwnerType = (listing: ListingResponse["data"] | undefined) => {
  const { data: user } = useUser();

  return listing?.relationships.owners.data.find(
    (owner) => owner.id === user?.data.id,
  )?.attributes.ownerType;
};

import { App as CapacitorApp } from "@capacitor/app";
import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";

export const useIsAppLoaded = (timeout: number = 0) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const handleCheckState = async () => {
      try {
        const state = await CapacitorApp.getState();

        if (state.isActive) {
          setIsLoaded(true);
        } else {
          // If the app is not active, check again in 250ms
          setTimeout(handleCheckState, 250);
        }
      } catch (error) {
        Sentry.captureMessage("Error fetching app state", { extra: { error } });
        // If we error, just set the loaded state..
        setIsLoaded(true);
      }
    };

    const timeoutId = setTimeout(() => {
      handleCheckState();
    }, timeout);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [timeout]);

  return { isLoaded };
};

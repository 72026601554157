import { IonContent } from "@ionic/react";
import { useRef } from "react";

import { isAndroid, isIos } from "utils/capacitor.utils";

import CalendarContainer from "components/@calendar/CalendarContainer/CalendarContainer";
import PageWrap from "components/@common/PageWrap/PageWrap";
import { PullToRefresh, RefreshHandle } from "components/@common/PullToRefresh";

const Calendar = () => {
  const calendarRef = useRef<RefreshHandle>(null);
  return (
    <PageWrap safeArea={{ show: true }}>
      <IonContent>
        <PullToRefresh dataRef={calendarRef} />
        <div
          className={`ion-tab-page calendar-page ${isAndroid ? "android-top-padding" : !isIos && "web-top-padding"}`}
        >
          <CalendarContainer ref={calendarRef} />
        </div>
      </IonContent>
    </PageWrap>
  );
};

export default Calendar;

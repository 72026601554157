import { useIonRouter } from "@ionic/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { signOut } from "aws-amplify/auth";

import { Routes } from "constants/routes.constants";
import { useUIStore } from "store/ui-store";

export const useSignOut = () => {
  const queryClient = useQueryClient();
  const router = useIonRouter();
  const setIsLoading = useUIStore((state) => state.setIsLoading);
  const { mutate, isPending, error } = useMutation({
    mutationFn: () => {
      setIsLoading(true);
      return signOut();
    },
    onSuccess: () => {
      queryClient.clear();
      router.push(Routes.SignInContinue, "back", "replace");
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });
  return {
    signOut: mutate,
    isLoading: isPending,
    error,
  };
};

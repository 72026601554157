import PageWrap from "components/@common/PageWrap/PageWrap";
import PropertyDetailsComponent from "components/@create-listing/PropertyDetails";

const PropertyDetails = () => {
  return (
    <PageWrap>
      <PropertyDetailsComponent />
    </PageWrap>
  );
};

export default PropertyDetails;

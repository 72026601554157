import { IonSplitPane } from "@ionic/react";
import { useState } from "react";
import { Route } from "react-router";

import { isNative } from "utils/capacitor.utils";

import { Routes } from "constants/routes.constants";

import ListingDetails from "components/@listing-detail/ListingDetails/ListingDetails";
import ListingDetailWrap from "components/@listing-detail/ListingDetailWrap/ListingDetailWrap";
import ListingOverviewHeader from "components/@listing-detail/ListingOverviewHeader/ListingOverviewHeader";
import ListingSidebar from "components/@listing-detail/ListingSidebar/ListingSidebar";

import ListingCalendar from "./ListingCalendar";
import ListingMarketing from "./ListingMarketing";
import ListingOverview from "./ListingOverview";
import ListingPlatforms from "./ListingPlatforms";
import ListingSettings from "./ListingSettings";

const ListingView = () => {
  const [scrollTop, setScrollTop] = useState(0);

  return (
    <>
      <ListingOverviewHeader isScrolled={scrollTop !== 0} />

      <IonSplitPane
        id="splitview-listing"
        when="(min-width: 769px)"
        contentId="listing-detail"
        style={{ zIndex: isNative ? 100 : undefined }}
      >
        <ListingSidebar />

        <ListingDetailWrap
          onScroll={(scrollTop: number) => setScrollTop(scrollTop)}
        >
          <Route path={Routes.ListingOverview} component={ListingOverview} />
          <Route path={Routes.ListingCalendar} component={ListingCalendar} />
          <Route path={Routes.ListingDetails} component={ListingDetails} />
          <Route path={Routes.ListingMarketing} component={ListingMarketing} />
          <Route path={Routes.ListingPlatforms} component={ListingPlatforms} />
          <Route path={Routes.ListingSettings} component={ListingSettings} />
        </ListingDetailWrap>
      </IonSplitPane>
    </>
  );
};

export default ListingView;

import "./SignInContinue.scss";

import { IonButton, IonIcon, IonText } from "@ionic/react";
import { signInWithRedirect, signOut } from "aws-amplify/auth";
import { logoApple, logoFacebook } from "ionicons/icons";
import { FormattedMessage } from "react-intl";

import { LoginProvider } from "services/auth/types";
import { useAuthStore } from "store/auth-store";

import Divider from "components/@common/Divider";

const googleSrc =
  "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxZW0iIGhlaWdodD0iMWVtIiB2aWV3Qm94PSIwIDAgNDggNDgiPjxwYXRoIGZpbGw9IiNmZmMxMDciIGQ9Ik00My42MTEgMjAuMDgzSDQyVjIwSDI0djhoMTEuMzAzYy0xLjY0OSA0LjY1Ny02LjA4IDgtMTEuMzAzIDhjLTYuNjI3IDAtMTItNS4zNzMtMTItMTJzNS4zNzMtMTIgMTItMTJjMy4wNTkgMCA1Ljg0MiAxLjE1NCA3Ljk2MSAzLjAzOWw1LjY1Ny01LjY1N0MzNC4wNDYgNi4wNTMgMjkuMjY4IDQgMjQgNEMxMi45NTUgNCA0IDEyLjk1NSA0IDI0czguOTU1IDIwIDIwIDIwczIwLTguOTU1IDIwLTIwYzAtMS4zNDEtLjEzOC0yLjY1LS4zODktMy45MTciLz48cGF0aCBmaWxsPSIjZmYzZDAwIiBkPSJtNi4zMDYgMTQuNjkxbDYuNTcxIDQuODE5QzE0LjY1NSAxNS4xMDggMTguOTYxIDEyIDI0IDEyYzMuMDU5IDAgNS44NDIgMS4xNTQgNy45NjEgMy4wMzlsNS42NTctNS42NTdDMzQuMDQ2IDYuMDUzIDI5LjI2OCA0IDI0IDRDMTYuMzE4IDQgOS42NTYgOC4zMzcgNi4zMDYgMTQuNjkxIi8+PHBhdGggZmlsbD0iIzRjYWY1MCIgZD0iTTI0IDQ0YzUuMTY2IDAgOS44Ni0xLjk3NyAxMy40MDktNS4xOTJsLTYuMTktNS4yMzhBMTEuOTEgMTEuOTEgMCAwIDEgMjQgMzZjLTUuMjAyIDAtOS42MTktMy4zMTctMTEuMjgzLTcuOTQ2bC02LjUyMiA1LjAyNUM5LjUwNSAzOS41NTYgMTYuMjI3IDQ0IDI0IDQ0Ii8+PHBhdGggZmlsbD0iIzE5NzZkMiIgZD0iTTQzLjYxMSAyMC4wODNINDJWMjBIMjR2OGgxMS4zMDNhMTIuMDQgMTIuMDQgMCAwIDEtNC4wODcgNS41NzFsLjAwMy0uMDAybDYuMTkgNS4yMzhDMzYuOTcxIDM5LjIwNSA0NCAzNCA0NCAyNGMwLTEuMzQxLS4xMzgtMi42NS0uMzg5LTMuOTE3Ii8+PC9zdmc+";

interface Props {
  loginProvider: LoginProvider;
}

const iconMap: Record<LoginProvider, { icon?: string; src?: string }> = {
  [LoginProvider.Facebook]: { icon: logoFacebook },
  [LoginProvider.Apple]: { icon: logoApple },
  [LoginProvider.Google]: { src: googleSrc },
};

const SignInProviderRemembered = ({ loginProvider }: Props) => {
  const { setValues } = useAuthStore((state) => state.actions);

  return (
    <div className="container">
      <p className="description">
        <IonText>
          <FormattedMessage
            id="sign_in_provider_remembered.description"
            values={{ loginProvider }}
          />
        </IonText>
      </p>

      <IonButton
        data-testid="provider-remembered-login"
        className="provider-remembered-button"
        shape="round"
        onClick={async () =>
          signOut().then(async () => {
            await signInWithRedirect({
              provider: loginProvider,
              customState: JSON.stringify({
                provider: loginProvider,
              }),
            });
          })
        }
      >
        <IonIcon
          className={`${loginProvider}-icon`}
          {...iconMap[loginProvider]}
        />
        <FormattedMessage
          id="sign_in_provider_remembered.button.label"
          values={{ loginProvider }}
        />
      </IonButton>
      <Divider />

      <IonButton
        data-testid="provider-remembered-continue"
        className="provider-remembered-button"
        shape="round"
        onClick={() => setValues({ loginProvider: undefined })}
      >
        <FormattedMessage id="sign_in_provider_remembered.continue.button.label" />
      </IonButton>
    </div>
  );
};

export default SignInProviderRemembered;

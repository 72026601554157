import "../../components/@listing-table/ListingTable/ListingTable.scss";

import { IonContent, useIonRouter } from "@ionic/react";
import { useEffect, useRef, useState } from "react";

import { isAndroid, isNative } from "utils/capacitor.utils";

import {
  APP_BG_COLOR,
  EMAIL_BANNER_BG_COLOR,
} from "constants/colors.constants";
import { Routes } from "constants/routes.constants";
import useIsEmailVerified from "hooks/useIsEmailVerified";

import PageWrap from "components/@common/PageWrap/PageWrap";
import { PullToRefresh, RefreshHandle } from "components/@common/PullToRefresh";
import VerifyEmailBanner from "components/@common/VerifyEmailBanner";
import ListingTableComponent from "components/@listing-table/ListingTable/ListingTable";

const ListingTable = () => {
  const listingTableRef = useRef<RefreshHandle>(null);
  const emailVerified = useIsEmailVerified();
  const router = useIonRouter();

  const [statusBarColor, setStatusBarColor] = useState(APP_BG_COLOR);
  useEffect(() => {
    if (router.routeInfo.pathname === Routes.Listings && !emailVerified) {
      setStatusBarColor(EMAIL_BANNER_BG_COLOR);
    } else {
      setStatusBarColor(APP_BG_COLOR);
    }
  }, [emailVerified, router.routeInfo.pathname]);

  return (
    <PageWrap
      androidStatusBar={{ bgColor: statusBarColor }}
      safeArea={{
        show: true,
        showAndroid: true,
        bgColor: statusBarColor,
        children: (
          <VerifyEmailBanner type="mobile" emailVerified={emailVerified} />
        ),
      }}
    >
      <IonContent className="listing-table-ion-content">
        <PullToRefresh dataRef={listingTableRef} />
        <div
          className={`ion-tab-page listing-table-tab-page ${!isNative && "web-top-padding"} ${!emailVerified ? "banner-margin" : isAndroid ? "android-top-margin" : ""}`}
        >
          <ListingTableComponent
            ref={listingTableRef}
            emailVerified={emailVerified}
          />
        </div>
      </IonContent>
    </PageWrap>
  );
};

export default ListingTable;

import { StatusBar, Style } from "@capacitor/status-bar";
import { IonPage } from "@ionic/react";
import { ReactNode, useEffect } from "react";

import { isAndroid } from "utils/capacitor.utils";

import { APP_BG_COLOR } from "constants/colors.constants";
import { useUIStore } from "store/ui-store";

import { IOSSafeArea } from "../IOSSafeArea";

type Props = {
  id?: string;
  className?: string;
  children: ReactNode;
  androidStatusBar?: {
    iconsColor?: "dark" | "light";
    bgColor?: string;
  };
  androidNavbarColor?: string;
  safeArea?: {
    show?: boolean;
    showAndroid?: boolean;
    bgColor?: string;
    children?: ReactNode;
  };
};

const PageWrap = ({
  id,
  className,
  children,
  androidStatusBar = {
    bgColor: APP_BG_COLOR,
    iconsColor: "dark",
  },
  androidNavbarColor = APP_BG_COLOR,
  safeArea = {
    show: false,
    bgColor: APP_BG_COLOR,
    children: null,
    showAndroid: false,
  },
}: Props) => {
  const splashHasEnded = useUIStore((state) => state.splashHasEnded);

  useEffect(() => {
    if (isAndroid && splashHasEnded) {
      StatusBar.setBackgroundColor({
        color: androidStatusBar.bgColor || APP_BG_COLOR,
      });
      StatusBar.setStyle({
        style:
          androidStatusBar.iconsColor === "light" ? Style.Dark : Style.Light,
      });
    }
  }, [
    androidNavbarColor,
    androidStatusBar.bgColor,
    androidStatusBar.iconsColor,
    splashHasEnded,
  ]);

  return (
    <IonPage id={id} className={className}>
      {safeArea.show && (
        <IOSSafeArea
          showAndroid={safeArea.showAndroid}
          bgColor={safeArea.bgColor}
        >
          {safeArea.children}
        </IOSSafeArea>
      )}
      {children}
    </IonPage>
  );
};

export default PageWrap;

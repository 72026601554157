import { IonContent } from "@ionic/react";

import { SignUpWelcome } from "components/@authentication/SignUpWelcome";
import PageWrap from "components/@common/PageWrap/PageWrap";

const Welcome = () => {
  return (
    <PageWrap androidStatusBar={{ bgColor: "#241261", iconsColor: "light" }}>
      <IonContent fullscreen scrollY={false} scrollX={false}>
        <SignUpWelcome />
      </IonContent>
    </PageWrap>
  );
};

export default Welcome;

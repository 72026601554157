import { PublicReview } from "./reservation.types";

export type CalendarReservation = {
  id: string;
  guestFullName: string;
  guestsCount: number;
  checkInDate: string;
  checkOutDate: string;
  plannedArrival: string;
  plannedDeparture: string;
  otaLogoUrl: string;
  otaName?: string;
  hostPayout: number;
  type: null;
  ownerFullName: string | null;
  confirmationCode: string;
  publicReview: PublicReview;
  isVerified: boolean;
};

export type CalendarOwnerReservation = Pick<
  CalendarReservation,
  "checkInDate" | "checkOutDate"
> & {
  guestyId: string;
  cleaningRequired: boolean;
  numberOfGuests: number;
  numberOfNights: number;
  isVerified: boolean;
  ownerFullName: string;
};

export type CalendarBlock = {
  guestyId: string;
  startDate: string;
  endDate: string;
  type: string;
  note?: string;
  guestFullName: null;
  ownerFullName: null;
  isOwnerBlock: boolean;
};

export type CalendarListing = {
  id: string;
  type: "calendar";
  attributes: {
    reservations: Array<CalendarReservation>;
    ownerReservations: Array<CalendarOwnerReservation>;
    blocks: Array<CalendarBlock>;
  };
  meta: {
    reservationsCount: number;
    ownerReservationsCount: number;
    blocksCount: number;
  };
};

export enum CalendarMarkingType {
  RESERVATION = "reservation",
  OWNER_RESERVATION = "ownerReservation",
  BLOCK = "block",
}

export enum DaySection {
  START = "start",
  MIDDLE = "middle",
  END = "end",
}

export interface CalendarMarkingProps {
  id: string;
  listingId?: string;
  type: CalendarMarkingType;
  daySection: DaySection;
  title: string;
  description: string;
  blockSpan: number;
  displayTitle?: boolean;
  isInPast: boolean;
  weekDayIndex: number;
  onRemoveReservation?: () => void;
}

export type DayProps = {
  date: Date;
  calendarMarkings: Array<CalendarMarkingProps>;
  selectedDate: Date;
  onRemoveReservation?: () => void;
};

export type WeekRowType = Array<DayProps>;

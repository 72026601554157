import { GuestyReservation } from "types/reservation.types";

import { FooterBar } from "components/@common/FooterBar";

import {
  OwnerCancelEditButton,
  OwnerCancelReservationButton,
  OwnerEditButton,
  OwnerUpdateReservationButton,
  RequestCancellationButton,
} from "./ActionButtons";

type Props = {
  useDesktopButtons?: boolean;
  reservation?: GuestyReservation["data"];
  canEdit?: boolean;
  isEditing?: boolean;
  isOwner?: boolean;
  onRequestCancel: () => void;
  onEdit: () => void;
  onCancelEdit: () => void;
  onDelete: () => void;
  onUpdate: () => void;
  isLoadingDelete: boolean;
  isLoadingUpdate: boolean;
  isHidden?: boolean;
};

const ReservationFooter = ({
  useDesktopButtons,
  reservation,
  canEdit,
  isEditing,
  isOwner,
  onRequestCancel,
  onEdit,
  onCancelEdit,
  onDelete,
  onUpdate,
  isLoadingDelete,
  isLoadingUpdate,
  isHidden,
}: Props) => {
  const isCompletedOrOngoing = reservation?.attributes.checkInDate
    ? new Date(reservation?.attributes.checkInDate) < new Date()
    : false;

  return (
    <FooterBar
      className={
        useDesktopButtons || isCompletedOrOngoing
          ? "ion-hide"
          : "ion-hide-md-up"
      }
      style={{ display: isHidden ? "none" : "flex" }}
      backButton={
        reservation && canEdit ? (
          !isOwner ? (
            <RequestCancellationButton onClick={onRequestCancel} />
          ) : isEditing ? (
            <OwnerCancelEditButton onClick={onCancelEdit} />
          ) : (
            <OwnerCancelReservationButton
              onClick={onDelete}
              isLoading={isLoadingDelete}
            />
          )
        ) : undefined
      }
      continueButton={
        reservation && canEdit && isOwner ? (
          isEditing ? (
            <OwnerUpdateReservationButton
              onClick={onUpdate}
              isLoading={isLoadingUpdate}
            />
          ) : (
            <OwnerEditButton onClick={onEdit} />
          )
        ) : undefined
      }
    />
  );
};

export default ReservationFooter;

import { useIonRouter } from "@ionic/react";
import { ReactNode, useEffect, useMemo } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { matchPath, useLocation } from "react-router";

import { getCountryCode } from "utils/country.utils";

import { createListingDefaultValues } from "constants/listing.constants";
import { Routes } from "constants/routes.constants";
import { useToastNotification } from "hooks";
import { useListing } from "queries";
import {
  CreateListingProviderValues,
  RequirementsAnswer,
} from "types/listing.types";

import LoadingIndicator from "components/@common/LoadingIndicator";

interface Props {
  children: ReactNode;
}

const CreateListingProvider = ({ children }: Props) => {
  const location = useLocation();
  const router = useIonRouter();

  const showErrorToast = useToastNotification({
    text: "listing_overview.error.short",
  });

  const match = matchPath<{ listingId: string }>(location.pathname, {
    path: `${Routes.CreateListing}/:listingId/*`,
    exact: false,
    strict: false,
  });

  const listingId = useMemo(
    () => match?.params.listingId,
    [match?.params.listingId],
  );
  const { data, isLoading, isError } = useListing(listingId ?? "", {
    enabled: !!listingId,
  });

  const defaultValues: CreateListingProviderValues = {
    ...createListingDefaultValues,
    listingId: listingId ?? "",
  };

  const { reset, ...methods } = useForm<CreateListingProviderValues>({
    defaultValues,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const currentRequirements = useWatch({
    control: methods.control,
    name: "requirements",
  });

  useEffect(() => {
    if (data) {
      const defaultValues: CreateListingProviderValues = {
        listingId: listingId ?? "",
        country: getCountryCode(data?.attributes.address.country) ?? "",
        street: data?.attributes.address.street ?? "",
        additionalInformation:
          data?.attributes.address.additionalInformation ?? "",
        zipcode: data?.attributes.address.zipcode ?? "",
        city: data?.attributes.address.city ?? "",
        bathrooms: data?.attributes.bathrooms ?? 1,
        bedrooms: data?.attributes.bedrooms ?? 1,
        maximumOccupancy: data?.attributes.maximumOccupancy ?? 1,
        toilets: data?.attributes.toilets ?? 1,
        propertyType: data?.attributes.propertyType ?? "",
        propertyKind: data?.attributes.propertyKind ?? "",
        originalUrl: data.attributes.originalUrl ?? "",
        requirements: currentRequirements ?? RequirementsAnswer.Yes,
        pricingPlan: data?.attributes.pricingPlan ?? null,
      };
      reset(defaultValues);
    }
  }, [currentRequirements, data, listingId, reset]);

  useEffect(() => {
    if (isError) {
      router.push(Routes.Listings, "back");
      showErrorToast();
    }
  }, [isError, router, showErrorToast]);

  return (
    <FormProvider reset={reset} {...methods}>
      <LoadingIndicator isOpen={isLoading} testId="CreateListingProvider" />
      {children}
    </FormProvider>
  );
};

export default CreateListingProvider;

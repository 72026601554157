import { useIonRouter } from "@ionic/react";
import { ReactNode, useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { matchPath, useLocation } from "react-router";

import { Routes } from "constants/routes.constants";
import { useToastNotification } from "hooks";
import { useListing } from "queries";
import { ReviewListingProviderValues } from "types/listing.types";

import LoadingIndicator from "components/@common/LoadingIndicator";

import { defaultAvailabilities } from "../constants";
import { ReviewListingAdvanceNotice } from "../types";

interface Props {
  children: ReactNode;
}

const ReviewListingProvider = ({ children }: Props) => {
  const location = useLocation();
  const router = useIonRouter();

  const showErrorToast = useToastNotification({
    text: "listing_overview.error.short",
  });

  const match = matchPath<{ listingId: string }>(location.pathname, {
    path: `${Routes.ReviewListing}/:listingId/*`,
    exact: false,
    strict: false,
  });

  const listingId = match?.params.listingId;
  const { data, isLoading, isError } = useListing(listingId ?? "", {
    enabled: !!listingId,
  });

  const defaultValues: ReviewListingProviderValues = useMemo(
    () => ({
      listingId: listingId ?? "",
      availabilities: defaultAvailabilities,
    }),
    [listingId],
  );

  const { reset, ...methods } = useForm<ReviewListingProviderValues>({
    defaultValues,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  useEffect(() => {
    if (data) {
      const availabilities =
        data.attributes.availabilities || defaultAvailabilities;

      const defaultValues: ReviewListingProviderValues = {
        listingId: data.id,
        availabilities: {
          advanceNotice:
            availabilities.advanceNotice || ReviewListingAdvanceNotice.None,
          bookingWindow: availabilities.bookingWindow,
          customSchema: availabilities.customSchema,
          defaultSchema: availabilities.defaultSchema,
        },
      };
      reset(defaultValues);
    }
  }, [data, reset]);

  useEffect(() => {
    if (isError) {
      router.push(Routes.Listings, "back");
      showErrorToast();
    }
  }, [isError, router, showErrorToast]);

  return (
    <FormProvider reset={reset} {...methods}>
      <LoadingIndicator isOpen={isLoading} testId="ReviewListingProvider" />
      {children}
    </FormProvider>
  );
};

export default ReviewListingProvider;

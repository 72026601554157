import { IonButton, useIonRouter } from "@ionic/react";
import { useCallback } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath } from "react-router";

import { Routes } from "constants/routes.constants";
import { Flow } from "types/flow.types";
import { CreateReservationProviderValues } from "types/reservation.types";

import StepperContainer from "components/@common/StepperContainer";
import FormIonTextarea from "components/@form/FormIonTextArea";

const BlockReason = () => {
  const router = useIonRouter();
  const intl = useIntl();

  const listingId = useWatch({ name: "listingId" });
  const reservationId = useWatch({ name: "reservationId" });

  const { handleSubmit } = useFormContext<CreateReservationProviderValues>();

  const backRoute =
    new URLSearchParams(window.location.search).get("backroute") ||
    Routes.Reservations;

  const handleBack = useCallback(() => {
    router.push(
      generatePath(
        Routes.CreateReservationDetails + `?backroute=${backRoute}`,
        {
          listingId,
          reservationId: reservationId ? reservationId : undefined,
        },
      ),
      "none",
    );
  }, [listingId, reservationId, router, backRoute]);

  const onSubmit = () => {
    router.push(
      generatePath(
        Routes.CreateReservationOverview + `?backroute=${backRoute}`,
        {
          listingId,
          reservationId: reservationId ? reservationId : undefined,
        },
      ),
    );
  };

  return (
    <StepperContainer
      flow={Flow.CreateReservation}
      route={Routes.CreateReservationDetails}
      backRoute={backRoute}
      continueButton={
        <IonButton
          data-testid="continue-button"
          className="continue-button-stepper"
          type="submit"
          shape="round"
          expand="full"
          form="hook-form-block-reason"
        >
          <FormattedMessage id="common.continue" />
        </IonButton>
      }
      backButton={
        <IonButton
          className="contrast-button"
          shape="round"
          onClick={handleBack}
        >
          <FormattedMessage id="back.button" />
        </IonButton>
      }
    >
      <h2 className="margin-24">
        <FormattedMessage id="create_reservation_details.blocking_reason_subtitle" />
      </h2>
      <form id="hook-form-block-reason" onSubmit={handleSubmit(onSubmit)}>
        <FormIonTextarea
          name="note"
          labelPlacement="floating"
          fill="solid"
          label={intl.formatMessage({
            id: "create_reservation.block.reason.label",
          })}
        />
      </form>
    </StepperContainer>
  );
};

export default BlockReason;

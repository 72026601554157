import PageWrap from "components/@common/PageWrap/PageWrap";
import CheckoutComponent from "components/@create-listing/Checkout";

const Checkout = () => {
  return (
    <PageWrap>
      <CheckoutComponent />
    </PageWrap>
  );
};
export default Checkout;

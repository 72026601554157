import PageWrap from "components/@common/PageWrap/PageWrap";
import UrlComponent from "components/@create-listing/Url";

const Url = () => {
  return (
    <PageWrap>
      <UrlComponent />
    </PageWrap>
  );
};

export default Url;

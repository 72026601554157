import PageWrap from "components/@common/PageWrap/PageWrap";
import SuccessComponent from "components/@review-listing/Success";

const Success = () => {
  return (
    <PageWrap androidStatusBar={{ bgColor: "#241261", iconsColor: "light" }}>
      <SuccessComponent />
    </PageWrap>
  );
};

export default Success;

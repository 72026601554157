import { IonText, useIonRouter } from "@ionic/react";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { getQueryParam, QueryParam } from "utils/location.utils";

import { Routes } from "constants/routes.constants";
import { emailRegex } from "constants/validation.constants";
import { useUserExists } from "queries";
import { useAuthStore } from "store/auth-store";

import Divider from "components/@common/Divider";
import ErrorCard from "components/@common/ErrorCard";
import LinkUnstyled from "components/@common/LinkUnstyled";
import LoadingButton from "components/@common/LoadingButton";
import SocialLoginOptions from "components/@common/SocialLoginOptions";
import { Form } from "components/@form/Form";
import { FormIonInput } from "components/@form/FormIonInput";

import { LoginFormFields } from "../types";

interface FormData {
  email: string;
}

const SignInContinueForm = () => {
  const router = useIonRouter();
  const intl = useIntl();
  const { setValues, email } = useAuthStore((state) => ({
    setValues: state.actions.setValues,
    email: state.values?.email,
  }));
  const { userExists, isLoading } = useUserExists();
  const [error, setError] = useState(false);

  const queryEmail = getQueryParam(QueryParam.Email);

  const handleSubmit = async ({ email }: FormData) => {
    setValues({ email });
    userExists(email, {
      onSettled: (skipSignup) => {
        if (skipSignup) {
          router.push(Routes.Login);
        } else {
          setError(true);
        }
      },
    });
  };

  const defaultValues = {
    [LoginFormFields.Email]: queryEmail || email || "",
  };

  return (
    <>
      <p className="description">
        <IonText>
          <FormattedMessage id="sign_in_continue.title.description" />
        </IonText>
      </p>

      {error && (
        <ErrorCard
          title={
            <FormattedMessage id="sign_in_continue.user_doesnt_exists.error" />
          }
        />
      )}

      <Form<FormData>
        onSubmit={handleSubmit}
        defaultValues={defaultValues}
        mode="onSubmit"
      >
        <FormIonInput
          inputMode="email"
          data-testid="email-input"
          name={LoginFormFields.Email}
          aria-label={LoginFormFields.Email}
          autocomplete="email"
          type="email"
          rules={{
            required: {
              value: true,
              message: intl.formatMessage({
                id: "login.field.email.error.required",
              }),
            },
            pattern: {
              value: emailRegex,
              message: intl.formatMessage({
                id: "login.field.email.error.invalid",
              }),
            },
          }}
          fill="solid"
          label={intl.formatMessage({ id: "email.label" })}
          labelPlacement="floating"
          placeholder={intl.formatMessage({
            id: "sign_up_continue.email.placeholder",
          })}
        />

        <LoadingButton
          className="continue-button"
          type="submit"
          shape="round"
          expand="full"
          data-testid="continue-button"
          isLoading={isLoading}
          disabled={isLoading}
        >
          <FormattedMessage id="sign_in_continue.button.label" />
        </LoadingButton>
      </Form>

      {/* Don't show if running inside a React Native WebView */}
      {!window.ReactNativeWebView && (
        <>
          <Divider />
          <SocialLoginOptions />
        </>
      )}

      {/* Don't show if running inside a React Native WebView */}
      {!window.ReactNativeWebView && (
        <div className="link-container">
          <p className="margin-24">
            <IonText>
              <FormattedMessage
                id="sign_in_continue.link"
                values={{
                  a: (chunks) => (
                    <LinkUnstyled
                      data-testid="sign-up-link"
                      onClick={() =>
                        router.push(Routes.SignUpContinue, "forward", "push")
                      }
                    >
                      {chunks}
                    </LinkUnstyled>
                  ),
                }}
              />
            </IonText>
          </p>
        </div>
      )}
    </>
  );
};

export default SignInContinueForm;

import {
  ActionPerformed,
  PushNotifications,
} from "@capacitor/push-notifications";
import { useIonRouter } from "@ionic/react";
import { useQueryClient } from "@tanstack/react-query";
import { ReactNode, useEffect } from "react";

import { Routes } from "constants/routes.constants";
import { useUpdateUserToken } from "queries/notifications/useUpdateUserToken";
import { useCurrentUser } from "services/auth/useCurrentUser";

import useRegisterNotifications from "./useRegisterNotifications";

interface Props {
  children: ReactNode;
}

const NotificationProvider = ({ children }: Props) => {
  const { isLoggedIn } = useCurrentUser();
  const { updateUserToken } = useUpdateUserToken();
  const { registerNotifications } = useRegisterNotifications();
  const queryClient = useQueryClient();
  const router = useIonRouter();

  const addListeners = async () => {
    await PushNotifications.addListener("registration", (token) => {
      updateUserToken({
        token: token.value,
      });
    });

    //TODO: handle error properly
    await PushNotifications.addListener("registrationError", (err) => {
      console.error("Registration error: ", err.error);
    });

    await PushNotifications.addListener(
      "pushNotificationActionPerformed",
      async ({ actionId, notification }: ActionPerformed) => {
        if (actionId === "tap") {
          if (notification.data.type === "reservation") {
            router.push(Routes.Reservations);
          }
          await queryClient.invalidateQueries();
        }
      },
    );
  };

  useEffect(() => {
    addListeners();

    return () => {
      PushNotifications.removeAllListeners();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      registerNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return <>{children}</>;
};

export default NotificationProvider;

import { FormattedMessage } from "react-intl";

import LinkUnstyled from "../LinkUnstyled";

interface Props {
  children: React.ReactNode;
  title?: I18nKey;
  values?: Record<string, string | number>;
  actionKey?: I18nKey;
  isLoading?: boolean;
  onActionClick?: () => void;
  actionOnEnd?: boolean;
}

const ItemListRow = ({
  children,
  title,
  values,
  actionKey,
  isLoading,
  onActionClick,
  actionOnEnd,
}: Props) => {
  const actionButton = actionKey && onActionClick && (
    <LinkUnstyled onClick={isLoading ? () => {} : onActionClick}>
      <FormattedMessage id={actionKey} />
    </LinkUnstyled>
  );

  return (
    <>
      <div className="item-list-row">
        <div className="item-list-row-content">
          {title && (
            <>
              <p className="margin-0 bold" style={{ marginBottom: 4 }}>
                <FormattedMessage id={title} values={values} />
              </p>
              {!!actionKey && !actionOnEnd && actionButton}
            </>
          )}
        </div>
        <div className="item-list-row-content">
          {children}
          {!title && !!actionKey && !actionOnEnd && actionButton}
        </div>
        {!!actionKey && actionOnEnd && (
          <div className="action-btn-end">{actionButton}</div>
        )}
      </div>
      <hr />
    </>
  );
};

export default ItemListRow;

import "./Address.scss";

import { IonButton, useIonRouter } from "@ionic/react";
import { useFormContext, useWatch } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath } from "react-router";

import { captureMessage } from "utils/sentry.utils";

import { Routes } from "constants/routes.constants";
import { useIntlDisplayNames, useIsKeyboardOpen } from "hooks";
import { useCreateListingAddress, useUpdateListing } from "queries";
import { Flow } from "types/flow.types";
import { CreateListingProviderValues } from "types/listing.types";

import ErrorCard from "components/@common/ErrorCard";
import LoadingButton from "components/@common/LoadingButton";
import StepperContainer from "components/@common/StepperContainer";
import FormCountrySelect from "components/@form/FormCountrySelect";
import { FormIonInput } from "components/@form/FormIonInput";

import { CreateListingAddressFormFields } from "../types";

const Address = () => {
  const intl = useIntl();
  const keyboardIsOpen = useIsKeyboardOpen();
  const displayNames = useIntlDisplayNames("en");
  const {
    createListing,
    error: errorCreate,
    isLoading: isLoadingCreate,
  } = useCreateListingAddress();
  const {
    updateListing,
    error: errorUpdate,
    isLoading: isLoadingUpdate,
  } = useUpdateListing();
  const router = useIonRouter();
  const { handleSubmit, setValue } =
    useFormContext<CreateListingProviderValues>();

  const listingId = useWatch({ name: "listingId" });

  const error = errorCreate?.name || errorUpdate?.name;

  const onSubmit = (data: CreateListingProviderValues) => {
    let country = data.country;
    try {
      country = displayNames.of(data.country) || data.country;
    } catch (error) {}

    // if listingId exists, update the listing, otherwise create a new one
    if (listingId) {
      updateListing(
        {
          id: listingId,
          attributes: {
            address: {
              city: data.city,
              street: data.street,
              additionalInformation: data.additionalInformation,
              zipcode: data.zipcode,
              country,
            },
          },
        },
        {
          onSuccess: () => {
            router.push(
              generatePath(Routes.CreateListingPropertyDetails, {
                listingId,
              }),
            );
          },
          onError: (error) => {
            captureMessage("Error updating listing address in create listing", {
              level: "error",
              extra: { error },
            });
          },
        },
      );
    } else {
      createListing(
        {
          city: data.city,
          street: data.street,
          additionalInformation: data.additionalInformation,
          zipcode: data.zipcode,
          country,
        },
        {
          onSuccess: ({ data }) => {
            router.push(
              generatePath(Routes.CreateListingPropertyDetails, {
                listingId: data.data.id,
              }),
            );
            setValue("listingId", data.data.id);
          },
          onError: (error) => {
            captureMessage("Error creating listing address", {
              level: "error",
              extra: { error },
            });
          },
        },
      );
    }
  };

  const handleBack = () => {
    const path = listingId
      ? generatePath(Routes.CreateListingLaunchpad, { listingId })
      : generatePath(Routes.CreateListingLaunchpad);

    router.push(path, "back");
  };

  return (
    <StepperContainer
      flow={Flow.CreateListing}
      route={Routes.CreateListingAddress}
      continueButton={
        <LoadingButton
          data-testid="continue-button"
          className="continue-button-stepper"
          type="submit"
          shape="round"
          expand="full"
          form="hook-form"
          isLoading={isLoadingCreate || isLoadingUpdate}
          disabled={isLoadingCreate || isLoadingUpdate}
        >
          <FormattedMessage id="common.continue" />
        </LoadingButton>
      }
      backButton={
        <IonButton
          className="contrast-button"
          shape="round"
          onClick={handleBack}
        >
          <FormattedMessage id="back.button" />
        </IonButton>
      }
    >
      <h2 className="margin-24">
        <FormattedMessage id="create_listing_address.title" />
      </h2>
      <p className="create-listing-description address-description">
        <FormattedMessage id="create_listing_address.description" />
      </p>
      {error && (
        <ErrorCard title={<FormattedMessage id={"common.error_unknown"} />} />
      )}

      <form
        id="hook-form"
        className="address-form"
        onSubmit={handleSubmit(onSubmit)}
        style={{
          marginBottom: keyboardIsOpen ? "24px" : "0",
        }}
      >
        <FormCountrySelect
          name={CreateListingAddressFormFields.Country}
          data-testid="country-input"
          rules={{
            required: {
              value: true,
              message: intl.formatMessage({
                id: "country.error.required",
              }),
            },
          }}
          aria-label={CreateListingAddressFormFields.Country}
        />

        <FormIonInput
          name={CreateListingAddressFormFields.Street}
          className="margin-top-24"
          data-testid="address-input"
          aria-label={CreateListingAddressFormFields.Street}
          autocomplete="street-address"
          type="text"
          tabIndex={1}
          rules={{
            required: {
              value: true,
              message: intl.formatMessage({
                id: "address.error.required",
              }),
            },
          }}
          fill="solid"
          placeholder={intl.formatMessage({
            id: "address_input.placeholder",
          })}
          label={intl.formatMessage({ id: "address.label" })}
          labelPlacement="floating"
        />

        <FormIonInput
          name={CreateListingAddressFormFields.AdditionalInformation}
          className="margin-top-12"
          data-testid="building-info-input"
          aria-label={CreateListingAddressFormFields.AdditionalInformation}
          autocomplete="address-level3"
          type="text"
          tabIndex={2}
          fill="solid"
          rules={{
            required: false,
          }}
          placeholder={intl.formatMessage({
            id: "building_info_input.placeholder",
          })}
          label={intl.formatMessage({ id: "building_info.label" })}
          labelPlacement="floating"
        />

        <FormIonInput
          name={CreateListingAddressFormFields.Zipcode}
          className="margin-top-12"
          data-testid="postal-code-input"
          aria-label={CreateListingAddressFormFields.Zipcode}
          autocomplete="postal-code"
          type="text"
          tabIndex={3}
          rules={{
            required: {
              value: true,
              message: intl.formatMessage({
                id: "postal_code.error.required",
              }),
            },
          }}
          fill="solid"
          placeholder={intl.formatMessage({
            id: "postal_code_input.placeholder",
          })}
          label={intl.formatMessage({ id: "postal_code.label" })}
          labelPlacement="floating"
        />

        <FormIonInput
          name={CreateListingAddressFormFields.City}
          className="margin-top-12"
          data-testid="city-input"
          aria-label={CreateListingAddressFormFields.City}
          autocomplete="address-level2"
          type="text"
          tabIndex={4}
          enterKeyHint="enter"
          rules={{
            required: {
              value: true,
              message: intl.formatMessage({
                id: "city.error.required",
              }),
            },
          }}
          fill="solid"
          placeholder={intl.formatMessage({
            id: "city_input.placeholder",
          })}
          label={intl.formatMessage({ id: "city.label" })}
          labelPlacement="floating"
        />
      </form>
    </StepperContainer>
  );
};

export default Address;

import { IonButton } from "@ionic/react";
import { FormattedMessage } from "react-intl";

import { ButtonProps } from "./types";

const OwnerEditButton = ({ onClick }: ButtonProps) => (
  <IonButton
    className="owner-button"
    data-testid="edit-reservation"
    shape="round"
    onClick={onClick}
  >
    <FormattedMessage id="reservation_detail.edit_reservation" />
  </IonButton>
);

export default OwnerEditButton;

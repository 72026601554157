import { createStore, useStore } from "zustand";

import { Middlewares, middlewares } from "./middleware";

interface UIStoreState {
  splashHasEnded: boolean;
  setSplashHasEnded: (splashHasEnded: boolean) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}
export const uiStore = createStore<UIStoreState, Middlewares<UIStoreState>>(
  middlewares(
    (set) => ({
      splashHasEnded: false,
      setSplashHasEnded: (splashHasEnded) => set({ splashHasEnded }),
      isLoading: false,
      setIsLoading: (isLoading) => set({ isLoading }),
    }),
    "ui-store",
  ),
);
export const useUIStore = <T>(selector: (state: UIStoreState) => T) =>
  useStore(uiStore, selector);

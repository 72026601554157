import { IonIcon, IonText } from "@ionic/react";
import { trash, warning } from "ionicons/icons";
import {
  Controller,
  FieldError,
  UseControllerProps,
  useFormContext,
} from "react-hook-form";
import Select, { components } from "react-select";

import { isIos } from "utils/capacitor.utils";

import ButtonUnstyled from "components/@common/ButtonUnstyled";

type Option = {
  label: string;
  value: string;
};

type Props = Pick<UseControllerProps, "rules" | "name"> & {
  options: Array<Option>;
  placeholder: React.ReactNode;
  isSearchable?: boolean;
  isClearable?: boolean;
  onClearItem?: () => void;
  menuPlacement?: "auto" | "bottom" | "top";
  tabIndex?: number;
};

const FormSelect = ({
  name,
  rules,
  options,
  placeholder,
  isSearchable = true,
  isClearable = false,
  menuPlacement = "auto",
  onClearItem,
  tabIndex,
}: Props) => {
  const { control } = useFormContext();

  const getHelperText = (error?: FieldError) => {
    if (error?.message) return error.message;
  };

  const handleFocus = () => {
    if (!isIos) return;
    const inputEl = document.getElementById(`form-input-${name}`);
    const scrollIntoViewTimeout = setTimeout(() => {
      inputEl?.scrollIntoView({
        behavior: "auto",
        block: "start",
      });
    }, 500);
    return () => clearTimeout(scrollIntoViewTimeout);
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <div>
            <Select
              menuPlacement={menuPlacement}
              isSearchable={isSearchable}
              onFocus={handleFocus}
              tabIndex={tabIndex}
              onChange={(option) => onChange(option?.value)}
              value={value && options.find((option) => option.value === value)}
              options={options}
              getOptionValue={(option) => option.value}
              getOptionLabel={(option) => option.label}
              placeholder={placeholder}
              inputId={`form-input-${name}`}
              components={{
                IndicatorSeparator: (props) =>
                  isClearable ? (
                    <>
                      <div
                        onMouseDown={(e) => e.stopPropagation()}
                        onTouchEnd={(e) => e.stopPropagation()}
                      >
                        <ButtonUnstyled onClick={() => onClearItem?.()}>
                          <IonIcon
                            style={{
                              fontSize: 18,
                              marginTop: 4,
                              marginRight: 8,
                            }}
                            icon={trash}
                          />
                        </ButtonUnstyled>
                      </div>
                      {components.IndicatorSeparator(props)}
                    </>
                  ) : null,
              }}
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                input: (base) => ({
                  ...base,
                  minHeight: "48px",
                }),
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  boxShadow: "unset",
                  border: state.hasValue
                    ? "1px solid var(--ion-color-primary-shade)"
                    : "1px solid var(--ion-text-color-step-500)",
                  "&:hover": {
                    borderColor: "#4205fa",
                  },
                  minHeight: "42px",
                }),
                singleValue: (base) => ({
                  ...base,
                  color: "var(--ion-text-color)",
                }),
                valueContainer: (base) => ({
                  ...base,
                  paddingLeft: "14px",
                  paddingRight: "14px",
                }),
                placeholder: (base) => ({
                  ...base,
                  color: "var(--ion-text-color-step-600)",
                }),
                option: (styles, { isFocused, isSelected }) => ({
                  ...styles,
                  backgroundColor: isSelected
                    ? "#4205fa"
                    : isFocused
                      ? "#efebdc"
                      : undefined,
                  ":active": {
                    backgroundColor: !isSelected ? "#e7e2ca" : "#4205fa",
                  },
                }),
              }}
            />
            {getHelperText(error) && (
              <div className="helper-text-container">
                <IonIcon color="danger" icon={warning} />
                <IonText color="danger">
                  <p data-testid="helper-text" className="helper-text margin-0">
                    {getHelperText(error)}
                  </p>
                </IonText>
              </div>
            )}
          </div>
        );
      }}
    />
  );
};

export default FormSelect;

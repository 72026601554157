import { useEffect } from "react";
import { useHistory } from "react-router";

import { Routes } from "constants/routes.constants";
import { useCurrentUser } from "services/auth/useCurrentUser";

export const useRedirectIfLoggedIn = () => {
  const { isLoggedIn, signInError } = useCurrentUser();
  const history = useHistory();

  useEffect(() => {
    if (isLoggedIn) {
      history.replace(Routes.Listings);
    }
  }, [history, isLoggedIn, signInError]);

  return { isLoggedIn, signInError };
};

import { IonButton, IonIcon, useIonRouter } from "@ionic/react";
import {
  createRef,
  forwardRef,
  RefObject,
  useImperativeHandle,
  useRef,
} from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { generatePath } from "react-router";

import { createListingDefaultValues } from "constants/listing.constants";
import { Routes } from "constants/routes.constants";
import { useUser } from "queries";
import { useCurrentUser } from "services/auth/useCurrentUser";
import { CreateListingProviderValues } from "types/listing.types";

import { RefreshHandle } from "components/@common/PullToRefresh";
import ListingTableRow from "components/@listing-table/ListingTableRow/ListingTableRow";

type Props = {
  emailVerified: boolean | null;
};

const ListingTable = forwardRef<RefreshHandle, Props>(function ListingTable(
  { emailVerified },
  ref,
) {
  const { isLoggedIn } = useCurrentUser();
  const {
    data: user,
    isLoading,
    refetch,
  } = useUser({
    enabled: isLoggedIn,
  });
  const router = useIonRouter();

  const listings = user?.data.relationships.listings.data || [];

  const listingItemRefs = useRef<Array<RefObject<RefreshHandle>>>([]);
  useImperativeHandle(ref, () => ({
    refresh: () => {
      refetch();
      listingItemRefs.current?.forEach((ref) => ref?.current?.refresh());
    },
  }));
  listingItemRefs.current = listings.map(
    (_, i) => listingItemRefs.current[i] ?? createRef(),
  );

  const { reset } = useFormContext<CreateListingProviderValues>();
  const handleAddListing = () => {
    reset(createListingDefaultValues);
    router.push(generatePath(Routes.CreateListingLaunchpad));
  };

  if (!listings.length && !isLoading) {
    return (
      <div
        className={`listing-table-empty ${!emailVerified && "table-empty-unverified"}`}
      >
        <IonIcon icon="assets/icons/icon-nav-listing.svg" />
        <h6>
          <FormattedMessage id="listing_overview.empty.title" />
        </h6>
        <p>
          <FormattedMessage id="listing_overview.empty.description" />
        </p>
        <IonButton
          data-testid="create-listing"
          shape="round"
          onClick={() =>
            router.push(generatePath(Routes.CreateListingLaunchpad))
          }
        >
          <FormattedMessage id="listing_overview.add_listing" />
        </IonButton>
      </div>
    );
  }

  return (
    <div className="listing-table">
      <div className="header">
        <h5>
          <FormattedMessage id="nav.listings" />
        </h5>

        <IonButton
          className="continue-button-stepper"
          data-testid="create-listing"
          shape="round"
          onClick={handleAddListing}
        >
          <FormattedMessage id="listing_overview.add_listing" />
        </IonButton>
      </div>

      <div className="listings">
        {listings.map(({ id, attributes }, i) => (
          <ListingTableRow
            key={id}
            listingId={id}
            nickname={attributes.nickname}
            ref={listingItemRefs.current[i]}
          />
        ))}
      </div>
    </div>
  );
});

export default ListingTable;

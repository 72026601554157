import "./AccountMenu.scss";

import { IonContent, IonPopover, useIonRouter } from "@ionic/react";
import { useRef, useState } from "react";

import { accountLinksMap } from "constants/menu.constants";
import { Routes } from "constants/routes.constants";
import { useSignOut, useUser } from "queries";

import MenuItem from "components/@common/MenuItem";

const AccountMenu = () => {
  const popover = useRef<HTMLIonPopoverElement>(null);

  const [popoverOpen, setPopoverOpen] = useState(false);
  const router = useIonRouter();
  const { signOut, isLoading } = useSignOut();

  const { data: user } = useUser();

  const userName =
    user?.data.attributes.firstName + " " + user?.data.attributes.lastName;
  const email = user?.data.attributes.email;
  const initials = userName.charAt(0).toUpperCase();

  const openPopover = (e: unknown) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    popover.current!.event = e;
    setPopoverOpen(true);
  };

  const handleSignOut = () => {
    router.push(Routes.Login, "root", "replace");
    signOut();
  };

  return (
    <>
      <div onClick={openPopover} className="account-avatar-large">
        {initials}
      </div>

      <IonPopover
        keepContentsMounted
        ref={popover}
        isOpen={popoverOpen}
        onDidDismiss={() => setPopoverOpen(false)}
      >
        <IonContent className="account-menu">
          <div className="account-container">
            <div className="account-avatar-small">{initials}</div>
            <div className="account-name-email">
              <p className="bold margin-0">{userName}</p>
              <p className="margin-0">{email}</p>
            </div>
          </div>

          <hr />

          <div className="account-links">
            {accountLinksMap.map((link, index) => (
              <MenuItem
                key={index}
                onClick={() => router.push(link.route)}
                {...link}
              />
            ))}
            <MenuItem
              title="mobile_menu_logout"
              icon="assets/icons/icon-logout.svg"
              isExternal={false}
              onClick={handleSignOut}
              isLoading={isLoading}
            />
          </div>
        </IonContent>
      </IonPopover>
    </>
  );
};

export default AccountMenu;

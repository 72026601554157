/* eslint-disable @typescript-eslint/no-explicit-any */
import "./StepperContainer.scss";

import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonProgressBar,
  IonRow,
  useIonRouter,
} from "@ionic/react";
import logo from "images/huswell-logo.svg";
import { closeOutline } from "ionicons/icons";
import { forwardRef, ReactNode } from "react";

import { isAndroid } from "utils/capacitor.utils";

import { Routes } from "constants/routes.constants";
import { Flow } from "types/flow.types";
import { Route } from "types/route.types";

import { getProgressValue } from "components/@create-listing/utils";

import { FooterBar } from "../FooterBar";
import { IOSSafeArea } from "../IOSSafeArea";

interface Props {
  children: ReactNode;
  continueButton?: ReactNode;
  continueButtonOnBottom?: boolean;
  backButton?: ReactNode;
  route?: Route;
  fullWidthContent?: boolean;
  skipButton?: ReactNode;
  topButton?: ReactNode;
  flow: Flow;
  backRoute?: string;
}

const StepperContainer = forwardRef<HTMLIonContentElement, Props>(
  function StepperContainer(
    {
      children,
      continueButton,
      continueButtonOnBottom,
      backButton,
      route,
      fullWidthContent,
      skipButton,
      topButton,
      flow,
      backRoute,
    },
    ref,
  ) {
    const router = useIonRouter();
    const value = getProgressValue(flow, route);

    const handleClose = () => {
      const route = backRoute || Routes.Listings;
      router.push(route, "back", "pop", { unmount: true });
    };

    return (
      <>
        <IOSSafeArea />
        <IonContent ref={ref}>
          <IonGrid className="ion-grid-stepper">
            {/* desktop row */}
            <IonRow className="ion-hide-md-down fixed-height">
              <IonCol className="ion-col-stepper">
                <button
                  data-testid="huswell-logo-button-desktop"
                  className="logo-button-desktop"
                  onClick={() => router.goBack()}
                >
                  <IonIcon
                    color="primary"
                    className="header-logo-stepper"
                    icon={logo}
                  />
                </button>
              </IonCol>
              <IonCol className="flex-end ion-col-stepper">
                <div className="close-icon-md">
                  <IonButton
                    shape="round"
                    className="close-button"
                    onClick={handleClose}
                  >
                    <IonIcon className="close-icon" icon={closeOutline} />
                  </IonButton>
                </div>
              </IonCol>
            </IonRow>

            {/* mobile row */}
            <IonRow
              className="ion-hide-md-up relative-row"
              style={{ minHeight: isAndroid ? 110 : 60 }}
            >
              <div className="ion-hide-md-up close-icon-sm">
                <IonButton
                  shape="round"
                  className="close-button"
                  onClick={handleClose}
                >
                  <IonIcon className="close-icon" icon={closeOutline} />
                </IonButton>
              </div>
            </IonRow>

            {fullWidthContent ? (
              <IonRow className="full-width-content">
                <IonCol className="full-width-col">
                  {!!value && (
                    <div className="stepper-container">
                      <IonProgressBar value={value} type="determinate" />
                    </div>
                  )}
                  {children}
                </IonCol>
              </IonRow>
            ) : (
              <IonRow className="flex-row content-row">
                <IonCol className="content-container ion-col-stepper">
                  {!!value && (
                    <IonProgressBar value={value} type="determinate" />
                  )}
                  {children}
                </IonCol>
              </IonRow>
            )}
          </IonGrid>
        </IonContent>
        <FooterBar
          topButton={topButton}
          backButton={backButton}
          continueButton={continueButton}
          skipButton={skipButton}
          continueOnBottom={continueButtonOnBottom}
        />
      </>
    );
  },
);

export default StepperContainer;

import PageWrap from "components/@common/PageWrap/PageWrap";
import CleaningRequiredComponent from "components/@create-reservation/CleaningRequired";

const CleaningRequired = () => {
  return (
    <PageWrap>
      <CleaningRequiredComponent />
    </PageWrap>
  );
};

export default CleaningRequired;

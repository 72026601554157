import { IonContent, useIonAlert, useIonRouter } from "@ionic/react";
import { captureMessage } from "@sentry/react";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { useToastNotification } from "hooks";
import {
  useDeleteReservation,
  useUpdateReservation,
} from "queries/reservations";
import { GuestyReservation, ReservationType } from "types/reservation.types";

import Reservation from "components/@reservations/ReservationDetail/ReservationDetail/Reservation";
import { ReservationStatus } from "components/@reservations/types";
import { getReservationStatus } from "components/@reservations/utils";

import ReservationFooter from "./components/ReservationFooter";

type Props = {
  reservation: GuestyReservation["data"];
  listingId: string;
  reservationId: string;
  hideBack?: boolean;
  useDesktopButtons?: boolean;
  onClose?: () => void;
  onRemove?: () => void;
  isModal?: boolean;
};

const ReservationContent = ({
  reservation,
  listingId,
  reservationId,
  hideBack,
  useDesktopButtons,
  onClose,
  onRemove,
  isModal,
}: Props) => {
  const intl = useIntl();
  const [presentAlert] = useIonAlert();

  const [isEditing, setIsEditing] = useState(false);
  const [openRequestCancel, setOpenRequestCancel] = useState(false);

  const methods = useForm({
    defaultValues: {
      checkInDate: "",
      checkOutDate: "",
      reservationType: ReservationType.Myself,
      numberOfGuests: 1,
      cleaningRequired: false,
      note: "",
    },
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const { deleteReservation, isLoading: isLoadingDelete } =
    useDeleteReservation();

  const {
    updateReservation,
    isLoading: isLoadingUpdate,
    error: updateError,
  } = useUpdateReservation();

  const handleEditReservation = () => {
    setIsEditing(true);
  };

  const isOwner = reservation?.attributes.isOwnerReservation;

  const reservationStatus = getReservationStatus(
    reservation?.attributes.checkInDate ?? "",
    reservation?.attributes.checkOutDate ?? "",
    reservation?.attributes.status ?? "",
  );
  const canEdit = reservationStatus === ReservationStatus.Upcoming;

  const router = useIonRouter();

  const handleBack = () => {
    if (hideBack) {
      onClose?.();
    } else {
      router.goBack();
    }
  };

  const showDeleteErrorToast = useToastNotification({
    text: "reservation.delete.error",
  });
  const showDeleteSuccessToast = useToastNotification({
    text: "reservation.delete.success",
  });

  const handleDeleteReservation = () => {
    deleteReservation(
      {
        listingId: listingId ?? "",
        reservationId: reservationId ?? "",
      },
      {
        onError: (error) => {
          showDeleteErrorToast();
          captureMessage("Error deleting reservation", {
            level: "error",
            extra: { error },
          });
        },
        onSuccess: () => {
          showDeleteSuccessToast();
          onRemove?.();
        },
        onSettled: () => {
          handleBack();
        },
      },
    );
  };

  const handleUpdate = () => {
    const { checkInDate, checkOutDate, numberOfGuests, cleaningRequired } =
      methods.getValues();

    updateReservation(
      {
        listingId,
        reservationId,
        attributes: {
          checkInDate,
          checkOutDate,
          numberOfGuests,
          cleaningRequired,
        },
      },
      {
        onSuccess: () => setIsEditing(false),
        onError: (error) => {
          captureMessage("Error updating reservation in ReservationDetail", {
            level: "error",
            extra: { error },
          });
        },
      },
    );
  };

  const handleDeleteConfirmation = () => {
    presentAlert({
      header: intl.formatMessage({
        id: "delete_reservation_alert_title",
      }),
      message: intl.formatMessage({
        id: "delete_reservation_alert_message",
      }),
      cssClass: "delete-alert",
      buttons: [
        {
          text: intl.formatMessage({
            id: "reservation_detail.cancel_reservation.keep",
          }),
        },
        {
          text: intl.formatMessage({ id: "reservation.delete" }),
          handler: handleDeleteReservation,
        },
      ],
    });
  };

  const ReservationComponent = () => (
    <Reservation
      reservation={reservation}
      hideBack={hideBack}
      listingId={listingId}
      reservationId={reservationId}
      useDesktopButtons={useDesktopButtons}
      handleBack={handleBack}
      updateError={updateError}
      isEditing={isEditing}
      setIsEditing={setIsEditing}
      openRequestCancel={openRequestCancel}
      setOpenRequestCancel={setOpenRequestCancel}
      canEdit={canEdit}
      isOwner={isOwner}
      reservationStatus={reservationStatus}
      isLoadingDelete={isLoadingDelete}
      isLoadingUpdate={isLoadingUpdate}
      handleDeleteConfirmation={handleDeleteConfirmation}
      handleEditReservation={handleEditReservation}
      handleUpdate={handleUpdate}
      isModal={isModal}
    />
  );

  return (
    <FormProvider {...methods}>
      {isModal ? (
        <ReservationComponent />
      ) : (
        <>
          <IonContent className="reservation-detail-page-ion-content">
            <ReservationComponent />
          </IonContent>
          <ReservationFooter
            isEditing={isEditing}
            isOwner={isOwner}
            reservation={reservation}
            useDesktopButtons={useDesktopButtons}
            canEdit={canEdit}
            isLoadingDelete={isLoadingDelete}
            isLoadingUpdate={isLoadingUpdate}
            onCancelEdit={() => setIsEditing(false)}
            onDelete={handleDeleteConfirmation}
            onEdit={handleEditReservation}
            onUpdate={methods.handleSubmit(handleUpdate)}
            onRequestCancel={() => setOpenRequestCancel(true)}
            isHidden={isModal}
          />
        </>
      )}
    </FormProvider>
  );
};

export default ReservationContent;

import PageWrap from "components/@common/PageWrap/PageWrap";
import AboutComponent from "components/@complete-listing/About";

const About = () => {
  return (
    <PageWrap>
      <AboutComponent />
    </PageWrap>
  );
};

export default About;

import { SignUpDetails } from "components/@authentication/SignUpDetails";
import LogoContainer from "components/@common/LogoContainer";
import PageWrap from "components/@common/PageWrap/PageWrap";

const Details = () => {
  return (
    <PageWrap androidStatusBar={{ bgColor: "#241261", iconsColor: "light" }}>
      <LogoContainer>
        <SignUpDetails />
      </LogoContainer>
    </PageWrap>
  );
};

export default Details;

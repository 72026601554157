import { App, URLOpenListenerEvent } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { useEffect } from "react";
import { useHistory } from "react-router";

import { getFrontendUrl, nativeFrontendUrls } from "utils/url.utils";

import { Routes } from "constants/routes.constants";

const AppUrlListener = () => {
  const history = useHistory();
  useEffect(() => {
    // Add the listener for app open URL events
    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      const urlString = event.url ?? getFrontendUrl();
      const url = new URL(urlString);
      const host = url.origin;
      const pathname = urlString.split(host)[1];

      if (Capacitor.getPlatform() === "android") {
        // On Android, use the URL directly
        const androidUrl = urlString.replace(host, nativeFrontendUrls.android);
        window.location.replace(androidUrl);
      } else if (Capacitor.getPlatform() === "ios") {
        // On iOS, use the URL pathname
        // If the pathname is the callback route, use window.location
        if (pathname.startsWith(Routes.Callback)) {
          const iosUrl = `${nativeFrontendUrls.ios}${pathname}`;
          window.location.replace(iosUrl);
        } else {
          history.push(pathname);
        }
      }
    });

    return () => {
      // Clean up the listener when the component is unmounted
      App.removeAllListeners();
    };
  }, [history]);

  return null;
};

export default AppUrlListener;

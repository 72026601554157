import "./SignInContinue.scss";

import { IonText } from "@ionic/react";
import { FormattedMessage } from "react-intl";

import { useAuthStore } from "store/auth-store";

import SignInContinueForm from "./SignInContinueForm";
import SignInProviderRemembered from "./SignInProviderRemembered";

const SignInContinue = () => {
  const { userName, loginProvider } = useAuthStore((state) => ({
    userName: state.values?.userName,
    loginProvider: state.values?.loginProvider,
  }));

  return (
    <div className="container">
      <IonText>
        <h2>
          <FormattedMessage
            id={
              userName
                ? "sign_in.username_remembered.title"
                : "sign_in_continue.title"
            }
            values={{ userName }}
          />
        </h2>
      </IonText>
      {loginProvider ? (
        <SignInProviderRemembered loginProvider={loginProvider} />
      ) : (
        <SignInContinueForm />
      )}
    </div>
  );
};

export default SignInContinue;

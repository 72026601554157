import "./Callback.scss";

import { IonSpinner, useIonRouter } from "@ionic/react";
import { useQueryClient } from "@tanstack/react-query";
import { signOut } from "aws-amplify/auth";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router";

import { Routes } from "constants/routes.constants";

import ErrorCard from "components/@common/ErrorCard";
import LogoContainer from "components/@common/LogoContainer";
import PageWrap from "components/@common/PageWrap/PageWrap";

const Callback = () => {
  const [message, setMessage] = useState(false);
  const router = useIonRouter();
  const location = useLocation();
  const queryClient = useQueryClient();

  const code = new URLSearchParams(location.search).get("code");

  useEffect(() => {
    // Set a timeout to display the error message after 5 seconds
    const timeout = setTimeout(() => {
      setMessage(true);
      // Set another timeout to redirect after 10 more seconds
      setTimeout(() => {
        if (location.pathname === Routes.Callback) {
          signOut();
          router.push(Routes.SignInContinue, "back");
        }
      }, 10000);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [code, location.pathname, queryClient, router]);

  if (location.pathname !== Routes.Callback) {
    return null;
  }

  return (
    <PageWrap>
      <LogoContainer>
        <div className="spinner-container">
          {message && (
            <ErrorCard
              title={
                <FormattedMessage
                  id="callback.error_message"
                  values={{
                    b: (chunks) => <b>{chunks}</b>,
                  }}
                />
              }
            />
          )}
          <IonSpinner className="spinner" name="crescent" />
        </div>
      </LogoContainer>
    </PageWrap>
  );
};

export default Callback;

import {
  AmenityItems,
  BedType,
  CreateListingProviderValues,
  PropertyKind,
  PropertyType,
  RequirementsAnswer,
} from "types/listing.types";

export enum PricingPlan {
  Starter = "starter",
  Professional = "professional",
  Premium = "premium",
  Manual = "manual",
}

export const propertyTypeKeys: Record<PropertyType | PropertyKind, I18nKey> = {
  [PropertyType.Apartment]: "property_type.apartment.label",
  [PropertyType.House]: "property_type.house.label",
  // [PropertyType.Hotel]: "property_type.hotel.label",
  [PropertyType.Other]: "property_type.other.label",
  [PropertyKind.Room]: "property_type.room.label",
  [PropertyKind.Place]: "property_type.place.label",
};

export const requirementsKeys: Record<RequirementsAnswer, I18nKey> = {
  [RequirementsAnswer.No]: "create_listing_requirements.no_checkbox",
  [RequirementsAnswer.Yes]: "create_listing_requirements.yes_checkbox",
};

export const planKeys: Record<string, I18nKey> = {
  [PricingPlan.Starter]: "starter_plan.title",
  [PricingPlan.Professional]: "professional_plan.title",
  [PricingPlan.Premium]: "premium_plan.title",
  [PricingPlan.Manual]: "manual_plan.title",
};

export const bedTypeKeys: Record<string, I18nKey> = {
  [BedType.KING_BED]: "bed_type.KING_BED",
  [BedType.QUEEN_BED]: "bed_type.QUEEN_BED",
  [BedType.DOUBLE_BED]: "bed_type.DOUBLE_BED",
  [BedType.SINGLE_BED]: "bed_type.SINGLE_BED",
  [BedType.SOFA_BED]: "bed_type.SOFA_BED",
  [BedType.AIR_MATTRESS]: "bed_type.AIR_MATTRESS",
  [BedType.BUNK_BED]: "bed_type.BUNK_BED",
  [BedType.FLOOR_MATTRESS]: "bed_type.FLOOR_MATTRESS",
  [BedType.WATER_BED]: "bed_type.WATER_BED",
  [BedType.TODDLER_BED]: "bed_type.TODDLER_BED",
  [BedType.CRIB]: "bed_type.CRIB",
};

// Some amenities have the same value, but a differentiating label.
// These amenity keys will take the value as default label, except when the label is defined.
// -> e.g. `bedroom.washer` and `essentials.washer` both have the value `essentials.washer` and should
//    both automatically be selected when 1 of them is selected, but the label is different.

export const essentialsKeys: AmenityItems = {
  wifi: { value: "essentials.wifi" },
  tv: { value: "essentials.tv" },
  kitchen: { value: "essentials.kitchen" },
  washer: { value: "essentials.washer" },
  dryer: { value: "essentials.dryer" },
  free_parking: { value: "essentials.free_parking" },
  paid_parking: { value: "essentials.paid_parking" },
  private_parking: { value: "essentials.private_parking" },
  airco: { value: "essentials.airco" },
  heating: { value: "essentials.heating" },
};

export const favouritesKeys: AmenityItems = {
  pool: { value: "favourites.pool" },
  hot_tub: { value: "favourites.hot_tub" },
  patio: { value: "favourites.patio" },
  bbq: { value: "favourites.bbq" },
  pool_table: { value: "favourites.pool_table" },
  lake_access: { value: "favourites.lake_access" },
  beach_access: { value: "favourites.beach_access" },
  ski_access: { value: "favourites.ski_access" },
};

const accessibilityKeys: AmenityItems = {
  grab_rails_for_shower_and_toilet: {
    value: "accessibility.grab_rails_for_shower_and_toilet",
  },
  tub_with_shower_bench: { value: "accessibility.tub_with_shower_bench" },
  wheelchair_accessible: { value: "accessibility.wheelchair_accessible" },
  wide_clearance_to_bed: { value: "accessibility.wide_clearance_to_bed" },
  wide_clearance_to_shower_and_toilet: {
    value: "accessibility.wide_clearance_to_shower_and_toilet",
  },
  wide_hallway_clearance: { value: "accessibility.wide_hallway_clearance" },
  roll_in_shower_with_shower_bench_or_chair: {
    value: "accessibility.roll_in_shower_with_shower_bench_or_chair",
  },
  accessible_height_toilet: { value: "accessibility.accessible_height_toilet" },
  accessible_height_bed: { value: "accessibility.accessible_height_bed" },
};

const bathroomKeys: AmenityItems = {
  bathtub: { value: "bathroom.bathtub" },
  hair_dryer: { value: "bathroom.hair_dryer" },
  hot_water: { value: "bathroom.hot_water" },
  shampoo: { value: "bathroom.shampoo" },
};

const bedroomKeys: AmenityItems = {
  essentials: { value: "bedroom.essentials" },
  bed_linens: { value: "bedroom.bed_linens" },
  dryer: { value: "essentials.dryer", label: "bedroom.dryer" },
  dryer_in_common_space: { value: "bedroom.dryer_in_common_space" },
  extra_pillows_and_blankets: { value: "bedroom.extra_pillows_and_blankets" },
  hangers: { value: "bedroom.hangers" },
  iron: { value: "bedroom.iron" },
  room_darkening_shades: { value: "bedroom.room_darkening_shades" },
  safe: { value: "bedroom.safe" },
  towels_provided: { value: "bedroom.towels_provided" },
  washer: { value: "essentials.washer", label: "bedroom.washer" },
  washer_in_common_space: { value: "bedroom.washer_in_common_space" },
};

const entertainmentKeys: AmenityItems = {
  cable_tv: { value: "entertainment.cable_tv" },
  dvd_player: { value: "entertainment.dvd_player" },
  game_console: { value: "entertainment.game_console" },
  stereo_system: { value: "entertainment.stereo_system" },
  tv: { value: "essentials.tv", label: "entertainment.tv" },
};

const familyKeys: AmenityItems = {
  baby_bath: { value: "family.baby_bath" },
  baby_monitor: { value: "family.baby_monitor" },
  babysitter_recommendations: { value: "family.babysitter_recommendations" },
  children_books_and_toys: { value: "family.children_books_and_toys" },
  changing_table: { value: "family.changing_table" },
  children_dinnerware: { value: "family.children_dinnerware" },
  crib: { value: "family.crib" },
  family_kid_friendly: { value: "family.family_kid_friendly" },
  fireplace_guards: { value: "family.fireplace_guards" },
  high_chair: { value: "family.high_chair" },
  outlet_covers: { value: "family.outlet_covers" },
  pack_n_play_travel_crib: { value: "family.pack_n_play_travel_crib" },
  stair_gates: { value: "family.stair_gates" },
  table_corner_guards: { value: "family.table_corner_guards" },
  window_guards: { value: "family.window_guards" },
};

const heatingKeys: AmenityItems = {
  heating: { value: "essentials.heating" },
  airco: { value: "essentials.airco" },
  fireplace: { value: "heating.fireplace" },
};

const safetyKeys: AmenityItems = {
  carbon_monoxide_detector: { value: "safety.carbon_monoxide_detector" },
  emergency_exit: { value: "safety.emergency_exit" },
  fire_extinguisher: { value: "safety.fire_extinguisher" },
  first_aid_kit: { value: "safety.first_aid_kit" },
  smoke_detector: { value: "safety.smoke_detector" },
};

const kitchenKeys: AmenityItems = {
  coffee_maker: { value: "kitchen.coffee_maker" },
  dishwasher: { value: "kitchen.dishwasher" },
  microwave: { value: "kitchen.microwave" },
  oven: { value: "kitchen.oven" },
  stove: { value: "kitchen.stove" },
  toaster: { value: "kitchen.toaster" },
  cookware: { value: "kitchen.cookware" },
  dishes_and_silverware: { value: "kitchen.dishes_and_silverware" },
  kitchen: { value: "essentials.kitchen", label: "kitchen.kitchen" },
  kettle: { value: "kitchen.kettle" },
  refrigerator: { value: "kitchen.refrigerator" },
};

const locationKeys: AmenityItems = {
  city: { value: "location.city" },
  country: { value: "location.country" },
  lake: { value: "location.lake" },
  mountain: { value: "location.mountain" },
  sea: { value: "location.sea" },
  beach: { value: "location.beach" },
  beach_front: { value: "location.beach_front" },
  beach_view: { value: "location.beach_view" },
  downtown: { value: "location.downtown" },
  golf_course_front: { value: "location.golf_course_front" },
  golf_view: { value: "location.golf_view" },
  lake_access: {
    value: "favourites.lake_access",
    label: "location.lake_access",
  },
  lake_front: { value: "location.lake_front" },
  mountain_view: { value: "location.mountain_view" },
  near_ocean: { value: "location.near_ocean" },
  ocean_front: { value: "location.ocean_front" },
  resort: { value: "location.resort" },
  river: { value: "location.river" },
  rural: { value: "location.rural" },
  sea_view: { value: "location.sea_view" },
  ski_in: { value: "location.ski_in" },
  ski_in_ski_out: { value: "location.ski_in_ski_out" },
  ski_out: { value: "location.ski_out" },
  town: { value: "location.town" },
  village: { value: "location.village" },
  water_view: { value: "location.water_view" },
  waterfront: { value: "location.waterfront" },
};

const logisticsKeys: AmenityItems = {
  shuttle: { value: "logistics.shuttle" },
  storage: { value: "logistics.storage" },
};

const outdoorKeys: AmenityItems = {
  outdoor_pool: { value: "outdoor.outdoor_pool" },
  garden_or_backyard: { value: "outdoor.garden_or_backyard" },
  bicycles_available: { value: "outdoor.bicycles_available" },
  patio_or_balcony: {
    value: "favourites.patio",
    label: "outdoor.patio_or_balcony",
  },
  bbq_grill: { value: "favourites.bbq", label: "outdoor.bbq_grill" },
  beach_essentials: { value: "outdoor.beach_essentials" },
};

const parkingKeys: AmenityItems = {
  elevator: { value: "parking.elevator" },
  free_parking: { value: "parking.free_parking" },
  garage: { value: "parking.garage" },
  paid_parking: { value: "parking.paid_parking" },
  private_parking: { value: "parking.private_parking" },
  free_parking_on_premises: {
    value: "essentials.private_parking",
    label: "parking.free_parking_on_premises",
  },
  paid_parking_off_premises: {
    value: "essentials.paid_parking",
    label: "parking.paid_parking_off_premises",
  },
  free_parking_on_street: {
    value: "essentials.free_parking",
    label: "parking.free_parking_on_street",
  },
  indoor_pool: { value: "parking.indoor_pool" },
  swimming_pool: { value: "favourites.pool", label: "parking.swimming_pool" },
  communal_pool: { value: "parking.communal_pool" },
  ev_charger: { value: "parking.ev_charger" },
  single_level_home: { value: "parking.single_level_home" },
  private_pool: { value: "parking.private_pool" },
  gym: { value: "parking.gym" },
  hot_tub: { value: "favourites.hot_tub", label: "parking.hot_tub" },
};

const internetAndOfficeKeys: AmenityItems = {
  desk: { value: "internet.desk" },
  internet: { value: "internet.internet" },
  laptop_friendly_workspace: { value: "internet.laptop_friendly_workspace" },
  pocket_wifi: { value: "internet.pocket_wifi" },
  wireless_internet: {
    value: "essentials.wifi",
    label: "internet.wireless_internet",
  },
};

const servicesKeys: AmenityItems = {
  breakfast: { value: "services.breakfast" },
  lunch: { value: "services.lunch" },
  long_term_stays_allowed: { value: "services.long_term_stays_allowed" },
  luggage_dropoff_allowed: { value: "services.luggage_dropoff_allowed" },
  high_touch_surfaces_disinfected: {
    value: "services.high_touch_surfaces_disinfected",
  },
  enhanced_cleaning_practices: {
    value: "services.enhanced_cleaning_practices",
  },
  doorman: { value: "services.doorman" },
  cleaning_disinfection: { value: "services.cleaning_disinfection" },
  cleaning_before_checkout: { value: "services.cleaning_before_checkout" },
};

export const otherKeys: AmenityItems = {
  ...accessibilityKeys,
  ...bathroomKeys,
  ...bedroomKeys,
  ...entertainmentKeys,
  ...familyKeys,
  ...heatingKeys,
  ...safetyKeys,
  ...kitchenKeys,
  ...locationKeys,
  ...logisticsKeys,
  ...outdoorKeys,
  ...parkingKeys,
  ...servicesKeys,
  ...internetAndOfficeKeys,
};

export const amenitiesKeys: AmenityItems = {
  pool: { value: "amenities.pool" },
  family: { value: "amenities.family" },
  wellness: { value: "amenities.wellness" },
  homeSafety: { value: "amenities.safety" },
  bedroomAndLaundry: { value: "amenities.bedroom" },
  heating: { value: "amenities.heating" },
  kitchenAndDining: { value: "amenities.kitchen" },
  outdoor: { value: "amenities.outdoor" },
  parkingAndFacilities: { value: "amenities.parking" },
  bathroom: { value: "amenities.bathroom" },
  locationFeatures: { value: "amenities.location" },
  services: { value: "amenities.services" },
  entertainment: { value: "amenities.entertainment" },
  accessibilityFeatures: { value: "amenities.accessibility" },
  logistics: { value: "amenities.logistics" },
  internetAndOffice: { value: "amenities.internet" },
};

export const allKeys: AmenityItems = {
  ...essentialsKeys,
  ...favouritesKeys,
  ...otherKeys,
  ...amenitiesKeys,
};

export const availibilityBookingWindowKeys: Record<string, I18nKey> = {
  ALL: "review_listing_availability.window.all",
  DAYS_30: "review_listing_availability.window.days_30",
  DAYS_60: "review_listing_availability.window.days_60",
  DAYS_90: "review_listing_availability.window.days_90",
  DAYS_120: "review_listing_availability.window.days_120",
  DAYS_150: "review_listing_availability.window.days_150",
  DAYS_180: "review_listing_availability.window.days_180",
  DAYS_210: "review_listing_availability.window.days_210",
  DAYS_240: "review_listing_availability.window.days_240",
  DAYS_270: "review_listing_availability.window.days_270",
  DAYS_300: "review_listing_availability.window.days_300",
  DAYS_330: "review_listing_availability.window.days_330",
  DAYS_365: "review_listing_availability.window.days_365",
};

export const advanceNoticeKeys: Record<string, I18nKey> = {
  SAME_DAY: "review_listing_availability.notice.same_day",
  DAYS_1: "review_listing_availability.notice.days_1",
  DAYS_2: "review_listing_availability.notice.days_2",
  DAYS_3: "review_listing_availability.notice.days_3",
  DAYS_7: "review_listing_availability.notice.days_7",
  NONE: "review_listing_availability.notice.none",
};

export const createListingDefaultValues: CreateListingProviderValues = {
  listingId: "",
  country: "",
  street: "",
  additionalInformation: "",
  zipcode: "",
  city: "",
  bathrooms: 1,
  bedrooms: 1,
  maximumOccupancy: 1,
  toilets: 1,
  propertyType: undefined,
  propertyKind: undefined,
  originalUrl: "",
  requirements: RequirementsAnswer.Yes,
  pricingPlan: null,
};

import { CSSProperties } from "react";

import { CalendarMarkingType } from "types/calendar.types";

import { getMarkingColors } from "../utils";

type Props = {
  height: string;
  position: "start" | "end";
  isHovered: boolean;
  type: CalendarMarkingType;
  isInPast: boolean;
};

const CalendarMarkingEnd = ({
  height,
  position,
  isHovered,
  type,
  isInPast,
}: Props) => {
  const colors = getMarkingColors(type, isInPast);
  const points =
    position === "start"
      ? "250,800 450,000 400,0 800,0 800,800"
      : "0,800 0,0 700,0 750,0 680,800";

  const dropShadowStart = isHovered
    ? `drop-shadow(-4px 4px 0px ${colors.shadow})`
    : undefined;
  const dropShadowEnd = isHovered
    ? `drop-shadow(4px 4px 0px ${colors.shadow})`
    : undefined;

  const boxShadowProps = {
    filter: isHovered
      ? position === "start"
        ? dropShadowStart
        : dropShadowEnd
      : undefined,
  };

  const commonMarkerStyleProps: CSSProperties = {
    position: "absolute",
    height: height,
    transition: "all 0.2s",
  };
  return (
    <>
      <div
        style={{
          ...commonMarkerStyleProps,
          right: position === "start" ? "15%" : undefined,
          left: position === "end" ? "-50%" : undefined,
          width: position === "start" ? "55%" : "95%",
        }}
      >
        <svg
          viewBox="0 0 800 800"
          preserveAspectRatio="none"
          style={{
            height: "100%",
            width: "inherit",
            transition: "all 0.2s ease-in",
            ...boxShadowProps,
          }}
        >
          <polygon points={points} fill={colors.background} />
        </svg>
      </div>
      {position === "end" && (
        <div
          style={{
            left: 0,
            width: "25%",
            ...commonMarkerStyleProps,
          }}
        />
      )}
    </>
  );
};

export default CalendarMarkingEnd;
